import React, { Component } from 'react'
import { Button, Modal, Input, Select } from 'scanmetrix-components'
import {CirclePicker} from "react-color";
import styled from "styled-components"

const CircleDesign = styled.div`
    transform: translateX(7px);
`

export default class extends Component {
    state = { content: null, name: null, loading: false }

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={() => {
            this.setState({ color: null, name: null, expenseTypeId: null, number: null, loading: false })
        }}>
            <Modal.Head title="Budget Kategorie anlegen" icon="piggy-bank" />
            <Modal.Body padding={24} adjustHeight="364px">
                <div style={{
                    padding: "16px",
                    border: `3px dashed ${this.state.color || "#cccccc"}`,
                    display: "grid",
                    gridGap: "32px",
                    gridTemplateColumns: "1fr",
                    width: "380px"
                }}>
                    <Select
                        label="Ausgabeart"
                        adjustWidth
                        icon="wallet"
                        value={this.state.expenseTypeId}
                        onChange={expenseTypeId => this.setState({ expenseTypeId })}
                        fetch={{
                            url: "/v2/expense-type",
                            map: dataSet => ({ key: dataSet.id, title: `${dataSet.type} / ${dataSet.name}` })
                        }}
                    />
                    <Input label="Name" icon="tag" value={this.state.name} required onChange={name => this.setState({name})} adjustWidth/>
                    <Input label="Nummer" icon="table" value={this.state.number} onChange={number => this.setState({number})} adjustWidth/>
                    <CircleDesign>
                        <CirclePicker onChange={color => this.setState({color: color.hex})} width="100%"/>
                    </CircleDesign>
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading}
                                                       disabled={!this.state.name || !this.state.color} thick
                                                       title="Budget Kategorie anlegen" icon="piggy-bank" onClick={() => {
                this.setState({ loading: true })

                fetch(`${scanmetrix.nestURL}/v2/budget-category`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        name: this.state.name,
                        color: this.state.color,
                        number: this.state.number,
                        expense_type_id: this.state.expenseTypeId
                    })
                }).then(result => {
                    if(result.status === 201) {
                        this.props.refresh().then(() => {
                            instance.close()
                        })
                    } else {
                        this.setState({ loading: false })
                    }
                }).catch(() => this.setState({ loading: false }))
            }} />} />
        </Modal>
    }
}
