import React, { Component } from "react"
import { Modal, Input, Select, Grid, GridElement } from "scanmetrix-components"

export default class extends Component {
    state = {}

    render() {
        return <Modal minWidth="768px" instance={this.props.instance} initialize={expenseType => this.setState(expenseType)}>
            <Modal.Head title="Ausgabeart aktualisieren" icon="wallet" />
            <Modal.Body padding={24}>
               <Input.PostProvider value={{
                    url: `/v2/expense-type/${this.state.id}`,
                    data: this.state,
                    onSave: () => this.props.refresh()
                }}>
                   <Grid gap="16px">
                       <GridElement>
                           <Select
                               field="type"
                               label="Typ"
                               adjustWidth
                               noUnselect
                               required
                               icon="cog"
                               items={[
                                   { key: "CAPEX", title: "CapEx - Capital Expenditures", icon: "money-bills" },
                                   { key: "OPEX", title: "OpEx - Operational Expenditures", icon: "coin" },
                               ]}
                           />
                       </GridElement>
                       <GridElement>
                           <Input field="name" required icon="tag" label="Name" adjustWidth />
                       </GridElement>
                       <GridElement>
                           <Input field="number" required icon="table" label="Nummer" adjustWidth />
                       </GridElement>
                   </Grid>
                </Input.PostProvider>
            </Modal.Body>
        </Modal>
    }
}
