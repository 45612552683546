import React, { Component, useState } from "react"
import { Page, Modal, Button, GridElement } from "scanmetrix-components"
import Chart from "react-apexcharts"
import ToolTip from "react-portal-tooltip"
import i18next from "i18next";
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import { getColor } from "../calendar/"
//import GridLayout from 'react-grid-layout';
import ContentLoader from "react-content-loader"
import Color from "color"
import ScrollContainer from "react-indiana-drag-scroll"
import dueDatesExample from "./due-dates-example.json";
import Tilt from "react-parallax-tilt"
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
//import news from "./news.json"
import UserExample from "./User-example.json"
import Avatar from "react-avatar"
import 'react-calendar/dist/Calendar.css'
import Agenda from "./styled/Agenda"
import Rating from "./styled/Rating"
import AgendaEntry from "./styled/AgendaEntry"
import Charts from "./styled/Charts"
import Container from "./styled/Container"
import DueDates from "./styled/DueDates"
import DueDatesContainer from "./styled/DueDatesContainer"
import ElementTitle from "./styled/ElementTitle"
import Grid from "./styled/Grid"
import PMGrid from "./styled/PMGrid"
import LastVisit from "./styled/LastVisit"
import News from "./styled/News"

import OverviewWrapperMaxwidth from "./styled/OverviewWrapperMaxwidth"
import PieChart from "./styled/PieChart"
import Post from "./styled/Post"
import SideBar from "./styled/SideBar"
import SideStatistics from "./styled/SideStatistics"
import NothingTodo from "./styled/NothingTodo"
import StyledStat from "./styled/Stat"
import TitleButton from "./styled/TitleButton"
import StyledUser from "./styled/User"
import Users from "./styled/Users"
import UserTooltip from "./styled/UserTooltip"
import ChartSelectorTooltip from "./styled/ChartSelectorTooltip"
import ChartSelector from "./styled/ChartSelector"



import Calendar from "react-calendar"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ApolloClient } from "@apollo/client"
import { InMemoryCache } from "apollo-cache-inmemory"
import { createHttpLink } from "apollo-link-http"
import { gql } from "apollo-boost"
import Cookies from "js-cookie"

import PropertyManagementDashboard from "./propertyManagement"
import ReactJoyride from "react-joyride";
import { Section } from "../../../components";
import { widgets } from "react-trello";
import AdressButton from "../../../components/AdressBook/AdressButton";
const CalendarContainer = styled.div`
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: ${props => props.rowStart};
  grid-row-end: ${props => props.rowEnd};
  border-radius: 8px;
  flex-grow: 0;
  height: 378px;
  display: flex;
  flex-direction: column;
  //padding: 16px;
  padding: 16px 16px 0px 16px;
  background: white;
  box-shadow: 0 4px 24px -8px rgb(0 0 0 / 15%);
  
  @media screen and (max-width: 1919px) {
      grid-column-start: 1;
      grid-row-start: 6;
      grid-row-end: 7;
  }
  
  button {
      border-radius: 8px;
      transition: background-color 0.3s;
  }

  .react-calendar {
      width: 100%;
      border: none;
  }
  
  .react-calendar__tile.react-calendar__month-view__days__day {
      border: none;
      height: 48px;
  }

  .react-calendar__month-view__days__day--weekend {
      color: rgba(0, 0, 0, 0.35);
  }

  .react-calendar * {
      font-family: inherit !important;
  }
  
  abbr {
      text-decoration: none !important;
  }

  .react-calendar__tile {
      border-radius: 8px;
      transition: background-color 0.3s;
  }

  .react-calendar__tile--active {
      background: #20242b !important;
      color: white !important;
  }
  
  .react-calendar__tile--now {
      background: linear-gradient(30deg,#0f7abf,#58b9f9);
      color: white;
      border-radius: 8px;
      box-shadow: 0 0 6px 0 #1a699c66;
      font-weight: bold;
      transition: opacity 0.3s;
    
      &:hover {
        background: linear-gradient(30deg,#0f7abf,#58b9f9) !important;
        opacity: 0.75;
      }
  }
`

class CalendarModule extends Component {
    state = { loading: true, googleLoading: true, googleEvents: [], automaticAppointments: [], appointments: [],}

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)
        this.fetch()
    }

    fetch() {
        fetch(scanmetrix.backendURL + "/integration/google_workspace/calendar_events", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            if(data.status === "SUCCESSFUL") this.setState({ googleEvents: data.events, googleLoading: false })
            else this.setState({ googleLoading: false })
        })

        fetch(scanmetrix.backendURL + "/calendar/automatic_appointments", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            if(data.status === "SUCCESSFUL") this.setState({ automaticAppointments: data.items })
        })

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    Appointments {
                        nodes {
                            id
                            date
                            duration
                            type
                            name
                            description
                            allDay
                        }
                    }
                }
            `
        }).then(result => {
            const appointments = result.data.Appointments.nodes

            this.setState({ appointments, loading: false })
        })
    }

    render() {
        if(this.state.loading || this.state.googleLoading) return <ContentLoader
            speed={2}
            style={{ gridColumnStart: 4, gridColumnEnd: 6 }}
            width="100%"
            height={378}
            viewBox="0 0 100% 378"
            foregroundColor="rgba(255, 255, 255, 0.25)"
            backgroundColor="rgba(0, 0, 0, 0.1)">
            <rect x="0" y="0" rx="8" ry="8" width="100%" height="378" />
        </ContentLoader>

        const dates = [ ...this.state.appointments, ...this.state.googleEvents, ...this.state.automaticAppointments ]

        return <CalendarContainer rowStart={this.props.rowStart} rowEnd={this.props.rowEnd}>
            <Calendar showWeekNumbers tileContent={({ date }) => {
                const current = moment(date)
                const found = dates.filter(item => moment(item.date).format("DD.MM.YYYY") === current.format("DD.MM.YYYY"))

                if(found.length) {
                    return <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        {[...found].splice(0, 3).map((appointment, index) => <div key={index} style={{ margin: "0 3px", width: "8px", height: "8px", borderRadius: "100%", border: "1px solid white", backgroundColor: getColor(appointment.type) }}></div>)}
                    </div>
                }

                return null
            }} />
        </CalendarContainer>
    }
}

const getEvaluationColor = percentage => {
    if(percentage < 0.25) return "#c0392b"
    if(percentage < 0.5) return "#e67e22"
    if(percentage < 0.75) return "#e67e22"
    if(percentage < 0.9) return "#2ecc71"

    return "#1abc9c"
}

const getAccountingChartOptions = (formatter, colors) => {
    return {
        colors,
        chart: {
            toolbar: {
                show: false
            },
            animations: {
                enabled: false
            },
            sparkline: {
                enabled: true
            },
            dropShadow: {
                enabled: true,
                opacity: 0.3,
                blur: 5,
                left: -2,
                top: 8
            },
        },
        xaxis: {
            categories: ["01.07.2020", "02.07.2020", "03.07.2020", "04.07.2020", "05.07.2020", "06.07.2020", "07.07.2020", "08.07.2020", "09.07.2020", "10.07.2020", "11.07.2020", "12.07.2020", "13.07.2020", "14.07.2020", "15.07.2020", "16.07.2020"],
            axisBorder: {
                show: false
            },
            labels: {
                show: false
            },
            axisTicks: {
                show: false
            },
            crosshairs: {
                show: false
            },
            tooltip: {
                enabled: false
            }
        },
        yaxis: {
            y: 0,
            offsetX: 0,
            offsetY: 0,
            padding: {
                left: 0,
                right: 0
            },
            labels: {
                formatter
            }
        },
        stroke: {
            curve: 'smooth',
            colors
        },
        dataLabels: {
            enabled: false,
            style: {
                colors
            }
        },
        legend: {
            show: false,
            labels: {
                colors
            }
        },
        markers: {
            strokeColor: colors
        },
        grid: {
            show: false,
            padding: {
                left: 0,
                right: 0,
                bottom: 0
            }
        }
    }
}

class Stat extends Component {
    render() {
        const value = this.props.value

        return <StyledStat className={this.props.className}>
            {!(value === null || value === undefined) && value <= 0.75 && value > 0.5 && <div className="orange attention animate__animated animate__flash animate__slower animate__infinite"><i className="fas fa-do-not-enter" /></div>}
            {!(value === null || value === undefined) && value <= 0.5 && <div className="attention animate__animated animate__flash animate__slower animate__infinite"><i className="fas fa-exclamation" /></div>}
            <p className="label">{this.props.title}</p>
            <PieChart style={{ color: !value ? "black" : getEvaluationColor(value) }}>
                {!(value === null || value === undefined) && <svg viewBox="0 0 36 36">
                    <path
                        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                        fill="none"
                        stroke="#cccccc"
                        strokeWidth="3"
                        strokeDasharray="100, 100"
                    />
                    <path
                        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                        fill="none"
                        stroke={getEvaluationColor(value)}
                        strokeWidth="3"
                        strokeDasharray={`${value * 100}, 100`}
                    />
                </svg>}
                {(value === null || value === undefined) && <ContentLoader
                    speed={2}
                    width={72}
                    height={72}
                    viewBox="0 0 72 72"
                    foregroundColor="rgba(255, 255, 255, 0.25)"
                    backgroundColor="rgba(0, 0, 0, 0.1)"
                >
                    <circle cx="36" cy="36" r="36" />
                </ContentLoader>}
                {!(value === null || value === undefined) && <p className="value">{Math.round(value * 100)}</p>}
            </PieChart>
            <p className="unit">{i18next.t("page.private.dashboard.facilityManagement.percent")}</p>
        </StyledStat>
    }
}

class User extends Component {
    state = { hovered: false, ref: null, lastActivity: null, online: false }

    constructor(props) {
        super(props)

        this.state.lastActivity = props.lastActivity

        this.state.online = scanmetrix.onlineUsers.includes(parseInt(this.props.id))
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            const online = scanmetrix.onlineUsers.includes(parseInt(this.props.id))

            if(online !== this.state.online) {
                if(!online) this.setState({ online, lastActivity: moment().toDate() })
                else this.setState({ online })
            }
        }, 1000)

    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        return <>
            {this.state.ref && <ToolTip tooltipTimeout={100} group="users" parent={this.state.ref} active={this.state.hovered} position="top" arrow="center" style={{ arrowStyle: { borderColor: false } }}>
                <UserTooltip>
                    <Avatar size="80" email={scanmetrix.disableGravatar ? null : this.props.email} name={this.props.name} className="avatar" />
                    <p className="name">{this.props.name}</p>
                    <p className="email">{this.props.email}</p>
                    <button disabled className="primary"><i className="far fa-comments-alt" />{i18next.t("page.private.dashboard.facilityManagement.chat")}</button>
                    <button disabled><i className="far fa-user" />{i18next.t("page.private.dashboard.facilityManagement.profileVisit")}</button>
                </UserTooltip>
            </ToolTip>}
            <StyledUser innerRef={ref => {
                if(!this.state.ref) this.setState({ ref })
            }} onMouseEnter={() => this.setState({ hovered: true })} onMouseLeave={() => this.setState({ hovered: false })}>
                <div className="avatar">
                    <Avatar size="64" email={scanmetrix.disableGravatar ? null : this.props.email} name={this.props.name} className="avatar" />
                    <div className={`status ${this.state.online ? "green" : "red"}`} />
                </div>
                <div className="right">
                    <p className="name">{this.props.name}</p>
                    <p className="status">{this.state.online ? "Online" : (this.state.lastActivity ? moment(this.state.lastActivity).fromNow() : "Offline")}</p>
                </div>
            </StyledUser>
        </>
    }
}

class ChartSelectorItem extends Component {
    state = { hovered: false, ref: null }

    render() {
        return <>
            {this.state.ref && this.props.locked && <ToolTip tooltipTimeout={100} group="chartSelector" parent={this.state.ref} active={this.state.hovered} position="top" arrow="center" style={{ arrowStyle: { borderColor: false } }}>
                <ChartSelectorTooltip>
                    <i className="fad fa-sad-tear icon" />
                    {i18next.t("page.private.dashboard.facilityManagement.notBookedModule")}
                    <button className="primary"><i className="far fa-box-open" />{i18next.t("page.private.dashboard.facilityManagement.learnMore")}</button>
                </ChartSelectorTooltip>
            </ToolTip>}
            <div onClick={() => {
                if(!this.props.locked) this.props.onClick()
            }} onMouseEnter={() => this.setState({ hovered: true })} onMouseLeave={() => this.setState({ hovered: false })} ref={ref => {
                if(!this.state.ref) this.setState({ ref })
            }} className={`item ${this.props.locked ? "locked" : ""} ${this.props.selected ? "selected" : ""}`}>
                {this.props.title}
                <div className={`value ${this.props.value < 0 ? "green" : "red"}`}>
                    {!this.props.locked && <div>
                        <i className={`far fa-arrow-${this.props.value < 0 ? "down" : "up"}`} />
                        {Number(Math.abs(this.props.value)).toFixed(2)} %
                    </div>}
                    {this.props.locked && <i className="fas fa-lock" />}
                </div></div>
        </>
    }
}

class StatisticPlaceholder extends Component {
    render() {

        return <ContentLoader
            speed={2}
            width="50%"
            height={42}
            viewBox="0 0 50% 42"
            foregroundColor="rgba(255, 255, 255, 0.25)"
            backgroundColor="rgba(0, 0, 0, 0.1)"
        >
            <rect x="0" y="11" rx="4" ry="4" width="100%" height="31" />
        </ContentLoader>
    }
}

const ActivityUser = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

    
    &:hover {
        .avatar {
          opacity: 0.75;
        }
    }
  
  .avatar {
    position: relative;
    transition: opacity 0.3s;
      border-radius: 100%;
    
    img {
      -webkit-user-drag: none;
      height: 48px;
      width: 48px;
      object-fit: cover;
      border-radius: 100%;
      box-shadow: 0 4px 5px -3px rgba(0, 0, 0, 0.75);
    }
    
    .status {
      position: absolute;
      z-index: 2;
      width: 10px;
      height: 10px;
      bottom: 2px;
      right: 2px;
      border: 2px solid white;
      border-radius: 100%;
      
      &.green {
        background: #1abc9c;
      }
      
      &.yellow {
        background: #f1c40f;
      }
      
      &.red {
        background: #e74c3c;
      }
    }
  }
  
  .right {
    padding-left: 16px;
    
    .name {
      font-size: 1.2em;
    }
    
    .status {
      opacity: 0.5;
      font-size: 0.8em;
    }
  }
`


class UserActivitiesModal extends Component {
    render() {
        return <Modal minWidth="650px" instance={this.props.instance}>
            <Modal.Head title={i18next.t("page.private.dashboard.facilityManagement.activeUsers")} icon="users" />
            <Modal.Body padding={24}>
                <div style={{ display: "grid", gridTemplateColumns: "100%", gridGap: "16px" }}>
                    {this.props.users.map(user => <ActivityUser key={user.id}>
                        <div className="avatar">
                            <Avatar size="64" email={scanmetrix.disableGravatar ? null : user.email} name={`${user.firstName} ${user.lastName}`} className="avatar" />
                            <div className={`status ${scanmetrix.onlineUsers.includes(parseInt(user.id)) ? "green" : "red"}`} />
                        </div>
                        <div className="right">
                            <p className="name">{`${user.firstName} ${user.lastName}`}</p>
                            <p className="status">{scanmetrix.onlineUsers.includes(parseInt(user.id)) ? "Online" : (user.lastActivity ? moment(user.lastActivity).fromNow() : "Offline")}</p>
                        </div>
                    </ActivityUser>)}
                </div>
            </Modal.Body>
        </Modal>
    }
}


class ChartModule extends Component {
    state = { chart: "costs" }

    render() {
        return <Charts>
            <ChartSelector>
                <ChartSelectorItem selected={this.state.chart === "costs"} onClick={() => this.setState({ chart: "costs" })} title={i18next.t("page.private.dashboard.facilityManagement.cost")} value={75.2} />
                <ChartSelectorItem selected={this.state.chart === "facilityManagement"} onClick={() => this.setState({ chart: "facilityManagement" })} title={i18next.t("page.private.dashboard.facilityManagement.fm")} value={-25.2} />
                <ChartSelectorItem selected={this.state.chart === "energy"} onClick={() => this.setState({ chart: "energy" })} locked title={i18next.t("page.private.dashboard.facilityManagement.energyConsumption")} value={45.2} />
                <div className="exampleData"><i className="far fa-exclamation-circle" /> {i18next.t("page.private.dashboard.facilityManagement.visualizeFuntion")}</div>
            </ChartSelector>
            <div className="chartContainer">
                {this.state.chart === "costs" && <Chart
                    options={getAccountingChartOptions(val => `${val} €`, ["#ffffff", "#2ecc71", "#3498db"])}
                    series={[
                        {
                            name: i18next.t("page.private.dashboard.facilityManagement.costs.targetCosts"),
                            data: [625, 123, 425, 124, 453, 236, 434, 256, 236, 634, 324, 563, 180, 244]
                        },
                        {
                            name: i18next.t("page.private.dashboard.facilityManagement.costs.actualCosts"),
                            data: [500, 60, 816, 250, 350, 300, 400, 300, 320, 500, 200, 100, 250, 300]
                        },
                        {
                            name: i18next.t("page.private.dashboard.facilityManagement.costs.budgetDefinition"),
                            data: [300, 300, 300, 300, 300, 300, 300, 300, 600, 600, 600, 600, 600, 600]
                        }
                    ]}
                    type="line"
                    height="100%"

                />}
                {this.state.chart === "facilityManagement" && <Chart
                    options={getAccountingChartOptions(val => val, ["#ffffff", "#2ecc71", "#e74c3c"])}
                    series={[
                        {
                            name: i18next.t("page.private.dashboard.facilityManagement.facilityManagement.newInventories"),
                            data: [625, 723, 425, 124, 453, 236, 434, 256, 236, 634, 324, 563, 180, 244]
                        },
                        {
                            name: i18next.t("page.private.dashboard.facilityManagement.facilityManagement.maintenanceCarriedOut"),
                            data: [500, 60, 816, 250, 350, 300, 100, 300, 320, 500, 200, 100, 250, 300]
                        },
                        {
                            name: i18next.t("page.private.dashboard.facilityManagement.facilityManagement.dueDatesExceeded"),
                            data: [100, 60, 816, 250, 350, 300, 400, 100, 60, 500, 200, 50, 300]
                        }
                    ]}
                    type="line"
                    height="100%"
                />}
                {this.state.chart === "energy" && <Chart
                    options={getAccountingChartOptions(["#ffffff", "#2ecc71"])}
                    series={[
                        {
                            name: i18next.t("page.private.dashboard.facilityManagement.energy.target"),
                            data: [625, 123, 425, 124, 453, 236, 434, 256, 236, 634, 324, 563, 180, 244]
                        },
                        {
                            name: i18next.t("page.private.dashboard.facilityManagement.energy.actual"),
                            data: [500, 60, 816, 250, 350, 300, 400, 300, 320, 500, 200, 100, 250, 300]
                        }
                    ]}
                    type="line"
                    height="100%"
                />}
            </div>
        </Charts>
    }
}

const ViewAllAgendaEntries = styled.div`
    color: #3b97d3;
    user-select: none;
    cursor: pointer;
    transition: opacity 0.3s;
    
    &:hover {
        opacity: 0.75;
    }
`

class AgendaItem extends Component {
    state = { checked: false }

    render() {
        return <AgendaEntry checked={this.state.checked}>
            <div className="checkbox" onClick={() => {
                if(!this.state.checked) {
                    this.setState({ checked: true })

                    if(this.props.google) {
                        fetch(scanmetrix.backendURL + "/integration/google_workspace/finish_task", {
                            method: "POST",
                            credentials: "include",
                            body: JSON.stringify({ taskId: this.props.id, tasklistId: this.props.tasklist }),
                            headers: {
                                "Content-Type": "application/json"
                            }
                        }).then(result => result.json()).then(result => {
                            if(result.status === "SUCCESSFUL") setTimeout(this.props.refresh, 500)
                        })
                    } else {
                        scanmetrix.client.mutate({
                            mutation: scanmetrix.gql`
                                mutation($id: ID!) {
                                    updateAgendaItem(id: $id, done: true)
                                }
                            `,
                            variables: {
                                id: this.props.id
                            }
                        }).then(data => {
                            if(data.data.updateAgendaItem) {
                                setTimeout(this.props.refresh, 500)
                            }
                        })
                    }
                }
            }}>{this.state.checked && <i className="far fa-check" />}</div>
            <p className="title">{this.props.google && <i className="fab fa-google" style={{ color: "#3b97d3" }} />} {this.props.title}</p>
        </AgendaEntry>
    }
}

const RSS = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 48px;
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 9;
  grid-row-end: 10;
  padding-bottom: 64px;

  @media screen and (max-width: 1919px) {
    grid-template-columns: 1fr 1fr;
    grid-row-start: 12;
    grid-row-end: 13;
  }

  @media screen and (max-width: 1400px) {
    grid-template-columns: 1fr;
  }
`

const RSSPost = styled.div`
    background: white;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 4px 24px -8px rgba(0, 0, 0, 0.15);
    position: relative;
    padding-top: 64px;
    
    .watermark {
        position: absolute;
        font-size: 200px;
        bottom: 8px;
        right: 32px;
        opacity: 0.015;
    }
  
    .title {
        font-size: 1.2em;
        margin-bottom: 8px;
    }
  
    .date {
        margin-bottom: 8px;
        font-size: 0.8em;
        position: absolute;
        left: -8px;
        top: 24px;
        background: #3b97d3;
        color: white;
        height: 24px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        padding-left: 16px;
        border-radius: 0 4px 4px 4px;
        user-select: none;
      
        &::before {
            display: block;
            content: " ";
            position: absolute;
            left: 0;
            top: -6px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #16669a;
            transform: rotate(-225deg);
            z-index: -1;
        }
    }
  
    .description {
        line-height: 1.6em;
        opacity: 0.75;
    }
  
    .read {
        color: #3b97d3;
        margin-top: 12px;
        user-select: none;
        cursor: pointer;
        font-weight: bold;
        transition: opacity 0.3s;
      
        &:hover {
            opacity: 0.75;
        }
      
        i {
            margin-right: 4px;
        }
    }
`

const NoDueDates = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    margin-top: 64px;
    
    h2 {
        font-weight: normal;
        margin-bottom: 8px;
        font-size: 20px;
    }
    
    p {
        max-width: 50%;
        opacity: 0.75;
        text-align: center;
    }
    
    img {
        max-width: 200px;
        opacity: 0.5;
        margin-top: 32px;
        filter: grayscale(100%);
        -webkit-user-drag: none;
    }
`

class OpenConsultationModal extends Component {
    render() {
        return <Modal maxWidth="450px" instance={ref => {
            if(!Cookies.get("open_consultation_closed") && moment().isBefore(moment("30.11.2023", "DD.MM.YYYY"))) ref.open()
        }}>
            <Modal.Head title="Jetzt neu: Offene Sprechstunde" icon="screen-users" />
            <Modal.Body padding={24}>
                <img src="/welcome.svg" style={{ margin: "0 auto", display: "inline-block", marginBottom: 32, width: "250px" }}  />
                <div style={{ width: "600px", userSelect: "none" }}>
                    <h2 style={{ marginBottom: 16, fontWeight: "normal" }}><b>Jetzt neu:</b> Offene Sprechstunde</h2>
                    <div style={{ lineHeight: "22px" }}>Beginnend ab Oktober 2023 halten wir bis zu zwei Mal wöchentlich eine offene Sprechstunde online ab, an welcher alle Kunden und Endanwender nach vorheriger Anmeldung teilnehmen können.<br /><br />Die Sprechstunde ist der richtige Ort für Anregungen, Prozessabstimmungen, Feedback und Anwendungsfragen.<br /><br />Wir hoffen, durch Einführung dieser Meetings den persönlichen Kontakt zu unseren Kunden zu verstärken und mehr Einblick in die Weiterentwicklung unserer Plattform zu gewähren.<br /><br />Die Anmeldung kann rund um die Uhr mit einem Klick auf das <b style={{ color: "#3b97d3" }}><i className="fa-duotone fa-megaphone" /> Megafonsymbol</b> in der linken Menüleiste durchgeführt werden.</div>
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button thick title="Verstanden, nicht mehr anzeigen" icon="check" onClick={() => {
                Cookies.set("open_consultation_closed", true, { expires: 90 })
                instance.close()
            }} />} />
        </Modal>
    }
}





















class DemoModal extends Component {
    render() {
        return <Modal maxWidth="450px" instance={ref => {
            if(!Cookies.get("welcome_closed")) ref.open()
        }}>
            <Modal.Head title={i18next.t("page.private.dashboard.facilityManagement.testPhase.titleForm")} icon="gift" />
            <Modal.Body padding={24}>
                <img src="/welcome.svg" style={{ margin: "0 auto", display: "inline-block", marginBottom: 32, width: "250px" }}  />
                <div style={{ width: "600px", userSelect: "none" }}>
                    <h2 style={{ marginBottom: 16, fontWeight: "normal" }}>{i18next.t("page.private.dashboard.facilityManagement.testPhase.description1")}</h2>
                    {i18next.t("page.private.dashboard.facilityManagement.testPhase.description2")}<br />
                    {i18next.t("page.private.dashboard.facilityManagement.testPhase.description3")}<br />
                    {i18next.t("page.private.dashboard.facilityManagement.testPhase.description4")}<br /><br />
                    {i18next.t("page.private.dashboard.facilityManagement.testPhase.description5")} <b style={{ color: "#3b97d3" }}>{i18next.t("page.private.dashboard.facilityManagement.testPhase.description6")}</b> {i18next.t("page.private.dashboard.facilityManagement.testPhase.description7")}<br /><br />
                    {i18next.t("page.private.dashboard.facilityManagement.testPhase.description8")}<br /><br />
                    {i18next.t("page.private.dashboard.facilityManagement.testPhase.description9")}<br />
                    {i18next.t("page.private.dashboard.facilityManagement.testPhase.description10")}
                </div>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button thick title={i18next.t("page.private.dashboard.facilityManagement.testPhase.exitButton")} icon="check" onClick={() => {
                Cookies.set("welcome_closed", true, { expires: 90 })
                instance.close()
            }} />} />
        </Modal>
    }
}

class LastUserActivities extends Component {
    render() {
        const users = this.props.users
        console.log(users)
        return <>
            <UserActivitiesModal users={users} instance={ref => this.userActivitiesModal = ref} />
            <Users rowStart={this.props.rowStart} rowEnd={this.props.rowEnd} columnEnd={this.props.columnEnd}>
                <ScrollContainer horizontal={true} vertical={false} style={{ display: "flex", overflowY: "visible", height: 70 }}>
                    {users.map(user => <User lastActivity={user.lastActivity} key={user.id} id={user.id} email={user.email} name={`${user.firstName} ${user.lastName}`} />)}
                </ScrollContainer>

                <div className="rightButton" onClick={() => this.userActivitiesModal.open()}>
                    <p>{i18next.t("page.private.dashboard.facilityManagement.activeUsersShow")}</p>
                    <i className='far fa-users' />
                </div>
            </Users>
        </>
    }
}

class LastVisitComponent extends Component {
    render() {
        return <LastVisit>
            <div className="left">
                <h1>{i18next.t("page.private.dashboard.welcome.title", { name: `${i18next.t(`defaults.salutation.${scanmetrix.session.salutation}`)} ${scanmetrix.session.lastName}` })}</h1>
                <h2 dangerouslySetInnerHTML={{__html: i18next.t("page.private.dashboard.welcome.content", { agendaPercentage: Number.isNaN(parseInt(this.props.statistics.agendaDone, 10)) ? "0" : parseInt(this.props.statistics.agendaDone * 100, 10), interpolation: { escapeValue: false } })}} />
            </div>
            <img src={`${scanmetrix.backendURL}/logo`} />
        </LastVisit>
    }
}







     // Translation of months of Upcoming TGA exams section
     const germanMonthLabels = [
        "Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"
    ];

    const englishMonthLabels = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];


    const getMonthLabels = (language) => {
        if (language === "de_DE") {
            return germanMonthLabels;
        } else if (language === "en_US") {
            return englishMonthLabels;
        }
        return englishMonthLabels;
    };





export default withRouter(class extends Component {     ///HauptContainer
    state = {editing: false, isOn: false ,dueDateType: "LZ", improve: null, statistics: {}, blogPosts: [], provisionIntervals: null, agendaEntries: null, dueDates: null, users: [], news: [],
    selected: null,
    items: [],
    selectedEmail: '',
    inputValue: '',
    filteredWidgetSelectBlock: [],
    layout: [
        {i: 'a', x: 0, y: 0, w: 1, h: 1},
      ],
      tableUnits: []
    }

    constructor(props) {
        super(props);
/*
        const widgetSelectBlock = [
        { key: 1, grid: "gridItem", label:"overdueBuildingInspections",  w: 1, h: 4,    minW: 1, maxW: 1,minH: 4,maxH: 4,   onClick: () => { if(scanmetrix.checkPermission("BuildingInspection") > 0) this.props.history.push("/buildinginspections", { tab: 1 }) },  class: (scanmetrix.checkPermission("BuildingInspection") > 0 ? 'box-body clickable' : 'box-body'),           icon:"fal fa-person-walking",                        categoryName:(i18next.t("page.private.dashboard.facilityManagement.overdueInspections")) , categoryValue:"?",   },
        { key: 2, grid: "gridItem",label:"inventoriedCustomerObjects", w: 1, h: 4,    minW: 1, maxW: 1,minH: 4,maxH: 4,     click: (""),class: 'box-body clickable',            icon:"fal fa-qrcode",               categoryName: (i18next.t("page.private.dashboard.facilityManagement.inventoriedObjects")),         categoryValue:"?",   },
        { key: 3, grid: "gridItem",label:"userCount", w: 1, h: 4,    minW: 1, maxW: 1,minH: 4,maxH: 4,                      onClick: () => this.props.history.push("/facilitymanagement") ,class: 'box-body clickable',            icon:"fal fa-user",                 categoryName: (i18next.t("page.private.dashboard.facilityManagement.activeUser")),                 categoryValue:"?",   },
        { key: 4, grid: "gridItem",label:"subsidiaryCount", w: 1, h: 4,    minW: 1, maxW: 1,minH: 4,maxH: 4,                onClick:() => this.props.history.push("/facilitymanagement"),class: 'box-body clickable',            icon:"fal fa-map-marker",           categoryName: (i18next.t("page.private.dashboard.facilityManagement.branches")),                   categoryValue:"?",   },
        { key: 5, grid: "gridItem",label:"serviceProviderCount", w: 1, h: 4,    minW: 1, maxW: 1,minH: 4,maxH: 4,           onClick:() => this.props.history.push("/serviceproviders"),class: 'box-body clickable',            icon:"fal fa-hard-hat",             categoryName: (i18next.t("page.private.dashboard.facilityManagement.serviceProvider")),            categoryValue:"?",   },
        { key: 6, grid: "gridItem",label:"flawCount", w: 1, h: 4,    minW: 1, maxW: 1,minH: 4,maxH: 4,                      onClick:() => this.props.history.push("/escalation"),class: 'box-body clickable',            icon:"fal fa-bolt",                 categoryName: (i18next.t("page.private.dashboard.facilityManagement.defects")),                    categoryValue:"?",   },
        { key: 7, grid: "gridItem",label:"overdueContractIntervals", w: 1, h: 4,    minW: 1, maxW: 1,minH: 4,maxH: 4,       onClick:() => this.props.history.push("/contracts", { tab: 2 }),class: 'box-body clickable',            icon:"fal fa-hourglass",            categoryName: (i18next.t("page.private.dashboard.facilityManagement.overdueServices")),            categoryValue:"?",   },
        { key: 8, grid: "gridItem",label:"followupContractProvisionProofs", w: 1, h: 4,    minW: 1, maxW: 1,minH: 4,maxH: 4,onClick:() => this.props.history.push("/contracts", { tab: 1 }),class: 'box-body clickable',            icon:"fal fa-file-certificate",     categoryName: (i18next.t("page.private.dashboard.facilityManagement.proofPerformance")),           categoryValue:"?",   },
        { key: 9, grid: "gridItem",label:"missingTga", w: 1, h: 4,    minW: 1, maxW: 1,minH: 4,maxH: 4,                     onClick:() => this.props.history.push("/facilitymanagement", { tab: 2 }),class: 'box-body clickable',            icon:"fal fa-exclamation-triangle", categoryName: (i18next.t("page.private.dashboard.facilityManagement.undeclaredTradeAssignments")), categoryValue:"?",   },
        { key: 10,grid: "gridItem",label:"userActive", w: 1, h: 4,    minW: 1, maxW: 1,minH: 4,maxH: 4,                     onClick:() => this.props.history.push("/duedates"),class: 'box-body clickable',            icon:"fal fa-folder-tree",          categoryName: (i18next.t("page.private.dashboard.facilityManagement.overdueTGA")),                 categoryValue:"?",   },
        { key: 11,grid: "",label:"lastVisit", w: 3, h: 7,    minW: 3, maxW: 3, minH: 7, maxH: 7,                            icon: "fa-light fa-ballot-check", categoryName: "Agenda", class: '',                     component: <LastVisitComponent statistics={this.state.statistics} />},
        { key: 12,grid: "",label:"calendar", w: 2, h: 5,    minW: 2, maxW: 2, minH: 4, maxH: 5,                             icon: "fa-regular fa-inbox-full", categoryName: "Anstehende TGA-Prüfungen", class: 'box-body clickable',   component:  <div style={{ gridColumn: "span 2", background: "white", height: "100%", position: "relative", borderRadius: "16px", boxSizing: "border-box", padding: "16px", paddingBottom: 0, boxShadow: "0 4px 24px -8px rgba(0, 0, 0, 0.15)" }}> <p style={{ fontSize: 14, opacity: 0.75, marginBottom: 8 }}>{i18next.t("page.private.dashboard.facilityManagement.pendingTGA")}</p> <CalendarHeatmap startDate={moment().subtract(14, "days").toDate()} endDate={moment().add(135, "days")} showWeekdayLabels={false} showMonthLabels={true} showOutOfRangeDays={true} gutterSize={1}  classForValue={(value) => "color-scale-100"} values={[]}  /> </div> },
        { key: 13,grid: "",label:"rating", w: 3, h: 4,    minW: 3, maxW: 4, minH: 4, maxH: 4,                               icon: "fa-light fa-face-smile", categoryName: (i18next.t("page.private.dashboard.facilityManagement.overallRating.ratingImprovement")), class: 'box-body clickable',   component: <Rating><div className="improve" onClick={() => this.setState({ improve: 0 })}>{i18next.t("page.private.dashboard.facilityManagement.overallRating.ratingImprovement")}</div> <div className="info"><p className="title">{i18next.t("page.private.dashboard.facilityManagement.overallRating.overallRatingTitle")}</p><p className="subtitle">{i18next.t("page.private.dashboard.facilityManagement.overallRating.factors")}</p><ul><li>{i18next.t("page.private.dashboard.facilityManagement.overallRating.functionalReadiness")}</li><li>{i18next.t("page.private.dashboard.facilityManagement.overallRating.correcteddeficiencies")}</li><li>{i18next.t("page.private.dashboard.facilityManagement.overallRating.complianceInspectionDeadlines")}</li></ul></div><div className="right">{Object.keys(this.state.statistics).length > 0 && <><Chart height="300px" options={{chart: {type: 'radialBar',offsetY: -16,sparkline: {enabled: true}},plotOptions: {radialBar: {startAngle: -90,endAngle: 90,hollow: {margin: 0,size: '60%',background: '#fff',image: undefined,imageOffsetX: 0,imageOffsetY: 0,position: 'front',dropShadow: {enabled: false}},track: {background: "#e7e7e7",strokeWidth: '100%',margin: 5,dropShadow: {enabled: false}},dataLabels: {name: {show: false},value: {offsetY: -2, fontSize: '22px',formatter: val => `${Number(val).toFixed(2)} P`}}}},grid: {padding: {top: -10}},fill: {colors: [ colors[0] ], gradient: {type: "horizontal",gradientToColors: [ colors[1] ], stops: [0, 100]},type: "gradient" }}} series={[gaugePercentage]} type="radialBar" /><i className={`fal fa-${emote}`} style={{ color: colors[0] }} /></>}</div></Rating>},
        { key: 14,grid: "",label:"calendarModule", w: 2, h: 10,   minH: 10,maxH: 10, minW: 2,                               icon: "fa-light fa-calendar", categoryName: "Kalendar", class: 'box-body clickable',   component: <CalendarModule rowStart={5} rowEnd={6} />   },
        { key: 15,grid: "",label:"lastUser", w: 5, h: 3,    minH: 3, maxH: 3, minW: 5,                                      icon: "fa-light fa-person-circle-question", categoryName: "Letzte Aktivitäten", class: 'box-body clickable',   component: <div style={{overflow:"hidden"}}><LastUserActivities users={UserExample.data.Users.nodes} rowStart={7} rowEnd={8} /></div>  },
        { key: 16,grid: "",label:"dueDates", w: 4, h: 10,   minW: 4,  minH: 10, maxH: 10,                                   icon: "fa-solid fa-table-list", categoryName: "Anstehende Fälligkeiten", class: 'box-body clickable',   component: <DueDatesContainer>
          <DueDates>
            <table>
                <thead>
                <tr>
                    <th>{i18next.t("page.private.dashboard.facilityManagement.tableTGA.identifier")}</th>
                    <th>{i18next.t("page.private.dashboard.facilityManagement.tableTGA.place")}</th>
                    <th>{i18next.t("page.private.dashboard.facilityManagement.tableTGA.name")}</th>
                    <th>{i18next.t("page.private.dashboard.facilityManagement.tableTGA.serviceProvider")}</th>
                    <th>{i18next.t("page.private.dashboard.facilityManagement.tableTGA.due")}</th>
                </tr>
                </thead>
                <tbody>
                {dueDatesExample.facilities.slice(0, 8).map((dueDate, index) => { //kann auf 8 oder auch 10
                    const due = moment(dueDate.due, "DD.MM.YYYY")
                    return <tr key={index}>
                        <td>{dueDate.label}</td>
                        <td>{dueDate.postalCode} {dueDate.city}</td>
                        <td>{dueDate.amount || "?"} Stk. ({dueDate.name})</td>
                        <td>{dueDate.building}</td>
                        <td><div className={`dot ${due.isSameOrBefore(moment()) ? "red" : (moment().add(dueDate.intervalAmount / 10, dueDate.intervalType).isSameOrBefore(due) ? "green" : "yellow")}`} />{due.fromNow()}</td>
                    </tr>
                })}
                </tbody>
            </table>
          </DueDates>
        </DueDatesContainer>},
  { key: 17,grid: "",label:"rssPost", x: 0, y: 14, w: 6, h: 17, maxH:17 ,minW: 2, minH: 8,   icon: "fa-light fa-newspaper", class: 'box-body clickable',   categoryName: "FM-Nachrichten",            disabled: false,    component:
    <RSS>
        <div className="rssGrid">
     {this.state.news.splice(0, 6).map((post, index) =>
      <RSSPost key={index}>

        <p className="date">{moment(post.date).fromNow()}</p>
        <p className="title">{post.title.replace(/&quot;/g, "\"").replace(/&#039;/g, "'").replace(/&amp;/g, "&")}</p>
        <p className="description">{post.description}</p>
        <div className="read" onClick={() => window.open(post.url, "_blank")}><i className="far fa-arrow-right" />Weiterlesen</div>
        <i className="far fa-newspaper watermark" />

      </RSSPost>
     )}
     </div>
    </RSS>   },
]








        this.widgetSelectBlock = widgetSelectBlock

        this.state.filteredWidgetSelectBlock = widgetSelectBlock*/

        fetch(scanmetrix.backendURL + "/news", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(news => {
            console.log(news)
            this.setState({ news: news.posts })
        })

        fetch(scanmetrix.backendURL + "/buildinginspection-intervals", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            if(data) this.setState({ overdueBuildingInspections: data.filter(d => moment().isSameOrAfter(moment(d.due))).length })
        })

        fetch(scanmetrix.backendURL + "/statistics", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(statistics => this.setState({ statistics }))

        fetch(scanmetrix.backendURL + "/due-dates", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            this.setState({ dueDates: data.facilities, tgaOverdue: data.facilities.filter(f => moment().isSameOrAfter(moment(f.due, "DD.MM.YYYY"))).length })
        })

        fetch(scanmetrix.backendURL + "/contract-provision-intervals", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            this.setState({ provisionIntervals: data.contractProvisionIntervals, overdueContractIntervals: data.contractProvisionIntervals.filter(cpi => moment().isSameOrAfter(moment(cpi.date, "DD.MM.YYYY"))).length })
        })

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    ContractProvisionProofs(filter: { tracing_eq: true }) {
                        nodes {
                            id
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ followupContractProvisionProofs: data.data.ContractProvisionProofs.nodes.length })
        })

        fetch(scanmetrix.backendURL + "/subsidiary-missing-work-sections", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            this.setState({ missingTga: data.workSections.map(ws => ws.subsidiaries.length).reduce((a, b) => a + b, 0) })
        })

        new ApolloClient({
            link: createHttpLink({
                uri: "https://api-eu-central-1.graphcms.com/v2/ckc0kun7m08ci01xl4h8500rq/master"
            }),
            cache: new InMemoryCache({ addTypename: false }),
            cachePolicy: { query: false, data: false },
            defaultOptions: {
                watchQuery: {
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only'
                },
                query: {
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only'
                },
                mutate: {
                    errorPolicy: 'all',
                    fetchPolicy: 'no-cache'
                }
            }
        }).query({
            query: gql`
                {
                    blogposts(last: 3, orderBy: date_ASC) {
                        id
                        title
                        shortDescription
                        date
                        icon
                        color {
                            hex
                        }
                    }
                }
            `
        }).then(data => {
            const blogPosts = data.data.blogposts.reverse()




            this.setState({ blogPosts })
        })
        //

        this.fetchAgendaItems = this.fetchAgendaItems.bind(this)

        this.fetchAgendaItems()

        scanmetrix.client.query({
            query: gql`
                {
                    Users {
                        nodes {
                            id
                            firstName
                            lastName
                            email
                            lastActivity
                        }
                    }
                }
            `
        }).then(data => this.setState({ users: data.data.Users.nodes }))
    }



    fetchAgendaItems() {
        return Promise.all([ fetch(scanmetrix.backendURL + "/integration/google_workspace/tasks", {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            if(data.status === "SUCCESSFUL") {
                const agendaItems = data.tasks.map(task => ({
                    id: task.id,
                    title: task.title,
                    tasklist: task.tasklist.id,
                    google: true
                }))

                return agendaItems
            }

            return []
        }), scanmetrix.client.query({
            query: gql`
                {
                    AgendaItems(scope: "all") {
                        nodes {
                            id
                            title
                            doneAt
                        }
                    }
                }
            `
        }).then(data => data.data.AgendaItems.nodes.filter(ag => !ag.doneAt)) ]).then(agendaEntries => {
            this.setState({ agendaEntries: agendaEntries.flat() })
        })
    }



    handleClick = () => {
        this.setState((prevState) => ({
          isOn: !prevState.isOn
        }));
      }





    render() {
        const users = [...this.state.users].filter(us => !!us.lastActivity).sort((a, b) => moment(b.lastActivity).valueOf() - moment(a.lastActivity).valueOf()).splice(0, 5)
        const gaugePercentage = ((this.state.statistics.operative + this.state.statistics.fixedFlaws + this.state.statistics.validIntervals) * 100) / 3
        const emote = gaugePercentage >= 90 ? "smile-wink" : (gaugePercentage >= 80 ? "smile" : (gaugePercentage >= 70 ? "grin-beam-sweat" : (gaugePercentage >= 50 ? "meh" : (gaugePercentage >= 30 ? "frown" : "frown-open"))))
        let colors = [ "#1abc9c", "#2ecc71" ]

        if(gaugePercentage <= 75) colors = [ "#3498db", "#77c5f7" ]
        if(gaugePercentage <= 50) colors = [ "#e67e22", "#f1c40f" ]
        if(gaugePercentage <= 25) colors = [ "#e74c3c", "#e67e22" ]

        let tgaHeatmap = []

        if(this.state.dueDates) {
            this.state.dueDates.forEach(dueDate => {
                const ts = moment(dueDate.due, "DD.MM.YYYY")

                let found = tgaHeatmap.find(tg => tg.date === ts.valueOf())

                if(found) {
                    found.count += (dueDate.amount || 1)
                } else {
                    tgaHeatmap.push({
                        date: ts.valueOf(),
                        count: (dueDate.amount || 1)
                    })
                }
            })
        }




    const toggleEditMode = () => {

        if(this.state.isOn){

               this.setState(prevState => ({
                    isOn: !prevState.isOn
               }));

               this.setState(prevState => ({
                    editing: prevState.editing
               }));
        }
           this.setState(prevState => ({
            editing: !prevState.editing
           }));
    }

       const handleWidgetChange = event => {
            const filteredWidgetSelectBlock = this.widgetSelectBlock.filter(item => {
                if(!event.target.value) return true
              return item.label.toLowerCase().includes((event.target.value || "").toLowerCase())
       });

            this.setState({ inputValue: event.target.value, filteredWidgetSelectBlock });
          };





        return <Page fullHeight maxSize="100%" full {...this.props}  >


            <ReactJoyride locale={{
                back: 'Zurück', close: 'Schließen', last: 'Abschließen', next: 'Weiter', open: 'Dialog öffnen', skip: 'Überspringen'
            }} styles={{
                options: {
                    primaryColor: "#3b97d3"
                }
            }}
                callback={event => {
                if((event.action === "close" || event.action === "reset") && this.state.improve !== null) {
                    this.setState({ improve: null })
                    this.joyrideHelpers.reset(true)
                }
            }} disableScrolling={true} stepIndex={this.state.improve} getHelpers={helpers => this.joyrideHelpers = helpers} run={this.state.improve !== null} continuous={true} steps={[
                {
                    target: ".joyride1",
                    content: i18next.t("page.private.dashboard.facilityManagement.overallRating.Rating1")
                },
                {
                    target: ".joyride2",
                    content: i18next.t("page.private.dashboard.facilityManagement.overallRating.Rating2")
                },
                {
                    target: ".joyride3",
                    content: i18next.t("page.private.dashboard.facilityManagement.overallRating.Rating3")
                },
                {
                    target: ".joyride4",
                    content: i18next.t("page.private.dashboard.facilityManagement.overallRating.Rating4")
                },
                {
                    target: ".joyride5",
                    content: i18next.t("page.private.dashboard.facilityManagement.overallRating.Rating5")
                },
                {
                    target: ".joyride6",
                    placement: "top",
                    content: i18next.t("page.private.dashboard.facilityManagement.overallRating.Rating6")
                }
            ]} />

            <OpenConsultationModal />

            <Container style={{position: "relative", alignItems:"center", gridTemplateColumns: this.state.isOn ? "100%" : "75% 25%",  flexDirection:"column"}}>
                {/*<button id="sidebarOpener" onClick={this.handleClick} style={{right:"0",display: this.state.isOn ? "flex" : "none", justifyContent:"center", alignItems:"center",zIndex: "0",top:"50%", borderRadius:"50%",boxShadow:"rgba(0, 0, 0, 0.25) 0px 2px 6px 0px", width:"30px", height:"30px", padding:"4px"}}>
                <i style={{fontSize:"0.9rem"}} id="sidebarOpenerIcon" class="fa-solid fa-arrow-left"></i></button>*/}

                  {/*<Section.Item title={"Standard"} style={{marginLeft:"50px"}}>*/}

                {scanmetrix.session.perspective === "facilityManagement" && <>

                    <Grid>

                        <div style={{ gridColumnStart: 1, gridColumnEnd: 6, display: "grid", gridTemplateColumns: "3fr 2fr", gridGap: "32px" }}>
                            <LastVisitComponent statistics={this.state.statistics} />
                            <Rating>
                                <div className="improve" onClick={() => this.setState({ improve: 0 })}>{i18next.t("page.private.dashboard.facilityManagement.overallRating.ratingImprovement")}</div>
                                <div className="info">
                                    <p className="title">{i18next.t("page.private.dashboard.facilityManagement.overallRating.overallRatingTitle")}</p>
                                    <p className="subtitle">{i18next.t("page.private.dashboard.facilityManagement.overallRating.factors")}</p>
                                    <ul>
                                        <li>{i18next.t("page.private.dashboard.facilityManagement.overallRating.functionalReadiness")}</li>
                                        <li>{i18next.t("page.private.dashboard.facilityManagement.overallRating.correcteddeficiencies")}</li>
                                        <li>{i18next.t("page.private.dashboard.facilityManagement.overallRating.complianceInspectionDeadlines")}</li>
                                    </ul>
                                </div>
                                <div className="right">
                                    {Object.keys(this.state.statistics).length > 0 && <><Chart height="300px" options={{
                                        chart: {
                                            type: 'radialBar',
                                            offsetY: -16,
                                            sparkline: {
                                                enabled: true
                                            }
                                        },
                                        plotOptions: {
                                            radialBar: {
                                                startAngle: -90,
                                                endAngle: 90,
                                                hollow: {
                                                    margin: 0,
                                                    size: '60%',
                                                    background: '#fff',
                                                    image: undefined,
                                                    imageOffsetX: 0,
                                                    imageOffsetY: 0,
                                                    position: 'front',
                                                    dropShadow: {
                                                        enabled: false
                                                    }
                                                },
                                                track: {
                                                    background: "#e7e7e7",
                                                    strokeWidth: '100%',
                                                    margin: 5,
                                                    dropShadow: {
                                                        enabled: false
                                                    }
                                                },
                                                dataLabels: {
                                                    name: {
                                                        show: false
                                                    },
                                                    value: {
                                                        offsetY: -2,
                                                        fontSize: '22px',
                                                        formatter: val => `${Number(val).toFixed(2)} P`
                                                    }
                                                }
                                            }
                                        },
                                        grid: {
                                            padding: {
                                                top: -10
                                            }
                                        },
                                        fill: {
                                            colors: [ colors[0] ],
                                            gradient: {
                                                type: "horizontal",
                                                gradientToColors: [ colors[1] ],
                                                stops: [0, 100]
                                            },
                                            type: "gradient"
                                        }
                                    }} series={[gaugePercentage]} type="radialBar" />
                                        <i className={`fal fa-${emote}`} style={{ color: colors[0] }} /></>}
                                </div>
                            </Rating>
                        </div>
                        <ElementTitle className='overview'>{i18next.t("page.private.dashboard.facilityManagement.overview")}</ElementTitle>

                        {/* RESPONSIVE LAYOUT ( MAX WIDTH: 1919PX ) */}
                        {/*<OverviewWrapper>
                            <div className='box-body'>
                                <div className='aspect-ratio'>
                                    <div className="content">
                                        <i className="fal fa-wrench" />
                                        <p className="title">Autark-Aufträge</p>
                                        <p className="value">{this.state.statistics.workOrders === undefined && <StatisticPlaceholder />}{this.state.statistics.workOrders}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='box-body'>
                                <div className='aspect-ratio'>
                                    <div className="content">
                                        <i className="fal fa-qrcode" />
                                        <p className="title">Inventarisierte Objekte</p>
                                        <p className="value">{this.state.statistics.inventoriedCustomerObjects === undefined && <StatisticPlaceholder />}{this.state.statistics.inventoriedCustomerObjects}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='box-body'>
                                <div className='aspect-ratio'>
                                    <div className="content">
                                        <i className="fal fa-user" />
                                        <p className="title">Aktive Benutzer</p>
                                        <p className="value">{this.state.statistics.userCount === undefined && <StatisticPlaceholder />}{this.state.statistics.userCount}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='box-body'>
                                <div className='aspect-ratio'>
                                    <div className="content">
                                        <i className="fal fa-map-marker" />
                                        <p className="title">Niederlassungen</p>
                                        <p className="value">{this.state.statistics.subsidiaryCount === undefined && <StatisticPlaceholder />}{this.state.statistics.subsidiaryCount}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='box-body'>
                                <div className='aspect-ratio'>
                                    <div className="content">
                                        <i className="fal fa-hard-hat" />
                                        <p className="title">Dienstleister</p>
                                        <p className="value">{this.state.statistics.serviceProviderCount === undefined && <StatisticPlaceholder />}{this.state.statistics.serviceProviderCount}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='box-body'>
                                <div className='aspect-ratio'>
                                    <div className="content">
                                        <i className="fal fa-bolt" />
                                        <p className="title">Offene Mängel</p>
                                        <p className="value">{this.state.statistics.flawCount === undefined && <StatisticPlaceholder />}{this.state.statistics.flawCount}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='box-body'>
                                <div className='aspect-ratio'>
                                    <div className="content">
                                        <i className="fal fa-hourglass" />
                                        <p className="title">Überfällige Leistungen</p>
                                        <p className="value">{this.state.overdueContractIntervals === undefined && <StatisticPlaceholder />}{this.state.overdueContractIntervals}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='box-body'>
                                <div className='aspect-ratio'>
                                    <div className="content">
                                        <i className="fal fa-file-certificate" />
                                        <p className="title">Nachverfolgungen Leistungsnachweis</p>
                                        <p className="value">{this.state.followupContractProvisionProofs === undefined && <StatisticPlaceholder />}{this.state.followupContractProvisionProofs}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='box-body'>
                                <div className='aspect-ratio'>
                                    <div className="content">
                                        <i className="fal fa-exclamation-triangle" />
                                        <p className="title">Undeklarierte Gewerkszuordnungen</p>
                                        <p className="value">{this.state.missingTga === undefined && <StatisticPlaceholder />}{this.state.missingTga}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='box-body'>
                                <div className='aspect-ratio'>
                                    <div className="content">
                                        <i className="fal fa-folder-tree" />
                                        <p className="title">Überfällige TGA-Prüfungen</p>
                                        <p className="value">{this.state.tgaOverdue === undefined && <StatisticPlaceholder />}{this.state.tgaOverdue}</p>
                                    </div>
                                </div>
                            </div>
                        </OverviewWrapper>*/}

                        {/* NON-RESPONSIVE LAYOUT ( MIN WIDTH: 1920PX ) */}
                        <OverviewWrapperMaxwidth  >
                            {/*<Tilt>
                                <div className='box-body clickable' onClick={() => this.props.history.push("/workorders", { tab: 1 })}>
                                    <div className="content">
                                        <i className="fal fa-wrench" />
                                        <p className="title">Autark-Aufträge</p>
                                        <p className="value">{this.state.statistics.workOrders === undefined && <StatisticPlaceholder />}{this.state.statistics.workOrders}</p>
                                    </div>
                                </div>
                            </Tilt>*/}
                            <Tilt>
                                <div className={scanmetrix.checkPermission("BuildingInspection") > 0 ? 'box-body clickable' : 'box-body'} onClick={() => {
                                    if(scanmetrix.checkPermission("BuildingInspection") > 0) this.props.history.push("/buildinginspections", { tab: 1 })
                                }}>
                                    <div className="content">
                                        <i className="fal fa-person-walking" />
                                        <p className="title">{i18next.t("page.private.dashboard.facilityManagement.overdueInspections")}</p>
                                        <p className="value">{this.state.overdueBuildingInspections === undefined && <StatisticPlaceholder />}{this.state.overdueBuildingInspections}</p>
                                    </div>
                                </div>
                            </Tilt>
                            <Tilt>
                                <div className='box-body blue'>
                                    <div className="content">
                                        <i className="fal fa-qrcode" />
                                        <p className="title">{i18next.t("page.private.dashboard.facilityManagement.inventoriedObjects")}</p>
                                        <p className="value">{this.state.statistics.inventoriedCustomerObjects === undefined && <StatisticPlaceholder />}{this.state.statistics.inventoriedCustomerObjects}</p>
                                    </div>
                                </div>
                            </Tilt>
                            <Tilt>
                                <div className='box-body clickable' onClick={() => this.props.history.push("/users")}>
                                    <div className="content">
                                        <i className="fal fa-user" />
                                        <p className="title">{i18next.t("page.private.dashboard.facilityManagement.activeUser")}</p>
                                        <p className="value">{this.state.statistics.userCount === undefined && <StatisticPlaceholder />}{this.state.statistics.userCount}</p>
                                    </div>
                                </div>
                            </Tilt>
                            <Tilt>
                                <div className='box-body map clickable' onClick={() => this.props.history.push("/facilitymanagement")}>
                                    <div className="content">
                                        <i className="fal fa-map-marker" />
                                        <p className="title">{i18next.t("page.private.dashboard.facilityManagement.branches")}</p>
                                        <p className="value">{this.state.statistics.subsidiaryCount === undefined && <StatisticPlaceholder />}{this.state.statistics.subsidiaryCount}</p>
                                    </div>
                                </div>
                            </Tilt>
                            <Tilt>
                                <div className='box-body clickable' onClick={() => this.props.history.push("/serviceproviders")}>
                                    <div className="content">
                                        <i className="fal fa-hard-hat" />
                                        <p className="title">{i18next.t("page.private.dashboard.facilityManagement.serviceProvider")}</p>
                                        <p className="value">{this.state.statistics.serviceProviderCount === undefined && <StatisticPlaceholder />}{this.state.statistics.serviceProviderCount}</p>
                                    </div>
                                </div>
                            </Tilt>
                            <Tilt>
                                <div className='box-body joyride5 clickable' onClick={() => this.props.history.push("/escalation")}>
                                    <div className="content">
                                        <i className="fal fa-bolt" />
                                        <p className="title">{i18next.t("page.private.dashboard.facilityManagement.defects")}</p>
                                        <p className="value">{this.state.statistics.flawCount === undefined && <StatisticPlaceholder />}{this.state.statistics.flawCount}</p>
                                    </div>
                                </div>
                            </Tilt>
                            <Tilt>
                                <div className='box-body joyride1 clickable' onClick={() => this.props.history.push("/contracts", { tab: 2 })}>
                                    <div className="content">
                                        <i className="fal fa-hourglass" />
                                        <p className="title">{i18next.t("page.private.dashboard.facilityManagement.overdueServices")}</p>
                                        <p className="value">{this.state.overdueContractIntervals === undefined && <StatisticPlaceholder />}{this.state.overdueContractIntervals}</p>
                                    </div>
                                </div>
                            </Tilt>
                            <Tilt>
                                <div className='box-body joyride2 clickable' onClick={() => this.props.history.push("/contracts", { tab: 1 })}>
                                    <div className="content">
                                        <i className="fal fa-file-certificate" />
                                        <p className="title">{i18next.t("page.private.dashboard.facilityManagement.proofPerformance")}</p>
                                        <p className="value">{this.state.followupContractProvisionProofs === undefined && <StatisticPlaceholder />}{this.state.followupContractProvisionProofs}</p>
                                    </div>
                                </div>
                            </Tilt>
                            <Tilt>
                                <div className='box-body joyride3 clickable' onClick={() => this.props.history.push("/facilitymanagement", { tab: 2 })}>
                                    <div className="content">
                                        <i className="fal fa-exclamation-triangle" />
                                        <p className="title">{i18next.t("page.private.dashboard.facilityManagement.undeclaredTradeAssignments")}</p>
                                        <p className="value">{this.state.missingTga === undefined && <StatisticPlaceholder />}{this.state.missingTga}</p>
                                    </div>
                                </div>
                            </Tilt>
                            <Tilt>
                                <div className='box-body joyride4 clickable' onClick={() => this.props.history.push("/duedates")}>
                                    <div className="content">
                                        <i className="fal fa-folder-tree" />
                                        <p className="title">{i18next.t("page.private.dashboard.facilityManagement.overdueTGA")}</p>
                                        <p className="value">{this.state.tgaOverdue === undefined && <StatisticPlaceholder />}{this.state.tgaOverdue}</p>
                                    </div>
                                </div>
                            </Tilt>



{/*
                            <GridLayout  onLayoutChange={this.onLayoutChange} layout={this.state.layout} cols={5} rowHeight={30} width={this.state.isOn ? 1330 : 1040}>
                              {this.tableUnits.map(data => (
                                <div className="gridItem" onClick={data.click} key={data.key} data-grid={
                                    {x: data.x, y: data.y, w: data.w, h: data.h,
                                     minH: data.minH, minW: data.minW, maxH: data.maxH, maxW: data.maxW }}>
                                    <div className="content">
                                        <i className={data.icon} />
                                        <p className="title">{data.categoryName}</p>
                                        <p className="value">{data.categoryValue}</p>
                                    </div>
                                    {data.component}
                                </div>
                              ))}
                            </GridLayout>
*/}


                            <div style={{ gridColumn: "span 2", background: "white", height: "100%", position: "relative", borderRadius: "16px", boxSizing: "border-box", padding: "16px", paddingBottom: 0, boxShadow: "0 4px 24px -8px rgba(0, 0, 0, 0.15)" }}>
                                <p style={{ fontSize: 14, opacity: 0.75, marginBottom: 8 }}>{i18next.t("page.private.dashboard.facilityManagement.pendingTGA")}</p>
                                <CalendarHeatmap
                                    startDate={moment().subtract(14, "days").toDate()}
                                    endDate={moment().add(135, "days")}
                                    showWeekdayLabels={false}
                                    showMonthLabels={true}
                                    showOutOfRangeDays={true}
                                    gutterSize={1}
                                    monthLabels={[ "Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez" ]}
                                    classForValue={(value) => {
                                        if (!value) {
                                            return 'color-empty';
                                        }

                                        let max = Math.max(tgaHeatmap.map(t => t.count).reduce((a, b) => a + b, 0), 1)

                                        let pclass = "100"

                                        if(value < (max / 4) * 3) pclass = "75"
                                        if(value < max / 2) pclass = "50"
                                        if(value < max / 4) pclass = "25"

                                        return `color-scale-${pclass}`
                                    }}
                                    values={tgaHeatmap.map(t => ({ date: moment(t.date).toDate(), count: t.count }))}
                                />
                            </div>
                           {/* <AdressButton onEmailSelected={this.emailInputChange} />
                            <input value={this.state.selectedEmail}></input> */}
                        </OverviewWrapperMaxwidth>
                        {/*<ChartModule />*/}
                        {/*<MapModule />*/}
                        <ElementTitle className='duedates' columnStart={1} columnEnd={4} rowStart={4} rowEnd={5}>
                            {i18next.t("page.private.dashboard.facilityManagement.upcomingDueDates")}
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <SwitchButton>
                                    <div onClick={() => this.setState({ dueDateType: "LZ" })} className={this.state.dueDateType === "LZ" ? "selected" : ""}>{i18next.t("page.private.dashboard.facilityManagement.performanceCycles")}</div>
                                    <div onClick={() => this.setState({ dueDateType: "TGA" })} className={this.state.dueDateType === "TGA" ? "selected" : ""}>{i18next.t("page.private.dashboard.facilityManagement.TGASystems")}</div>
                                </SwitchButton>
                                <TitleButton onClick={() => {
                                    if(this.state.dueDateType === "LZ") this.props.history.push("/contracts", { tab: 2 })
                                    else if(this.state.dueDateType === "TGA") this.props.history.push("/duedates")
                                }}>{i18next.t("page.private.dashboard.facilityManagement.viewAllContract")}</TitleButton>
                            </div>
                        </ElementTitle>
                        <DueDatesContainer>
                            {this.state.dueDateType === "LZ" && <DueDates>


                                {this.state.provisionIntervals && !!this.state.provisionIntervals.length && <table>
                                    <thead>
                                        <tr>
                                            <th>{i18next.t("page.private.dashboard.facilityManagement.tableLZ.identifier")}</th>
                                            <th>{i18next.t("page.private.dashboard.facilityManagement.tableLZ.place")}</th>
                                            <th>{i18next.t("page.private.dashboard.facilityManagement.tableLZ.name")}</th>
                                            <th>{i18next.t("page.private.dashboard.facilityManagement.tableLZ.serviceProvider")}</th>
                                            <th>{i18next.t("page.private.dashboard.facilityManagement.tableLZ.due")}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.provisionIntervals && this.state.provisionIntervals.slice(0, 10).map((provisionInterval, index) => {
                                        const due = moment(provisionInterval.date, "DD.MM.YYYY")

                                        return <tr key={index}>
                                            <td>{provisionInterval.subsidiary ? provisionInterval.subsidiary.label : ""}</td>
                                            <td>{provisionInterval.subsidiary ? (provisionInterval.subsidiary.postalCode + " " + provisionInterval.subsidiary.city) : ""}</td>
                                            <td>{provisionInterval.name}</td>
                                            <td>{provisionInterval.contract.partner}</td>
                                            <td><div className={`dot ${due.isSameOrBefore(moment()) ? "red" : (moment().add(provisionInterval.intervalAmount / 10, provisionInterval.intervalType).isSameOrBefore(due) ? "green" : "yellow")}`} />{due.fromNow()}</td>
                                            <td><i onClick={() => this.props.history.push(`/contract/${provisionInterval.contract.id}`)} className="far fa-ellipsis-h" /></td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>}
                                {this.state.provisionIntervals && this.state.provisionIntervals.length === 0 && <NoDueDates>
                                    <h2><i className="far fa-check" /> {i18next.t("page.private.dashboard.facilityManagement.performanceCyclesDescription.text1")}</h2>
                                    <p>{i18next.t("page.private.dashboard.facilityManagement.performanceCyclesDescription.text2")}</p>
                                    <img src="/relax.svg" />
                                </NoDueDates>}
                                {!this.state.provisionIntervals && [0, 0, 0, 0, 0, 0, 0].map((_, index) => <ContentLoader
                                    key={index}
                                    speed={2}
                                    width="100%"
                                    height={48}
                                    viewBox="0 0 100% 64"
                                    foregroundColor="rgba(255, 255, 255, 0.25)"
                                    backgroundColor="rgba(0, 0, 0, 0.1)">
                                    <rect x="0" y="16" rx="4" ry="4" width="calc(20% - 16px)" height="32" />
                                    <rect x="20%" y="16" rx="4" ry="4" width="calc(17.5% - 16px)" height="32" />
                                    <rect x="37.5%" y="16" rx="4" ry="4" width="calc(22.5% - 16px)" height="32" />
                                    <rect x="60%" y="16" rx="4" ry="4" width="calc(15% - 16px)" height="32" />
                                    <rect x="75%" y="16" rx="4" ry="4" width="calc(20% - 16px)" height="32" />
                                    <rect x="95%" y="16" rx="4" ry="4" width="5%" height="32" />
                                </ContentLoader>)}
                            </DueDates>}
                            {this.state.dueDateType === "TGA" && <DueDates>
                                {this.state.dueDates && !!this.state.dueDates.length && <table>
                                    <thead>
                                    <tr>
                                        <th>{i18next.t("page.private.dashboard.facilityManagement.tableTGA.identifier")}</th>
                                        <th>{i18next.t("page.private.dashboard.facilityManagement.tableTGA.place")}</th>
                                        <th>{i18next.t("page.private.dashboard.facilityManagement.tableTGA.name")}</th>
                                        <th>{i18next.t("page.private.dashboard.facilityManagement.tableTGA.serviceProvider")}</th>
                                        <th>{i18next.t("page.private.dashboard.facilityManagement.tableTGA.due")}</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.dueDates && this.state.dueDates.slice(0, 10).map((dueDate, index) => {
                                        const due = moment(dueDate.due, "DD.MM.YYYY")

                                        return <tr key={index}>
                                            <td>{dueDate.label}</td>
                                            <td>{dueDate.postalCode} {dueDate.city}</td>
                                            <td>{dueDate.amount || "?"} Stk. ({dueDate.name})</td>
                                            <td>{dueDate.building}</td>
                                            <td><div className={`dot ${due.isSameOrBefore(moment()) ? "red" : (moment().add(dueDate.intervalAmount / 10, dueDate.intervalType).isSameOrBefore(due) ? "green" : "yellow")}`} />{due.fromNow()}</td>
                                            <td><i onClick={() => this.props.history.push(`/facility/${dueDate.serviceProvider.id}/${dueDate.id}`)} className="far fa-ellipsis-h" /></td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>}
                                {this.state.dueDates && this.state.dueDates.length === 0 && <NoDueDates>
                                    <h2><i className="far fa-check" /> {i18next.t("page.private.dashboard.facilityManagement.TGASystemsDescription.text1")}</h2>
                                    <p>{i18next.t("page.private.dashboard.facilityManagement.TGASystemsDescription.text2")}</p>
                                    <img src="/relax.svg" />
                                </NoDueDates>}
                                {!this.state.dueDates && [0, 0, 0, 0, 0, 0, 0].map((_, index) => <ContentLoader
                                    key={index}
                                    speed={2}
                                    width="100%"
                                    height={48}
                                    viewBox="0 0 100% 64"
                                    foregroundColor="rgba(255, 255, 255, 0.25)"
                                    backgroundColor="rgba(0, 0, 0, 0.1)">
                                    <rect x="0" y="16" rx="4" ry="4" width="calc(20% - 16px)" height="32" />
                                    <rect x="20%" y="16" rx="4" ry="4" width="calc(17.5% - 16px)" height="32" />
                                    <rect x="37.5%" y="16" rx="4" ry="4" width="calc(22.5% - 16px)" height="32" />
                                    <rect x="60%" y="16" rx="4" ry="4" width="calc(15% - 16px)" height="32" />
                                    <rect x="75%" y="16" rx="4" ry="4" width="calc(20% - 16px)" height="32" />
                                    <rect x="95%" y="16" rx="4" ry="4" width="5%" height="32" />
                                </ContentLoader>)}
                            </DueDates>}
                        </DueDatesContainer>
                        <ElementTitle className='calendar' columnStart={4} columnEnd={6} rowStart={4} rowEnd={5}>{i18next.t("page.private.dashboard.facilityManagement.dashboardCalendar")} <TitleButton onClick={() => this.props.history.push("/calendar")}>{i18next.t("page.private.dashboard.facilityManagement.viewAllCalendar")}</TitleButton></ElementTitle>
                        <CalendarModule rowStart={5} rowEnd={6} />
                        <ElementTitle className="users" rowStart={6} rowEnd={7}>{i18next.t("page.private.dashboard.facilityManagement.recentActiveUsers")}</ElementTitle>
                        <LastUserActivities users={users.sort((a, b) => moment(b.lastActivity).valueOf() - moment(a.lastActivity).valueOf())} rowStart={7} rowEnd={8} />
                        <ElementTitle rowStart={8} rowEnd={9} className="news">{i18next.t("page.private.dashboard.facilityManagement.FMnews")} <span style={{ fontSize: "14px", opacity: 0.25, textAlign: "right" }}>({i18next.t("page.private.dashboard.facilityManagement.fmWebsite")})</span></ElementTitle>
                        <RSS>
                            {[...this.state.news].splice(0, 6).map((post, index) => <RSSPost key={index}>
                                <p className="date">{moment(post.date).fromNow()}</p>
                                <p className="title">{post.title.replace(/&quot;/g, "\"").replace(/&#039;/g, "'").replace(/&amp;/g, "&")}</p>
                                <p className="description">{post.description}</p>
                                <div className="read" onClick={() => window.open(post.url, "_blank")}><i className="far fa-arrow-right" />Weiterlesen</div>
                                <i className="far fa-newspaper watermark" />
                            </RSSPost>)}
                        </RSS>
                    </Grid>
                </>}

                    {/*}</Section.Item>*/}
                  {/*<Section.Item title={"Personalisiert"} >


                  <Grid style={{display: "flex", flexDirection:"column", width: "100%"}}>

                      <div style={{display:"flex", justifyContent:"space-between", flexDirection:"reverse",width: this.state.isOn ? "96%" : "100%"}}>
                          <ElementTitle  className='overview'>{i18next.t("page.private.dashboard.facilityManagement.overview")}</ElementTitle>
                          <button onClick={toggleEditMode} style={{minWidth:"176px",whiteSpace:"nowrap",border:"none",borderRadius:"4px",fontSize:"0.9rem",color: "white", width: this.state.isOn ? "15%" : "18%",padding: "10px", background: this.state.editing ? "rgb(231, 76, 60)" : " linear-gradient(30deg, rgb(15, 122, 191), rgb(88, 185, 249))", display:"flex", justifyContent:"space-between", alignItems:"center"}} > Dashboard bearbeiten<i style={{paddingLeft:"4px", paddingRight:"4px"}} className={this.state.editing ? "fa-solid fa-xmark" : "fa-solid fa-pen"} /></button>
                      </div>


                      <GridElement  style={{position:"relative"}} rightContent={<Button thick icon="comment-alt-check" title={i18next.t("page.private.measures.button")} disabled={scanmetrix.checkPermission("Measures") < 2} onClick={() => this.createMeasureModal.open()} />}>
                      {this.state.tableUnits.length === 0 ? (
                        <div style={{position:"absolute",left:"28%", top:"100px",height:"300px", width: "500px", background:"white", display: "flex", justifyContent:"center", alignItems:"center", boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)", borderRadius:"8px"}}>
                           </div>
                           ) : (
                      <OverviewWrapperMaxwidth  >

                            <GridLayout  onLayoutChange={this.onLayoutChange} layout={this.state.layout} cols={6} rowHeight={30} width={this.state.isOn ? 1280 : 1150}
                              {...this.props}
                              layouts={this.state.layouts}
                              onDrop={this.onDrop}
                              // WidthProvider option
                              // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
                              // and set `measureBeforeMount={true}`.
                              isDroppable={true}

                            >
                            {this.state.tableUnits.map(data => {

                              //Dashboard
                              if(data.component) return <div onContextMenu={(e) => this.handleContextMenuDelete(data.key, e)} className={data.grid} onClick={data.click} key={data.key} data-grid={
                                {x: data.x, y: data.y,      w: data.w, h: data.h,
                                 minH: data.minH, minW: data.minW, maxH: data.maxH, maxW: data.maxW }}>
                                       {data.component}
                                    </div>

                               return  (

                               <div  onContextMenu={(e) => this.handleContextMenuDelete(data.key, e)} className={data.grid} onClick={this.state.editing ? "" : data.onClick} key={data.key} data-grid={
                                    {x: data.x, y: data.y,      w: data.w, h: data.h,
                                     minH: data.minH, minW: data.minW, maxH: data.maxH, maxW: data.maxW }}>
                                    <div className="content">
                                        <i className={data.icon} />
                                        <p className="title">{data.categoryName}</p>
                                        <p className="value">{this.determineClass(data)}</p>
                                    </div>

                                </div>
                                )
                                })}
                            </GridLayout>


                        </OverviewWrapperMaxwidth>
                        )}
                        </GridElement>

                      </Grid>


                  </Section.Item>*/}

                {scanmetrix.session.perspective === "propertyManagement" && <PMGrid>
                    <PropertyManagementDashboard users={users} statistics={this.state.statistics} />
                </PMGrid>}

                <SideBar style={{position:"relative",}}>
                {this.state.editing ? (
                   // Inhalt der EditSidebar, wenn im Bearbeitungsmodus
                   <div className="edit-sidebar" style={{position:"relative"}}>
                    <div style={{position:"fixed", top:"48.6%", zIndex:"10"}}>
                        <button id="sidebarOpener" onClick={this.handleClick} style={{ display: "flex", justifyContent:"center",alignItems:"center" , top:"50%",position:"absolute",zIndex: "100", borderRadius:"50%",boxShadow:"rgba(0, 0, 0, 0.25) 0px 2px 6px 0px", width:"30px", height:"30px",  border:"1px blue", padding: "4px"}}>
                             <i style={{fontSize:"0.9rem", fontStyle:"bold"}} id="sidebarOpenerIcon" class="fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                      <div style={{display:"flex", justifyContent: "space-around"}}>
                          <h3 style={{fontStyle:"none", fontWeight:"normal"}}>Widgets Verwalten</h3>
                       </div>

                        <div style={{padding:"12px", overflow:"hidden", display:"flex", justifyContent:"center", width: "100%",}}>
                                 <input style={{border: "1px solid rgb(0, 0, 0, 0.1)",paddingTop:"8px", paddingBottom: "8px",  }} value={this.state.inputValue}  onChange={handleWidgetChange}></input>

                        </div>
                        <div className="widgetSelect" style={{ position:"relative"}}>


                            {this.widgetSelectBlock.map((data, index) => {
                                const disabled = this.state.tableUnits.find(tu => tu.key === data.key)
                                 if(disabled) return <div className="content"> <Tilt className={`box-body`} key={data.key}>
                                    <div className="content">
                                        <i className={data.icon} />
                                        <p className="title">{data.categoryName}</p>
                                        <p style={{fontWeight:"bold"}}>Hinzugefügt!</p>
                                    </div>
                                    </Tilt></div>

                                if(data.component) return <div style={{gridColumn:"span 2"}} key={data.key} onClick={(e) => this.handleContextMenuPush(data.key, e)}>
                                        <div className="Comper" style={{ pointerEvents: "none" }}>
                                          <Tilt>
                                            <div>{data.component}</div>
                                          </Tilt>
                                        </div>
                                    </div>

                                return (


                                        <div key={data.key}
                                              draggable={true}
                                              unselectable="on"
                                              onDragStart={e => e.dataTransfer.setData("text/plain", "")}
                                              onClick={(e) => this.handleContextMenuPush(data.key, e)}
                                              >


                                            <Tilt  disabled={!data.component} className={`box-body`}  data-grid={{x: data.x, y: data.y, w: data.w, h: data.h, minH: data.minH, minW: data.minW, maxH: data.maxH, maxW: data.maxW }}>

                                                <div className="content" >
                                                 <i className={data.icon} />
                                                 <p className="title">{data.categoryName}</p>
                                                 <p className="value">{data.categoryValue}</p>
                                                </div>

                                            </Tilt>
                                        </div>



                            )})}

                        </div>
                   </div>

                   ) : (

                    <>

                    {/*<div style={{position: "fixed", top: "47.6%", background: "none"}}>
                            <button id="sidebarOpener" onClick={this.handleClick} style={{
                                display: this.state.isOn ? "none" : "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                boxShadow: "rgba(0, 0, 0, 0.25) 0px 2px 6px 0px",
                                width: "30px",
                                height: "30px",
                                border: "1px blue",
                                padding: "4px"
                            }}>
                                <i style={{fontSize: "0.9rem", fontStyle: "bold"}} id="sidebarOpenerIcon"
                                   class="fa-solid fa-arrow-right"></i></button>
                        </div>*/}
                        <Agenda>
                            <h1>{i18next.t("page.private.dashboard.facilityManagement.agendaDashboard.title")}{/*<a className="add"><i className="far fa-plus" /></a>*/}</h1>
                            {this.state.agendaEntries !== null && [...this.state.agendaEntries].splice(0, 5).map(agendaEntry =>
                                <AgendaItem tasklist={agendaEntry.tasklist} key={agendaEntry.id}
                                            refresh={() => this.fetchAgendaItems()} google={agendaEntry.google}
                                            title={agendaEntry.title} id={agendaEntry.id}/>)}
                            {this.state.agendaEntries !== null && this.state.agendaEntries.length > 5 &&
                                <ViewAllAgendaEntries
                                    onClick={() => this.props.history.push("/agenda")}>...{i18next.t("page.private.dashboard.facilityManagement.agendaDashboard.and")} {this.state.agendaEntries.length - 5} {i18next.t("page.private.dashboard.facilityManagement.agendaDashboard.furtherEntries")}</ViewAllAgendaEntries>}
                            {this.state.agendaEntries !== null && this.state.agendaEntries.length === 0 && <NothingTodo>
                                <h2>{i18next.t("page.private.dashboard.facilityManagement.agendaDashboard.description")}</h2>
                                <img src="/illustrations/agenda_empty.svg"/>
                            </NothingTodo>}
                            {this.state.agendaEntries === null && [0, 0, 0, 0, 0].map((_, index) => <ContentLoader
                                key={index}
                                speed={2}
                                width="100%"
                                height={28}
                                viewBox="0 0 100% 28"
                                foregroundColor="rgba(255, 255, 255, 0.25)"
                                backgroundColor="rgba(0, 0, 0, 0.1)"
                                style={{marginTop: index === 0 ? 0 : 16}}>
                                <rect x="0" y="0" rx="4" ry="4" width="28" height="28"/>
                                <rect x="42" y="0" rx="4" ry="4" width="calc(100% - 42px)" height="28"/>
                            </ContentLoader>)}
                        </Agenda>
                        {/*<News>
                        <h1>{i18next.t("page.private.dashboard.facilityManagement.latest")}</h1>
                        {this.state.blogPosts.map((blogPost, index) => <Post key={index} onClick={() => window.open(`https://scanmetrix.app/blogpost/${blogPost.id}`, "_blank")}>
                            <p className="date">
                                <i className="fal fa-calendar-day" />
                                {moment(blogPost.date).format("DD.MM.YYYY HH:mm")}
                            </p>
                            <div className="content">
                                <div className="thumbnail" style={{ background: `linear-gradient(45deg,${blogPost.color.hex},${Color(blogPost.color.hex).lighten(0.4).hex()})` }}><i className={`fal fa-${blogPost.icon}`} /></div>
                                <p className="text">
                                    <p className="title">{blogPost.title}</p>
                                    <p className="description">{blogPost.shortDescription}</p>
                                </p>
                            </div>
                        </Post>)}
                        {this.state.blogPosts.length === 0 && [0, 0, 0].map((_, index) => <ContentLoader
                            key={index}
                            speed={2}
                            width="100%"
                            height={106}
                            viewBox="0 0 100% 106"
                            foregroundColor="rgba(255, 255, 255, 0.25)"
                            backgroundColor="rgba(0, 0, 0, 0.1)"
                            style={{ marginTop: index === 0 ? 0 : 48 }}>
                            <rect x="0" y="0" rx="4" ry="4" width="50%" height="18" />
                            <rect x="0" y="26" rx="4" ry="4" width="80" height="80" />
                            <rect x="112" y="26" rx="4" ry="4" width="calc(100% - 112px)" height="21" />
                            <rect x="112" y="34" rx="4" ry="4" width="calc(100% - 112px)" height="72" />
                        </ContentLoader>)}
                    </News>*/}
                        <Feedback>
                            <h1>Roadmap</h1>
                            <h2>Beteilige dich aktiv an der Entwicklung von scanmetrix.</h2>
                            <div data-featurebase-embed></div>
                        </Feedback>
                        <SideStatistics>
                            <h1>{i18next.t("page.private.dashboard.facilityManagement.facilityManagementStatistics")}</h1>
                            <Stat className="joyride6" value={this.state.statistics.operative}
                                  title="Funktionsbereite Objekte"/>
                            <Stat value={this.state.statistics.fixedFlaws} title="Behobene Mängel"/>
                            <Stat value={this.state.statistics.validIntervals} title="Eingehaltene Prüffristen"/>
                        </SideStatistics>
                    </>
                )}
                </SideBar>

            </Container>

        </Page>
    }
})

const Feedback = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
    padding: 0 48px;
    box-sizing: border-box;
    
    > div {
        border: 1px solid rgba(0, 0, 0, 0.05);
        height: 450px;
        width: 100%;
    }
    
    iframe {
        height: 450px !important;
        min-height: 450px !important;
    }
  
  h1 {
    color: #3b97d3;
    margin-top: 32px;
    margin-bottom: 16px;
    font-size: 1.8em;
  }
    
    h2 {
        font-weight: 300;
        font-size: 18px;
        margin-bottom: 16px;
    }
  
  @media screen and (max-width: 1920px) {
    padding: 0 32px;
    font-size: 0.9em;
  }
`

const SwitchButton = styled.div`
  display: flex;
  background: white;
  border-radius: 32px;
  font-size: 0.6em;
  user-select: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.75);
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.2);
  margin-right: 16px;
  overflow: hidden;
  
    div {
      white-space: nowrap;
      user-select: none;
      transition: color 0.3s, background 0.3s;
      padding: 8px 16px;
        
        &:hover {
          background: #f1f1f1;
        }
      
        &.selected {
            background: #2c3e50;
            color: white;
        }
    }
`

export { LastUserActivities, LastVisitComponent }
