import React, { Component } from "react"
import { Page, Section, Breadcrumbs, GridElement, Grid, Input, Button, Modal, Checkbox, Select, Table } from "scanmetrix-components"
import { FileManager } from "../documents"
import UpdateContractModal from "./modals/UpdateContractModal"
import DeleteContractModal from "./modals/DeleteContractModal"

import styled from "styled-components"
import CancelContractModal from "./modals/CancelContractModal"
import CreateSubsidiaryContractModal from "./modals/CreateSubsidiaryContractModal"
import DeleteSubsidiaryContractModal from "./modals/DeleteSubsidiaryContractModal"
import { getIntervalType } from "../object"
import CreateContractExtensionOptionModal from "./modals/CreateContractExtensionOptionModal"
import UpdateContractExtensionOptionModal from "./modals/UpdateContractExtensionOptionModal"
import DeleteContractExtensionOptionModal from "./modals/DeleteContractExtensionOptionModal";
import CreateContractProvisionIntervalModal from "./modals/CreateContractProvisionIntervalModal";
import UpdateContractProvisionIntervalModal from "./modals/UpdateContractProvisionIntervalModal";
import DeleteContractProvisionIntervalModal from "./modals/DeleteContractProvisionIntervalModal";
import CreateContractProvisionProofModal from "./modals/CreateContractProvisionProofModal";
import DeleteContractProvisionProofModal from "./modals/DeleteContractProvisionProofModal";
import UpdateContractProvisionProofModal from "./modals/UpdateContractProvisionProofModal";
import OpenContractProvisionProofDocumentsModal from "./modals/OpenContractProvisionProofDocumentsModal";
import UpdateSubsidiaryContractModal from "./modals/UpdateSubsidiaryContractModal";
import {contractTypes} from "../dashboard/propertyManagement";

import i18next from "i18next";

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

const Tiles = styled.div`
    display: grid;
    margin-bottom: 32px;
    grid-gap: 32px;
    grid-template-columns: 1fr 1fr 1fr;
`

const StyledTile = styled.div`
    box-sizing: border-box;
    padding: 24px;
    background: white;
    border-radius: 5px;
    user-select: none;
    box-shadow: 0 8px 24px -16px rgba(0,0,0,0.4);
    
    .expired {
        font-weight: bold;
        color: #e74c3c;
        height: 16px;
        margin-top: 16px;
        line-height: 16px;
    }
    
    
    .canceled {
        font-weight: bold;
        color: #16a085;
        height: 16px;
        margin-top: 16px;
        line-height: 16px;
        
        &.exp {
            color: #d35400;
        }
    }
    
    .title {
        font-size: 1.2em;
        margin-bottom: 4px;
    }
    
    .progressBar {
        height: 16px;
        width: 100%;
        overflow: hidden;
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.075);
        margin-top: 16px;
        
        .value {
            height: 16px;
            background: #3b97d3;
            border-radius: 5px;
        }
    }
`

class Tile extends Component {
    render() {
        return <StyledTile>
            <div className="title">{this.props.title}</div>
            <div className="text">{this.props.value ? `${this.props.value.fromNow()} (${this.props.value.format("DD.MM.YYYY")})` : i18next.t("page.private.contract.termination.notSpecified")}</div>
            {this.props.value && !this.props.canceled && !this.props.expired && <div className="progressBar">
                <div className="value" style={{ width: `${this.props.progress * 100}%` }} />
            </div>}
            {this.props.expired && !this.props.perpetual && !this.props.canceled && <div className="expired">
                <i className="far fa-times-circle" /> {i18next.t("page.private.contract.termination.expired")}
            </div>}
            {this.props.canceled && <div className={`canceled${this.props.exp ? " exp" : ""}`}>
                {!this.props.exp && <><i className="far fa-check-circle" /> {i18next.t("page.private.contract.termination.text1")} {this.props.canceledAt} {i18next.t("page.private.contract.termination.text2")}</>}
                {this.props.exp && <><i className="far fa-exclamation-circle" /> {i18next.t("page.private.contract.termination.text3")} {this.props.canceledAt} {i18next.t("page.private.contract.termination.text4")}</>}
            </div>}
        </StyledTile>
    }
}

class ContractPage extends Component {
    state = {
        contract: null,
        serviceProviders: [],
        contractProvisionIntervals: null
    }

    constructor(props) {
        super(props)

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        fetch(scanmetrix.backendURL + "/contract-provision-intervals?contractId=" + this.props.match.params.contractid, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(data => {
            this.setState({ contractProvisionIntervals: data.contractProvisionIntervals })
        })

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    ServiceProviders {
                        nodes {
                            id
                            name
                            address {
                                streetName
                                streetNumber
                                additional
                                postalCode
                                city
                                country
                            }
                        }
                    }
                    Contract(id: $id) {
                        id
                        files {
                            id
                        }
                        branch {
                            id
                        }
                        team {
                            id
                            name
                        }
                        title
                        number
                        type
                        description
                        partnerName
                        cancellationPossiblePriorEnd
                        beginsAt
                        endsAt
                        perpetual
                        automaticRenewal
                        notes
                        signed
                        yearlyCost
                        yearlyCostIncrement
                        yearlyCostDate
                        contractDurationAmount
                        contractDurationType
                        cancelationDurationAmount
                        cancelationDurationType
                        cancelationType
                        renewalDurationAmount
                        renewalDurationType
                        canceledAt
                        contractDate
                        workSection {
                            id
                            name
                            icon
                            stlbId
                        }
                        accountingInvoices {
                            id
                            number
                            date
                            net
                            gross
                            notes
                            status
                            declined
                            accountingInvoiceDocument {
                                id
                                name
                            }
                            subsidiary {
                                id
                                name
                                label
                            }
                        }
                        provisionIntervals {
                            id
                            name
                            startingAt
                            intervalAmount
                            intervalType
                            cost
                            disabled
                            budgetCategory {
                                id
                            }
                            proofs {
                                id
                                priority
                                name
                                continueFromDate
                                continueInterval
                                date
                                tracing
                                subsidiary {
                                    id
                                    name
                                    label
                                }
                                contractProvisionInterval {
                                    id
                                    name
                                }
                            }
                        }
                        extensionOptions {
                            id
                            date
                            intervalAmount
                            intervalType
                            notes
                            deadline
                        }
                        subsidiaries {
                            id
                            costQuota
                            subsidiary {
                                id
                                name
                                label
                                legalName
                                address {
                                    postalCode
                                    city
                                    streetName
                                    streetNumber
                                }
                            }
                        }
                        serviceProvider {
                            id
                        }
                        address {
                            inline
                            streetName
                            streetNumber
                            additional
                            postalCode
                            city
                            country
                        }
                    }
                }
            `,
            variables: {
                id: this.props.match.params.contractid
            }
        }).then(data => {
            this.setState({ contract: data.data.Contract, serviceProviders: data.data.ServiceProviders.nodes })
        })
    }

    render() {
        if(!this.state.contract) return null
        const contract = this.state.contract

        const endsAt = contract.endsAt ? moment(contract.endsAt) : moment(contract.beginsAt).add(contract.contractDurationAmount, contract.contractDurationType)
        //onst cancelationDate = endsAt ? ((contract.cancelationDurationAmount && contract.cancelationDurationType) ? (endsAt.clone().subtract(contract.cancelationDurationAmount, contract.cancelationDurationType)) : null) : null


        let currentStart = contract.beginsAt ? moment(contract.beginsAt).clone() : null
        if(currentStart && !contract.perpetual && contract.automaticRenewal) {
            while(currentStart.clone().add(contract.contractDurationAmount, contract.contractDurationType).valueOf() <= moment().valueOf()) {
                currentStart = currentStart.clone().add(contract.contractDurationAmount, contract.contractDurationType)
            }
        }
        let currentEnd = currentStart ? (currentStart.valueOf() > moment(contract.beginsAt).valueOf() ? (currentStart.clone().add(contract.contractDurationAmount, contract.contractDurationType)) : (contract.endsAt ? moment(contract.endsAt) : currentStart.clone().add(contract.contractDurationAmount, contract.contractDurationType))) : (contract.endsAt ? moment(contract.endsAt) : null)

        const cancelationType = contract.cancelationType
        const tempStart = contract.beginsAt ? contract.beginsAt : (contract.endsAt ? moment(contract.endsAt).subtract(contract.contractDurationAmount, contract.contractDurationType).toDate() : (contract.contractDate)) || null

        let cancelationDue, actualCancelationDate = null

        if(contract.cancelationDurationAmount !== null && contract.cancelationDurationType) {
            if(contract.perpetual) {
                cancelationDue = moment()
                actualCancelationDate = moment().add(contract.cancelationDurationAmount, contract.cancelationDurationType)
            } else {
                if(cancelationType === "ongoing") {
                    cancelationDue = moment()
                    actualCancelationDate = moment().add(contract.cancelationDurationAmount, contract.cancelationDurationType)
                } else if(cancelationType === "monthEnd") {
                    actualCancelationDate = moment().add(contract.cancelationDurationAmount, contract.cancelationDurationType).endOf("month")
                    cancelationDue = actualCancelationDate.clone().subtract(contract.cancelationDurationAmount, contract.cancelationDurationType)
                } else if(cancelationType === "yearEnd") {
                    actualCancelationDate = moment().add(contract.cancelationDurationAmount, contract.cancelationDurationType).endOf("year")
                    cancelationDue = actualCancelationDate.clone().subtract(contract.cancelationDurationAmount, contract.cancelationDurationType)
                } else if(cancelationType === "quarterEnd") {
                    actualCancelationDate = moment().add(contract.cancelationDurationAmount, contract.cancelationDurationType).endOf("quarter")
                    cancelationDue = actualCancelationDate.clone().subtract(contract.cancelationDurationAmount, contract.cancelationDurationType)
                } else if(cancelationType === "contractEnd") {
                    if(contract.automaticRenewal) {
                        if(tempStart && !contract.canceledAt) {
                            let current = moment((contract.endsAt && moment().isBefore(moment(contract.endsAt))) ? contract.endsAt : (contract.endsAt || tempStart)).clone()

                            while(current.isSameOrBefore(moment())) {
                                current = contract.renewalDurationAmount ? current.add(contract.renewalDurationAmount, contract.renewalDurationType) : current.add(contract.contractDurationAmount, contract.contractDurationType)
                            }

                            if(contract.cancellationPossiblePriorEnd) {
                                cancelationDue = moment()
                                actualCancelationDate = cancelationDue.clone().add(contract.cancelationDurationAmount, contract.cancelationDurationType)
                            } else {
                                actualCancelationDate = endsAt
                                cancelationDue = actualCancelationDate.clone().subtract(contract.cancelationDurationAmount, contract.cancelationDurationType)

                                if(moment().isAfter(cancelationDue)) {
                                    actualCancelationDate = contract.renewalDurationAmount ? actualCancelationDate.add(contract.renewalDurationAmount, contract.renewalDurationType) : actualCancelationDate.add(contract.contractDurationAmount, contract.contractDurationType)
                                    cancelationDue = actualCancelationDate.clone().subtract(contract.cancelationDurationAmount, contract.cancelationDurationType)
                                }
                            }

                            currentEnd = current
                        }
                    } else {
                        if(contract.cancellationPossiblePriorEnd) {
                            cancelationDue = moment()
                            actualCancelationDate = cancelationDue.clone().add(contract.cancelationDurationAmount, contract.cancelationDurationType)
                        } else {
                            actualCancelationDate = moment(endsAt)
                            cancelationDue = actualCancelationDate.clone().subtract(contract.cancelationDurationAmount, contract.cancelationDurationType)
                        }
                    }
                }

                /*if(!contract.cancellationPossiblePriorEnd && currentEnd && cancelationDue) {
                    let value = Math.max(cancelationDue.valueOf(), currentEnd.valueOf())

                    actualCancelationDate = moment(value)
                    cancelationDue = actualCancelationDate.clone().subtract(contract.cancelationDurationAmount, contract.cancelationDurationType)
                }*/
            }
        }

        let renewalEnd = currentEnd

        return <>
            <UpdateContractModal instance={ref => this.updateContractModal = ref} refresh={() => this.fetch()} />
            <DeleteContractModal history={this.props.history} contractId={contract.id} instance={ref => this.deleteContractModal = ref} />
            <CancelContractModal currentEnd={currentEnd} contract={contract} instance={ref => this.cancelContractModal = ref} refresh={() => this.fetch()} />
            <CreateContractExtensionOptionModal instance={ref => this.createContractExtensionOptionModal = ref} contractId={contract.id} refresh={() => this.fetch()} />
            <UpdateContractExtensionOptionModal instance={ref => this.updateContractExtensionOptionModal = ref} refresh={() => this.fetch()} />
            <DeleteContractExtensionOptionModal instance={ref => this.deleteContractExtensionOptionModal = ref} refresh={() => this.fetch()} />
            <CreateSubsidiaryContractModal instance={ref => this.createSubsidiaryContractModal = ref} contractId={contract.id} refresh={() => this.fetch()} subsidiaryIds={contract.subsidiaries.map(s => s.subsidiary.id)} />
            <DeleteSubsidiaryContractModal instance={ref => this.deleteSubsidiaryContractModal = ref} refresh={() => this.fetch()} />
            <CreateContractProvisionIntervalModal instance={ref => this.createContractProvisionIntervalModal = ref} contractId={contract.id} refresh={() => this.fetch()} />
            <UpdateContractProvisionIntervalModal instance={ref => this.updateContractProvisionIntervalModal = ref} refresh={() => this.fetch()} contractId={contract.id} />
            <DeleteContractProvisionIntervalModal instance={ref => this.deleteContractProvisionIntervalModal = ref} refresh={() => this.fetch()} />
            <CreateContractProvisionProofModal openFiles={id => {
                console.log(id)
                this.openContractProvisionProofDocumentsModal.open(id)
            }} contractId={contract.id} instance={ref => this.createContractProvisionProofModal = ref} refresh={() => this.fetch()} />
            <DeleteContractProvisionProofModal instance={ref => this.deleteContractProvisionProofModal = ref} refresh={() => this.fetch()} />
            <UpdateContractProvisionProofModal instance={ref => this.updateContractProvisionProofModal = ref} refresh={() => this.fetch()} />
            <OpenContractProvisionProofDocumentsModal instance={ref => this.openContractProvisionProofDocumentsModal = ref} refresh={() => this.fetch()} />
            <UpdateSubsidiaryContractModal instance={ref => this.updateSubsidiaryContractModal = ref} refresh={() => this.fetch()} />
            <Breadcrumbs values={[
                {
                    icon: "file-contract",
                    title: i18next.t("page.private.contract.breadCrumbsTitle"),
                    link: "/contracts"
                },
                {
                    title: contract.title,
                    icon: "file"
                }
            ]} />
            <Section bodyPadding="32px 0 0 0">
                <Section.Item title={i18next.t("page.private.contract.generalInformation.sectionTitle")} icon="id-card">
                    <Tiles>
                        <StyledTile>
                            <div className="title">{i18next.t("page.private.contract.generalInformation.noticePeriod.text1")}</div>
                            {!cancelationDue && !actualCancelationDate && !contract.canceledAt && <div className="text">{i18next.t("page.private.contract.generalInformation.noticePeriod.text2")}</div>}
                            {(!contract.automaticRenewal && !contract.perpetual && moment().isSameOrAfter(cancelationDue)) && <div className="text">{i18next.t("page.private.contract.generalInformation.noticePeriod.text3")}</div>}
                            {!contract.perpetual && cancelationDue && !(!contract.automaticRenewal && moment().isSameOrAfter(cancelationDue)) && <div className="text">{i18next.t("page.private.contract.generalInformation.noticePeriod.text4")} {cancelationDue.format("DD.MM.YYYY") === moment().format("DD.MM.YYYY") ? "heute" : cancelationDue.fromNow()} {i18next.t("page.private.contract.generalInformation.noticePeriod.text5")} <b>{cancelationDue.format("DD.MM.YYYY")}</b></div>}
                            {actualCancelationDate && !(!contract.automaticRenewal && !contract.perpetual && moment().isSameOrAfter(cancelationDue)) && contract.canceledAt && <div className="text">{i18next.t("page.private.contract.generalInformation.noticePeriod.text6")} {actualCancelationDate.format("DD.MM.YYYY") === moment().format("DD.MM.YYYY") ? "heute" : actualCancelationDate.fromNow()} {i18next.t("page.private.contract.generalInformation.noticePeriod.text7")} <b>{actualCancelationDate.format("DD.MM.YYYY")}</b></div>}
                            {/*<div className="text">{this.props.value ? `${this.props.value.fromNow()} (${this.props.value.format("DD.MM.YYYY")})` : "Keine Angabe"}</div>
                            {this.props.value && !this.props.canceled && !this.props.expired && <div className="progressBar">
                                <div className="value" style={{ width: `${this.props.progress * 100}%` }} />
                            </div>}
                            {this.props.expired && !this.props.perpetual && !this.props.canceled && <div className="expired">
                                <i className="far fa-times-circle" /> Abgelaufen
                            </div>}*/}
                            {contract.canceledAt && <div className="canceled">
                                <i className="far fa-check-circle" /> {i18next.t("page.private.contract.generalInformation.noticePeriod.text8")} {moment(contract.canceledAt).format("DD.MM.YYYY")} {i18next.t("page.private.contract.generalInformation.noticePeriod.text9")}
                            </div>}
                        </StyledTile>
                        {/*<Tile perpetual={contract.perpetual} title="Kündigungsfrist" canceledAt={contract.canceledAt ? moment(contract.canceledAt).format("DD.MM.YYYY") : null} canceled={!!contract.canceledAt} progress={(cancelationDate && currentEnd) ? (1 - Math.min(1, ((cancelationDate.valueOf() - currentEnd.valueOf()) / (moment().valueOf() - currentEnd.valueOf())))) : null} value={contract.perpetual ? null : cancelationDue} exp={cancelationDate && (cancelationDate.valueOf() < moment(contract.canceledAt).valueOf())} expired={cancelationDate && (cancelationDate.valueOf() <= moment().valueOf())} />
                        */}<Tile perpetual={contract.perpetual} title={i18next.t("page.private.contract.generalInformation.noticePeriod.text10")} progress={(currentStart && currentEnd) ? ((1 - Math.min(1, ((moment().valueOf() - currentStart.valueOf()) / (currentEnd.valueOf() - currentStart.valueOf()))))) : null} value={contract.automaticRenewal ? (currentStart ? ((contract.perpetual || contract.canceledAt) ? null : renewalEnd) : null) : null} />
                        <Tile perpetual={contract.perpetual} title={i18next.t("page.private.contract.generalInformation.noticePeriod.text11")} progress={(currentStart && currentEnd) ? ((1 - Math.min(1, ((moment().valueOf() - currentStart.valueOf()) / (currentEnd.valueOf() - currentStart.valueOf()))))) : null} value={currentStart ? ((contract.perpetual || contract.automaticRenewal) ? null : currentEnd) : null} expired={currentStart && currentEnd && (currentEnd.valueOf() <= moment().valueOf())} />
                    </Tiles>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: 32 }}>
                        <GridElement>
                            <Grid gap="32px" columns={[ "1fr" ]}>
                                <GridElement styled title={i18next.t("page.private.contract.generalInformation.contractData.gridElementTitle")} icon="database" rightContent={<div style={{ display: "grid", gridGap: 16, gridTemplateColumns: "max-content max-content max-content" }}>
                                    <Button disabled={scanmetrix.checkPermission("ServiceProvider") < 1} secondary thick icon="user-tie" title={i18next.t("page.private.contract.generalInformation.contractData.serviceProvider")} onClick={() => window.open(`/serviceprovider/${contract.serviceProvider.id}`, "_blank")} />
                                    <Button disabled={scanmetrix.checkPermission("Contract") < 3} thick icon="pencil" title={i18next.t("page.private.contract.generalInformation.contractData.updateButton")} onClick={() => this.updateContractModal.open(contract)} />
                                    <Button disabled={scanmetrix.checkPermission("Contract") < 4} secondary thick icon="trash" title={i18next.t("page.private.contract.generalInformation.contractData.deleteButton")} onClick={() => this.deleteContractModal.open()} />
                                </div>}>
                                    <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr" ]}>
                                        <GridElement columnStart={1} columnEnd={4}>
                                            <Input adjustWidth label={i18next.t("page.private.contract.generalInformation.contractData.contractName")} icon="tag" value={contract.title} readOnly />
                                        </GridElement>
                                        <GridElement columnStart={1} columnEnd={3}>
                                            <Select readOnly value={contract.type} icon="pencil-alt" label={i18next.t("page.private.contract.generalInformation.contractData.contractType")} adjustWidth items={contractTypes.map(ct => ({ key: ct.key, title: i18next.t("page.private.dashboard.propertyManagement.contractType." + ct.key) }))} />
                                        </GridElement>
                                        <GridElement columnStart={3} columnEnd={4}>
                                            <Checkbox readOnly label={i18next.t("page.private.contract.generalInformation.contractData.contractSign")} value={contract.signed} />
                                        </GridElement>
                                        <GridElement columnStart={1} columnEnd={4}>
                                            <Input adjustWidth label={i18next.t("page.private.contract.generalInformation.contractData.contractNumbe")} icon="sort-numeric-up-alt" value={contract.number} readOnly />
                                        </GridElement>
                                        <GridElement columnStart={1} columnEnd={4}>
                                            <Input textArea adjustWidth label={i18next.t("page.private.contract.generalInformation.contractData.contractDescription")} icon="font" value={contract.description} readOnly />
                                        </GridElement>
                                    </Grid>
                                </GridElement>
                                <GridElement styled title={i18next.t("page.private.contract.generalInformation.classification.gridElementTitle")} icon="archive">
                                    <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr" ]}>
                                        <GridElement columnStart={1} columnEnd={3}>
                                            <Input adjustWidth label={i18next.t("page.private.contract.generalInformation.classification.trade")} icon="hard-hat" value={contract.workSection ? `${contract.workSection.stlbId ? (contract.workSection.stlbId + " / ") : ""}${contract.workSection.name}` : ""} readOnly />
                                        </GridElement>
                                        <GridElement columnStart={3} columnEnd={4}>
                                            <Input adjustWidth label={i18next.t("page.private.contract.generalInformation.classification.workGroup")} icon="users-crown" value={contract.team ? contract.team.name : ""} readOnly />
                                        </GridElement>
                                        <GridElement columnStart={1} columnEnd={4}>
                                            <Input textArea adjustWidth label={i18next.t("page.private.contract.generalInformation.classification.notes")} icon="sticky-note" value={contract.notes} readOnly />
                                        </GridElement>
                                    </Grid>
                                </GridElement>
                                <GridElement styled title={i18next.t("page.private.contract.generalInformation.contractCost.gridElementTitle")} icon="piggy-bank">
                                    <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr" ]}>
                                        <GridElement columnStart={1} columnEnd={2}>
                                            <Input readOnly adjustWidth float label={i18next.t("page.private.contract.generalInformation.contractCost.annualCostsNet")} value={contract.yearlyCost} format={val => val !== null ? formatter.format(val) : val} />
                                        </GridElement>
                                        <GridElement columnStart={2} columnEnd={3}>
                                            <Input readOnly adjustWidth float label={i18next.t("page.private.contract.generalInformation.contractCost.annualPricePercent")} icon="percent" value={contract.yearlyCostIncrement} format={val => val !== null ? `${val} %` : val} />
                                        </GridElement>
                                        <GridElement columnStart={3} columnEnd={4}>
                                            <Input readOnly adjustWidth date label={i18next.t("page.private.contract.generalInformation.contractCost.settingPrice")} icon="calendar-day" value={contract.yearlyCostDate ? moment(contract.yearlyCostDate).format("DD.MM.YYYY") : null} />
                                        </GridElement>
                                    </Grid>
                                </GridElement>
                            </Grid>
                        </GridElement>
                        <GridElement>
                            <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 32 }}>
                                <GridElement styled title={i18next.t("page.private.contract.generalInformation.contractTerm.gridElementTitle")} icon="hourglass">
                                    <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr" ]}>
                                        <GridElement columnStart={1} columnEnd={2}>
                                            <Input adjustWidth label={i18next.t("page.private.contract.generalInformation.contractTerm.contractStart")} icon="hourglass-start" value={contract.beginsAt ? moment(contract.beginsAt).format("DD.MM.YYYY") : null} readOnly />
                                        </GridElement>
                                        <GridElement columnStart={2} columnEnd={3}>
                                            <Input adjustWidth label={i18next.t("page.private.contract.generalInformation.contractTerm.contractEnd")} icon="hourglass-end" value={contract.endsAt ? moment(contract.endsAt).format("DD.MM.YYYY") : null} readOnly />
                                        </GridElement>
                                        <GridElement columnStart={3} columnEnd={4}>
                                            <Checkbox readOnly label={i18next.t("page.private.contract.generalInformation.contractTerm.unlimitedContract")} value={contract.perpetual} />
                                        </GridElement>
                                        <GridElement columnStart={1} columnEnd={2}>
                                            <Input adjustWidth label={i18next.t("page.private.contract.generalInformation.contractTerm.contractDate")} icon="calendar-day" value={moment(contract.contractDate).format("DD.MM.YYYY")} readOnly />
                                        </GridElement>
                                        <GridElement columnStart={2} columnEnd={3}>
                                            <Input adjustWidth label={i18next.t("page.private.contract.generalInformation.contractTerm.termNumber")} icon="tally" value={contract.contractDurationAmount} readOnly />
                                        </GridElement>
                                        <GridElement columnStart={3} columnEnd={4}>
                                            <Select readOnly value={contract.contractDurationType} icon="calendar-week" label={i18next.t("page.private.contract.generalInformation.contractTerm.termUnit")} adjustWidth items={[
                                                { key: "days", title: i18next.t("page.private.contract.generalInformation.contractTerm.termUnitTypes.days") },
                                                { key: "weeks", title: i18next.t("page.private.contract.generalInformation.contractTerm.termUnitTypes.weeks") },
                                                { key: "months", title: i18next.t("page.private.contract.generalInformation.contractTerm.termUnitTypes.months") },
                                                { key: "years", title: i18next.t("page.private.contract.generalInformation.contractTerm.termUnitTypes.years") }
                                            ]} />
                                        </GridElement>
                                    </Grid>
                                </GridElement>
                                <GridElement styled title={i18next.t("page.private.contract.generalInformation.termination.gridElementTitle")} icon="ban" rightContent={<Button disabled={scanmetrix.checkPermission("Contract") < 3} secondary thick icon="ban" title={contract.canceledAt ? i18next.t("page.private.contract.generalInformation.termination.revokeTerminationButton") : i18next.t("page.private.contract.generalInformation.termination.cancelContract")} onClick={() => this.cancelContractModal.open()} />}>
                                    <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr" ]}>
                                        <GridElement columnStart={1} columnEnd={2}>
                                            <Input adjustWidth label={i18next.t("page.private.contract.generalInformation.termination.noticePeriodNumber")} icon="tally" value={contract.cancelationDurationAmount} readOnly />
                                        </GridElement>
                                        <GridElement columnStart={2} columnEnd={3}>
                                            <Select upwards readOnly value={contract.cancelationDurationType} icon="calendar-week" label={i18next.t("page.private.contract.generalInformation.termination.noticePeriodUnit")} adjustWidth items={[
                                                { key: "days", title: i18next.t("page.private.contract.generalInformation.termination.noticePeriodUnitTypes.days") },
                                                { key: "weeks", title: i18next.t("page.private.contract.generalInformation.termination.noticePeriodUnitTypes.weeks") },
                                                { key: "months", title: i18next.t("page.private.contract.generalInformation.termination.noticePeriodUnitTypes.months") },
                                                { key: "years", title: i18next.t("page.private.contract.generalInformation.termination.noticePeriodUnitTypes.years") }
                                            ]} />
                                        </GridElement>
                                        <GridElement columnStart={3} columnEnd={4}>
                                            <Select upwards readOnly value={contract.cancelationType} icon="calendar" label={i18next.t("page.private.contract.generalInformation.termination.noticePeriodType")} adjustWidth items={[
                                                { key: "ongoing", title: i18next.t("page.private.contract.generalInformation.termination.noticePeriodTypeTypes.ongoing") },
                                                { key: "monthEnd", title: i18next.t("page.private.contract.generalInformation.termination.noticePeriodTypeTypes.monthEnd") },
                                                { key: "yearEnd", title: i18next.t("page.private.contract.generalInformation.termination.noticePeriodTypeTypes.yearEnd") },
                                                { key: "quarterEnd", title: i18next.t("page.private.contract.generalInformation.termination.noticePeriodTypeTypes.quarterEnd") },
                                                { key: "contractEnd", title: i18next.t("page.private.contract.generalInformation.termination.noticePeriodTypeTypes.contractEnd") }
                                            ]} />
                                        </GridElement>
                                        <GridElement columnStart={1} columnEnd={4}>
                                            <Checkbox readOnly label={i18next.t("page.private.contract.generalInformation.termination.contractEndCheckbox")} value={contract.cancellationPossiblePriorEnd} />
                                        </GridElement>
                                    </Grid>
                                </GridElement>

                                <GridElement styled title={i18next.t("page.private.contract.generalInformation.contractExtension.gridElementTitle")} icon="history">
                                    <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr", "1fr" ]}>
                                        <GridElement columnStart={1} columnEnd={3}>
                                            <Input adjustWidth label={i18next.t("page.private.contract.generalInformation.contractExtension.renewalPeriodNumber")} icon="tally" value={contract.renewalDurationAmount} readOnly />
                                        </GridElement>
                                        <GridElement columnStart={3} columnEnd={5}>
                                            <Select upwards readOnly value={contract.renewalDurationType} icon="calendar-week" label={i18next.t("page.private.contract.generalInformation.contractExtension.renewalPeriodUnit")} adjustWidth items={[
                                                { key: "days", title: i18next.t("page.private.contract.generalInformation.contractExtension.renewalPeriodUnitTypes.days") },
                                                { key: "weeks", title: i18next.t("page.private.contract.generalInformation.contractExtension.renewalPeriodUnitTypes.weeks") },
                                                { key: "months", title: i18next.t("page.private.contract.generalInformation.contractExtension.renewalPeriodUnitTypes.months") },
                                                { key: "years", title: i18next.t("page.private.contract.generalInformation.contractExtension.renewalPeriodUnitTypes.years") }
                                            ]} />
                                        </GridElement>
                                        <GridElement columnStart={1} columnEnd={4}>
                                            <Checkbox readOnly label={i18next.t("page.private.contract.generalInformation.contractExtension.automaticRenewal")} value={contract.automaticRenewal} />
                                        </GridElement>
                                    </Grid>
                                </GridElement>
                                <GridElement styled title={i18next.t("page.private.contract.generalInformation.contractPartner.gridElementTitle")} icon="user-tie">
                                    <Grid gap="32px" padding="32px" columns={[ "1fr", "1fr", "1fr", "1fr" ]}>
                                        <GridElement columnStart={1} columnEnd={3}>
                                            <Select upwards readOnly value={(contract.serviceProvider || {}).id || null} icon="user-tie" label={i18next.t("page.private.contract.generalInformation.contractPartner.serviceProvider")} adjustWidth items={this.state.serviceProviders.map(serviceProvider => ({ key: serviceProvider.id, title: serviceProvider.name, data: serviceProvider }))} />
                                        </GridElement>
                                        <GridElement columnStart={3} columnEnd={5}>
                                            <Input adjustWidth label={i18next.t("page.private.contract.generalInformation.contractPartner.contractPartner")} icon="user-secret" value={contract.partnerName} readOnly />
                                        </GridElement>
                                        <GridElement columnStart={1} columnEnd={5}>
                                            <Input adjustWidth label={i18next.t("page.private.contract.generalInformation.contractPartner.addressContractPartner")} icon="mailbox" value={contract.address.inline} readOnly />
                                        </GridElement>
                                    </Grid>
                                </GridElement>
                            </div>
                        </GridElement>
                    </div>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.contract.documents.sectionTitle")} icon="folder-tree" amount={contract.files.length}>
                    <FileManager contractId={contract.id} title={i18next.t("page.private.contract.documents.contractDocument")} icon="file-contract" scope="contract" onPathChange={contractPath => this.setState({ contractPath })} />
                </Section.Item>
                <Section.Item disabled={scanmetrix.checkPermission("ContractExtensionOption") === 0} title={i18next.t("page.private.contract.extensionOptions.sectionTitle")} icon="history" amount={contract.extensionOptions.length}>
                    <GridElement styled title={i18next.t("page.private.contract.extensionOptions.gridElementTitle")} icon="history" rightContent={<Button disabled={scanmetrix.checkPermission("ContractExtensionOption") < 2} thick title={i18next.t("page.private.contract.extensionOptions.button")} icon="history" onClick={() => this.createContractExtensionOptionModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.contract.extensionOptions.table.date"), width: "15%", column: "date" },
                                { title: i18next.t("page.private.contract.extensionOptions.table.deadline"), width: "15%", column: "deadline" },
                                { title: i18next.t("page.private.contract.extensionOptions.table.duration"), width: "15%", column: "duration" },
                                { title: i18next.t("page.private.contract.extensionOptions.table.notes"), width: "55%", column: "notes" },
                            ]}
                            contextItems={scanmetrix.checkPermission("ContractExtensionOption") === 4 ? [
                                {
                                    icon: "times",
                                    title: i18next.t("page.private.contract.extensionOptions.deleteButton"),
                                    onClick: data => {
                                        this.deleteContractExtensionOptionModal.open(data.id)
                                    }
                                }
                            ] : null}
                            onItemClick={item => this.updateContractExtensionOptionModal.open(item.extensionOption)}
                            items={contract.extensionOptions.map(extensionOption => {
                                return ({
                                    extensionOption,
                                    id: extensionOption.id,
                                    notes: extensionOption.notes,
                                    duration: `${extensionOption.intervalAmount} ${getIntervalType(extensionOption.intervalType)}`,
                                    deadline: extensionOption.deadline ? moment(extensionOption.deadline).format("DD.MM.YYYY") : null,
                                    date: moment(extensionOption.date).format("DD.MM.YYYY")
                                })
                            })}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item disabled={scanmetrix.checkPermission("SubsidiaryContract") === 0} title={i18next.t("page.private.contract.areaApplication.sectionTitle")} icon="map-marker" amount={contract.subsidiaries.length}>
                    <GridElement styled title={i18next.t("page.private.contract.areaApplication.gridElementTitle")} icon="map-marker" rightContent={<Button disabled={scanmetrix.checkPermission("SubsidiaryContract") < 2} thick title={i18next.t("page.private.contract.areaApplication.button")} icon="map-marker" onClick={() => this.createSubsidiaryContractModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.contract.areaApplication.table.name"), width: "15%", column: "name" },
                                { title: i18next.t("page.private.contract.areaApplication.table.label"), width: "10%", column: "label" },
                                { title: i18next.t("page.private.contract.areaApplication.table.legalName"), width: "20%", column: "legalName" },
                                { title: i18next.t("page.private.contract.areaApplication.table.street"), width: "20%", column: "street" },
                                { title: i18next.t("page.private.contract.areaApplication.table.postalCode"), width: "10%", column: "postalCode" },
                                { title: i18next.t("page.private.contract.areaApplication.table.city"), width: "15%", column: "city" },
                                { title: i18next.t("page.private.contract.areaApplication.table.costQuota"), width: "10%", column: "costQuota" }
                            ]}
                            onItemClick={item => this.updateSubsidiaryContractModal.open(item.raw)}
                            contextItems={scanmetrix.checkPermission("SubsidiaryContract") === 4 ? [
                                {
                                    icon: "link",
                                    title: i18next.t("page.private.contract.areaApplication.branchCallButton"),
                                    onClick: data => {
                                        this.props.history.push("/subsidiary/" + data.raw.subsidiary.id)
                                    }
                                },
                                {
                                    icon: "times",
                                    title: i18next.t("page.private.contract.areaApplication.deleteButton"),
                                    onClick: data => {
                                        this.deleteSubsidiaryContractModal.open(data.id)
                                    }
                                }
                            ] : null}
                            items={contract.subsidiaries.map(subsidiary => {
                                return ({
                                    id: subsidiary.id,
                                    label: subsidiary.subsidiary.label,
                                    name: subsidiary.subsidiary.name,
                                    legalName: subsidiary.subsidiary.legalName,
                                    street: `${subsidiary.subsidiary.address.streetName} ${subsidiary.subsidiary.address.streetNumber}`,
                                    postalCode: subsidiary.subsidiary.address.postalCode,
                                    city: subsidiary.subsidiary.address.city,
                                    costQuota: subsidiary.costQuota ? `${subsidiary.costQuota} %` : <><i className="far fa-times-circle" /> {i18next.t("page.private.contract.areaApplication.noShare")}</>,
                                    raw: subsidiary
                                })
                            })}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item disabled={scanmetrix.checkPermission("ContractProvisionInterval") === 0} title={i18next.t("page.private.contract.performanceCycle.sectionTitle")} icon="hourglass" amount={contract.provisionIntervals.length}>
                    <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 32 }}>
                        <GridElement styled title={i18next.t("page.private.contract.performanceCycle.gridElementTitle")} icon="hourglass" rightContent={<Button disabled={scanmetrix.checkPermission("ContractProvisionInterval") < 2} thick title={i18next.t("page.private.contract.performanceCycle.button")} icon="history" onClick={() => this.createContractProvisionIntervalModal.open()} />}>
                            <Table
                                head={[
                                    { title: i18next.t("page.private.contract.performanceCycle.table.disabled"), width: "10%", column: "disabled" },
                                    { title: i18next.t("page.private.contract.performanceCycle.table.name"), width: "50%", column: "name" },
                                    { title: i18next.t("page.private.contract.performanceCycle.table.startingAt"), width: "15%", column: "startingAt" },
                                    { title: i18next.t("page.private.contract.performanceCycle.table.cost"), width: "10%", column: "cost" },
                                    { title: i18next.t("page.private.contract.performanceCycle.table.duration"), width: "15%", column: "duration" }
                                ]}
                                contextItems={scanmetrix.checkPermission("ContractProvisionInterval") === 4 ? [
                                    {
                                        icon: "times",
                                        title: i18next.t("page.private.contract.performanceCycle.deleteButton"),
                                        onClick: data => {
                                            this.deleteContractProvisionIntervalModal.open(data.id)
                                        }
                                    }
                                ] : null}
                                onItemClick={item => this.updateContractProvisionIntervalModal.open(item.provisionInterval)}
                                items={contract.provisionIntervals.map(provisionInterval => {
                                    return ({
                                        provisionInterval,
                                        cost: provisionInterval.cost !== null ? formatter.format(provisionInterval.cost) : null,
                                        id: provisionInterval.id,
                                        name: provisionInterval.name,
                                        disabled: provisionInterval.disabled ? <><i className="far fa-check-circle" />{i18next.t("page.private.contract.performanceCycle.Yes")}</> : <><i className="far fa-times-circle" />{i18next.t("page.private.contract.performanceCycle.No")}</>,
                                        duration: `${provisionInterval.intervalAmount} ${getIntervalType(provisionInterval.intervalType)}`,
                                        startingAt: provisionInterval.startingAt ? moment(provisionInterval.startingAt).format("DD.MM.YYYY") : "Vertragsdatum"
                                    })
                                })}
                            />
                        </GridElement>
                        <GridElement styled title={i18next.t("page.private.contract.upcomingPerformanceCycle.gridElementTitle")} icon="history">
                            <Table
                                items={this.state.contractProvisionIntervals ? this.state.contractProvisionIntervals.map(cpi => ({
                                    id: cpi.id,
                                    due: <p style={moment(cpi.date, "DD.MM.YYYY").isSameOrBefore(moment()) ? ({ fontWeight: "bold", color: "rgb(231, 76, 60)" }) : ({})}>{`${cpi.date} (${moment(cpi.date, "DD.MM.YYYY").fromNow()})`}</p>,
                                    name: cpi.name,
                                    interval: `${cpi.intervalAmount} ${[{ key: "days", title: i18next.t("page.private.contract.upcomingPerformanceCycle.tableOne.days") },
                                        { key: "weeks", title: i18next.t("page.private.contract.upcomingPerformanceCycle.tableOne.weeks") },
                                        { key: "months", title: i18next.t("page.private.contract.upcomingPerformanceCycle.tableOne.months") },
                                        { key: "years", title: i18next.t("page.private.contract.upcomingPerformanceCycle.tableOne.years") }].find(d => d.key === cpi.intervalType).title}`,
                                    contract: cpi.contract.title,
                                    partner: cpi.contract.partner,
                                    lastContractProof: cpi.last,
                                    contractRaw: cpi.contract,
                                    subsidiary: cpi.subsidiary ? `${cpi.subsidiary.name}${cpi.subsidiary.label ? (" (" + cpi.subsidiary.label + ")") : ""}` : null,
                                    latestAppointment: cpi.latestAppointment ? <p style={moment(cpi.latestAppointment, "DD.MM.YYYY").isSameOrBefore(moment()) ? ({ fontWeight: "bold", color: "rgb(231, 76, 60)" }) : ({})}>{cpi.latestAppointment}</p> : null
                                })) : null}
                                head={[
                                    { title: i18next.t("page.private.contract.upcomingPerformanceCycle.tableTwo.branch"), width: "32.5%", column: "subsidiary" },
                                    { title: i18next.t("page.private.contract.upcomingPerformanceCycle.tableTwo.due"), width: "15%", column: "due" },
                                    { title: i18next.t("page.private.contract.upcomingPerformanceCycle.tableTwo.name"), width: "22.5%", column: "name" },
                                    { title: i18next.t("page.private.contract.upcomingPerformanceCycle.tableTwo.interval"), width: "10%", column: "interval" },
                                    { title: i18next.t("page.private.contract.upcomingPerformanceCycle.tableTwo.lastContractProof"), width: "10%", column: "lastContractProof" },
                                    { title: i18next.t("page.private.contract.upcomingPerformanceCycle.tableTwo.latestAppointment"), width: "10%", column: "latestAppointment" }
                                ]}
                            />
                        </GridElement>
                    </div>
                </Section.Item>
                {/*<ContractPriceAdjustments />*/}
                <Section.Item disabled={scanmetrix.checkPermission("ContractProvisionProof") === 0} title={i18next.t("page.private.contract.proofOfPerformance.sectionTitle")} icon="file-certificate" amount={contract.provisionIntervals.map(i => i.proofs.length).reduce((a, b) => a + b, 0)}>
                    <GridElement styled title={i18next.t("page.private.contract.proofOfPerformance.gridElementTitle")} icon="file-certificate" rightContent={<Button disabled={scanmetrix.checkPermission("ContractProvisionProof") < 2} thick title={i18next.t("page.private.contract.proofOfPerformance.button")} icon="file-certificate" onClick={() => this.createContractProvisionProofModal.open()} />}>
                        <Table
                            head={[
                                { title: i18next.t("page.private.contract.proofOfPerformance.table.date"), width: "10%", column: "date" },
                                { title: i18next.t("page.private.contract.proofOfPerformance.table.branch"), width: "17.5%", column: "subsidiary" },
                                { title: i18next.t("page.private.contract.proofOfPerformance.table.interval"), width: "10%", column: "interval" },
                                { title: i18next.t("page.private.contract.proofOfPerformance.table.name"), width: "20%", column: "name" },
                                { title: i18next.t("page.private.contract.proofOfPerformance.table.continueInterval"), width: "10%", column: "continueInterval" },
                                { title: i18next.t("page.private.contract.proofOfPerformance.table.continueFromDate"), width: "10%", column: "continueFromDate" },
                                { title: i18next.t("page.private.contract.proofOfPerformance.table.tracing"), width: "12.5%", column: "tracing" },
                                { title: i18next.t("page.private.contract.proofOfPerformance.table.files"), width: "10%", column: "files" }
                            ]}
                            contextItems={scanmetrix.checkPermission("ContractProvisionProof") === 4 ? [
                                {
                                    icon: "times",
                                    title: i18next.t("page.private.contract.proofOfPerformance.deleteButton"),
                                    onClick: data => {
                                        this.deleteContractProvisionProofModal.open(data.id)
                                    }
                                }
                            ] : null}
                            onItemClick={item => this.updateContractProvisionProofModal.open(item.provisionProof)}
                            items={contract.provisionIntervals.map(pi => pi.proofs).flat().sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()).map(provisionProof => {
                                return ({
                                    ...provisionProof,
                                    provisionProof,
                                    subsidiary: provisionProof.subsidiary ? `${provisionProof.subsidiary.name}${provisionProof.subsidiary.label ? (" (" + provisionProof.subsidiary.label + ")") : ""}` : null,
                                    interval: provisionProof.contractProvisionInterval.name,
                                    date: moment(provisionProof.date).format("DD.MM.YYYY"),
                                    continueInterval: provisionProof.continueInterval ? <><i className="far fa-check-circle" /> {i18next.t("page.private.contract.proofOfPerformance.continueIntervalJa")}</> : <><i className="far fa-times-circle" /> {i18next.t("page.private.contract.proofOfPerformance.continueIntervalNein")}</>,
                                    continueFromDate: provisionProof.continueFromDate ? <><i className="far fa-check-circle" /> {i18next.t("page.private.contract.proofOfPerformance.continueFromDateJa")}</> : <><i className="far fa-times-circle" /> {i18next.t("page.private.contract.proofOfPerformance.continueFromDateNein")}</>,
                                    tracing: provisionProof.tracing ? <><i className="far fa-check-circle" /> {i18next.t("page.private.contract.proofOfPerformance.tracingJa")} ({provisionProof.priority === "medium" ? "Mittel" : (provisionProof.priority === "high" ? i18next.t("page.private.contract.proofOfPerformance.high") : i18next.t("page.private.contract.proofOfPerformance.low"))})</> : <><i className="far fa-times-circle" /> {i18next.t("page.private.contract.proofOfPerformance.tracingNein")}</>,
                                    files: <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                        <Button noIconMargin icon="folder-tree" thick stopPropagation onClick={() => this.openContractProvisionProofDocumentsModal.open(provisionProof.id)} />
                                    </div>
                                })
                            })}
                        />
                    </GridElement>
                </Section.Item>
                <Section.Item disabled={scanmetrix.checkPermission("AccountingInvoice") === 0} title={i18next.t("page.private.contract.accountingInvoice.sectionTitle")} icon="coins" amount={contract.accountingInvoices.length}>
                    <GridElement styled title={i18next.t("page.private.contract.accountingInvoice.gridElementTitle")} icon="coins">
                        <Table
                            head={[
                                { title: i18next.t("page.private.accounting.archive.table.number"), width: "47.5%", column: "number" },
                                { title: i18next.t("page.private.accounting.archive.table.date"), width: "10%", column: "date" },
                                { title: i18next.t("page.private.accounting.archive.table.net"), width: "10%", column: "net" },
                                { title: i18next.t("page.private.accounting.archive.table.gross"), width: "10%", column: "gross" },
                                { title: i18next.t("page.private.accounting.archive.table.branch"), width: "22.5%", column: "subsidiary" },
                            ]}
                            onItemClick={item => window.open(scanmetrix.backendURL + "/accounting-invoice-document/" + item.data.accountingInvoiceDocument.id, "_blank")}
                            items={contract.accountingInvoices.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()).map(accountingInvoice => {
                                return ({
                                    ...accountingInvoice,
                                    data: accountingInvoice,
                                    net: formatter.format(accountingInvoice.net),
                                    gross: formatter.format(accountingInvoice.gross),
                                    date: moment(accountingInvoice.date).format("DD.MM.YYYY"),
                                    subsidiary: accountingInvoice.subsidiary ? (accountingInvoice.subsidiary.name + (accountingInvoice.subsidiary.label ? (" (" + accountingInvoice.subsidiary.label + ")") : "")) : null,
                                    number: <div style={{ paddingLeft: "16px" }}><Status status={accountingInvoice.declined ? false : true}><i className={`far fa-${accountingInvoice.declined ? "times" : "check"}`} /></Status>{accountingInvoice.number}</div>,
                                })
                            })}
                        />
                    </GridElement>
                </Section.Item>
            </Section>
        </>
    }
}


const Status = styled.div`
    position: absolute;
    left: -16px;
    width: 32px;
    height: 32px;
    background: ${props => props.yellow ? "#e67e22" : (!!props.status ? "#27ae60" : (props.status === false ? "#c0392b" : "#2c3e50"))};
    border-radius: 32px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(50% - 16px);
    font-size: 0.9em;
    box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.3);

    i {
        margin: 0 !important;
    }
`

export default class extends Component {
    render() {
        return <Page {...this.props}>
            <ContractPage {...this.props} />
        </Page>
    }
}
