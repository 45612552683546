import React, { Component } from "react"
import { Modal, Button } from "scanmetrix-components"

export default class extends Component {
    state = { measureApprovalId: null, loading: false, error: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={measureApprovalId => this.setState({ measureApprovalId, error: false, loading: false })}>
            <Modal.Head title="Freigabeanfrage stornieren" icon="ban" />
            <Modal.Body padding={24}>
                Wenn Sie fortfahren, erhalten alle Empfänger der Unterschriftsmappe eine E-Mail, dass der Freigabeprozess abgebrochen wurde.
                {this.state.error && <Modal.Error>Die Unterschriftsmappe konnte nicht storniert werden. Möglicherweise wurde die Mappe bereits storniert oder abgeschlossen.</Modal.Error>}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick red title="Freigabeanfrage stornieren" icon="ban" onClick={async () => {
                this.setState({ loading: true, error: false })

                const result = await fetch(`${scanmetrix.nestURL}/v2/measure-approval/${this.state.measureApprovalId}/cancel`, {
                    method: "POST",
                    credentials: "include"
                })

                if(result.status === 201) {
                    this.props.refresh().then(() => instance.close())
                } else this.setState({ loading: false, error: true })
            }} />} />
        </Modal>
    }
}
