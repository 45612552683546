import React, { Component } from "react"
import { Modal, Input, Table, Select, Button } from "scanmetrix-components"

export default class extends Component {
    state = {}

    render() {
        return <Modal minWidth="768px" instance={this.props.instance} initialize={budgetTemplate => this.setState({ ...budgetTemplate, createError: false, loading: false, budgetCategoryId: null })}>
            <Modal.Head title="Budget Vorlage aktualisieren" icon="filter-circle-dollar" />
            {this.state.id && <Modal.Body padding={24}>
                <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 16 }}>
                    <Modal.Divider title="Vorlageneinstellungen" />
                    <Input.PostProvider value={{
                        url: `/v2/budget-template/${this.state.id}`,
                        data: this.state,
                        onSave: () => this.props.refresh()
                    }}>

                        <Input label="Name" icon="tag" field="name" adjustWidth/>
                    </Input.PostProvider>
                    <Modal.Divider title="Kategorien" />
                    <div style={{ display: "flex", alignItems: "center", gap: 16, width: "100%" }}>
                        <div style={{ width: "100%" }}>
                            <Select
                                label="Budget Kategorie"
                                refresh={refresh => this.updateBudgetCategorySelect = refresh}
                                readOnly={this.state.loading}
                                adjustWidth
                                icon="piggy-bank"
                                value={this.state.budgetCategoryId}
                                onChange={budgetCategoryId => this.setState({ budgetCategoryId, createError: false })}
                                fetch={{
                                    url: "/v2/budget-category",
                                    queryParams: {
                                        notAssignedToBudgetTemplateId: this.state.id
                                    },
                                    map: dataSet => ({ key: dataSet.id, title: `${dataSet.expenseType?.type || "Sonstige"} / ${dataSet.expenseType?.name || "Sonstige"} / ${dataSet.name}` })
                                }}
                            />
                        </div>
                        <Button
                            title="Budget Kategorie hinzufügen"
                            disabled={!this.state.budgetCategoryId}
                            thick
                            icon="piggy-bank"
                            loading={this.state.loading}
                            onClick={() => {
                                this.setState({ loading: true, createError: false })

                                fetch(`${scanmetrix.nestURL}/v2/budget-template-category`, {
                                    method: "POST",
                                    credentials: "include",
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify({
                                        budget_template_id: this.state.id,
                                        budget_category_id: this.state.budgetCategoryId
                                    })
                                }).then(result => {
                                    if(result.status === 201) {
                                        this.refreshBudgetTemplateCategories()
                                        this.updateBudgetCategorySelect()
                                        this.setState({ loading: false, budgetCategoryId: null })
                                    } else {
                                        this.setState({ loading: false, createError: true })
                                    }
                                }).catch(() => this.setState({ loading: false, createError: true }))
                            }}
                        />
                    </div>
                    {this.state.createError && <Modal.Error>Möglicherweise ist diese Budget Kategorie der Vorlage bereits zugeordnet.</Modal.Error>}
                    <Table
                        controlsPadding="32px 0 0 0"
                        refresh={refresh => this.refreshBudgetTemplateCategories = refresh}
                        minimal
                        menuName="budgetTemplateCategories"
                        head={[
                            {title: "Nummer", width: "10%", column: "number", orderField: "budgetCategory.number"},
                            {title: "Name", width: "25%", column: "name", orderField: "budgetCategory.name"},
                            {title: "Farbe", width: "15%", column: "colorFormatted", orderField: "budgetCategory.color"},
                            {title: "Ausgabeart", width: "25%", column: "expenseTypeFormatted", orderField: "budgetCategory.expenseType.name"},
                            {title: "Typ", width: "25%", column: "typeFormatted", orderField: "budgetCategory.expenseType.type"},
                        ]}
                        contextItems={[
                            {
                                title: "Kategorie entfernen",
                                icon: "trash",
                                onClick: item => {
                                    fetch(`${scanmetrix.nestURL}/v2/budget-template-category/${item.id}`, {
                                        method: "DELETE",
                                        credentials: "include",
                                        headers: {
                                            "Content-Type": "application/json"
                                        }
                                    })
                                    .then(() => {
                                        this.refreshBudgetTemplateCategories()
                                        this.updateBudgetCategorySelect()
                                    })
                                    .catch(() => this.refreshBudgetTemplateCategories())
                                }
                            }
                        ]}
                        metaCallback={this.props.metaCallback}
                        query={`${scanmetrix.nestURL}/v2/budget-template-category/${this.state.id}`}
                        defaultSort="budgetCategory.name_ASC"
                        map={budgetTemplateCategories => budgetTemplateCategories.map(budgetTemplateCategory => ({
                            ...budgetTemplateCategory,
                            name: budgetTemplateCategory.budgetCategory.name,
                            number: budgetTemplateCategory.budgetCategory.number,
                            typeFormatted: budgetTemplateCategory.budgetCategory.expenseType ? (budgetTemplateCategory.budgetCategory.expenseType.type === "CAPEX" ? <><i className="fa-duotone fa-money-bills" style={{ color: "#3b97d3" }} /> CapEx - Capital Expenditures</> : <><i className="fa-duotone fa-coin" style={{ color: "#3b97d3" }} /> OpEx - Operational Expenditures</>) : <><i className="fa-duotone fa-cog" style={{ color: "#3b97d3" }} />Sonstige</>,
                            colorFormatted: <div style={{ display: "flex", alignItems: "center" }}><div style={{ height: 16, width: 16, borderRadius: "100%", backgroundColor: budgetTemplateCategory.budgetCategory.color, marginRight: 8 }} />{budgetTemplateCategory.budgetCategory.color}</div>,
                            expenseTypeFormatted: budgetTemplateCategory.budgetCategory.expenseType?.name || <><i className="fa-duotone fa-times" style={{ color: "#3b97d3" }} />Keine</>,
                        }))}
                    />
                </div>
            </Modal.Body>}
        </Modal>
    }
}
