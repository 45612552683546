import React, { Component } from 'react'
import { Button, Modal, Input } from 'scanmetrix-components'

export default class extends Component {
    state = { name: null, loading: false }

    render() {
        return <Modal minWidth="768px" instance={this.props.instance} initialize={() => this.setState({ name: null, loading: false })}>
            <Modal.Head title="Budget Vorlage anlegen" icon="filter-circle-dollar" />
            <Modal.Body padding={24}>
                <Input label="Name" icon="tag" value={this.state.name} required onChange={name => this.setState({ name })} adjustWidth/>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading}
                                                       disabled={!this.state.name} thick
                                                       title="Budget Vorlage anlegen" icon="filter-circle-dollar" onClick={() => {
                this.setState({ loading: true })

                fetch(`${scanmetrix.nestURL}/v2/budget-template`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        name: this.state.name
                    })
                }).then(result => {
                    if(result.status === 201) {
                        this.props.refresh().then(() => {
                            instance.close()
                        })
                    } else {
                        this.setState({ loading: false })
                    }
                }).catch(() => this.setState({ loading: false }))
            }} />} />
        </Modal>
    }
}
