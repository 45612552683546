import React, { Component } from "react"
import CreateBudgetCategoryModal from "./modals/CreateBudgetCategoryModal"
import DeleteBudgetCategoryModal from "./modals/DeleteBudgetCategoryModal"
import UpdateBudgetCategoryModal from "./modals/UpdateBudgetCategoryModal"
import { GridElement, Table, Button } from "scanmetrix-components"

export default class extends Component {
    render() {
        return <>
            <CreateBudgetCategoryModal instance={ref => this.createBudgetCategoryModal = ref} refresh={() => this.refreshBudgetCategories()} />
            <DeleteBudgetCategoryModal instance={ref => this.deleteBudgetCategoryModal = ref} refresh={() => this.refreshBudgetCategories()} />
            <UpdateBudgetCategoryModal instance={ref => this.updateBudgetCategoryModal = ref} refresh={() => this.refreshBudgetCategories()} />
            <GridElement styled title="Budget Kategorien" icon="piggy-bank" rightContent={<Button icon="piggy-bank" title="Budget Kategorie anlegen" thick primary onClick={() => this.createBudgetCategoryModal.open()} />}>
                <Table
                    refresh={refresh => this.refreshBudgetCategories = refresh}
                    head={[
                        { title: "Nummer", width: "7.5%", column: "number", orderField: "number" },
                        { title: "Name", width: "17.5%", column: "name", orderField: "name" },
                        { title: "Farbe", width: "15%", column: "colorFormatted", orderField: "color" },
                        { title: "Ausgabeart", width: "20%", column: "expenseType", orderField: "expenseType.name" },
                        { title: "Ausgabetyp", width: "20%", column: "typeFormatted", orderField: "expenseType.type" },
                        { title: "Bearbeitet", width: "10%", column: "updated_at", orderField: "updated_at" },
                        { title: "Erstellt", width: "10%", column: "created_at", orderField: "created_at" },
                    ]}
                    contextItems={[
                        { title: "Budget Kategorie löschen", icon: "trash", onClick: item => this.deleteBudgetCategoryModal.open(item.id)}
                    ]}
                    metaCallback={this.props.metaCallback}
                    query={`${scanmetrix.nestURL}/v2/budget-category`}
                    defaultSort="created_at_DESC"
                    onItemClick={budgetCategory => this.updateBudgetCategoryModal.open(budgetCategory)}
                    map={budgetCategories => budgetCategories.map(budgetCategory => ({
                        ...budgetCategory,
                        expense_type_id: budgetCategory.expenseType?.id || null,
                        typeFormatted: budgetCategory.expenseType ? (budgetCategory.expenseType.type === "CAPEX" ? <><i className="fa-duotone fa-money-bills" style={{ color: "#3b97d3" }} /> CapEx - Capital Expenditures</> : <><i className="fa-duotone fa-coin" style={{ color: "#3b97d3" }} /> OpEx - Operational Expenditures</>) : <><i className="fa-duotone fa-cog" style={{ color: "#3b97d3" }} />Sonstige</>,
                        colorFormatted: <div style={{ display: "flex", alignItems: "center" }}><div style={{ height: 16, width: 16, borderRadius: "100%", backgroundColor: budgetCategory.color, marginRight: 8 }} />{budgetCategory.color}</div>,
                        expenseType: budgetCategory.expenseType?.name || <><i className="fa-duotone fa-times" style={{ color: "#3b97d3" }} />Keine</>,
                        updated_at: moment(budgetCategory.updated_at).fromNow(),
                        created_at: moment(budgetCategory.created_at).fromNow(),
                    }))}
                />
            </GridElement>
        </>
    }
}
