import React, { Component } from "react"
import {ScaleLoader} from "react-spinners";
import styled from "styled-components"
import { Input } from "scanmetrix-components"

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2
})

export default class extends Component {
    state = { loading: true, schema: null, values: {} }

    constructor(props) {
        super(props);

        this.fetchSchema = this.fetchSchema.bind(this)
        this.fetchValues = this.fetchValues.bind(this)

        Promise.all([
            this.fetchSchema(),
            this.fetchValues()
        ]).then(() => this.setState({ loading: false }))
    }

    async fetchValues() {
        const result = await fetch(`${scanmetrix.nestURL}/v2/budget-pool/values/${this.props.data.id}`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json())

        let values = {}

        result.forEach(value => {
            if(!values[String(value.budget_category_id)]) values[String(value.budget_category_id)] = {}

            values[String(value.budget_category_id)][String(value.index)] = value.value
        })

        this.setState({ values })
    }

    async fetchSchema() {
        const doCategoriesBatch = async (page = 1, array = []) => {
            const result = await fetch(`${scanmetrix.nestURL}/v2/budget-template-category/${this.props.data.budgetTemplate.id}?perPage=100&page=${page}`, {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(result => result.json())

            array = [ ...array, ...result.data.map(res => res.budgetCategory) ]

            if(result.meta.next) {
                array = await doCategoriesBatch(result.meta.next, array)
            }

            return array
        }

        const categories = await doCategoriesBatch()

        const expenseTypes = {}

        categories.forEach(category => {
            const expenseType = category.expenseType
            let arrayItem = null

            if(!expenseType) {
                if(!expenseTypes["none"]) expenseTypes["none"] = {}
                arrayItem = expenseTypes["none"]
            } else {
                if(!expenseTypes[expenseType.id]) expenseTypes[expenseType.id] = expenseType
                arrayItem = expenseTypes[expenseType.id]
            }

            if(!arrayItem.categories) arrayItem.categories = {}

            delete category.expenseType

            arrayItem.categories[String(category.id)] = category
        })

        const schema = { CAPEX: {}, OPEX: {}, NONE: {} }

        Object.keys(expenseTypes).forEach(expenseTypeId => {
            const expenseType = expenseTypes[String(expenseTypeId)]

            if(expenseTypeId !== "none") {
                schema[expenseType.type][String(expenseTypeId)] = expenseType
            } else {
                schema["NONE"]["NONE"] = expenseType
            }
        })

        this.setState({ schema })
    }

    render() {
        if(this.state.loading) return <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            fontSize: "24px",
            lineHeight: "50px",
            height: "400px"
        }}>
            <div>
                <ScaleLoader color="#3b97d3" height={20}/>
            </div>
            Lade Budget...
        </div>

        const data = this.props.data
        const phasing = data.phasing
        const divider = phasing === "MONTHLY" ? 1 : (phasing === "QUARTERLY" ? 3 : 12)
        const monthsBetween = Math.max(1, Math.ceil(Math.abs(moment(data.begin).diff(data.end, "months", true))))
        const dates = []

        for(let i = 0; i < monthsBetween; i += divider) {
            const date = moment(data.begin).add(i, "months")

            if(phasing === "MONTHLY") dates.push(date.format("MMM 'YY"))
            else if(phasing === "QUARTERLY") dates.push(`Q${date.quarter()} '${date.format("YY")}`)
            else if(phasing === "YEARLY") dates.push(date.format("YYYY"))
        }

        return <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 24 }}>
            <Legend>
                <div><div style={{ backgroundColor: "#3b97d3" }} />Ist-Kosten</div>
                <div><div style={{ backgroundColor: "#20242b" }} />Bestellobligo</div>
                <div><div style={{ backgroundColor: "#e67e22" }} />Verbleibende Soll-Kosten</div>
                <div><div style={{ backgroundColor: "#cccccc" }} />Verbleibendes Budget</div>
            </Legend>
            {Object.keys(this.state.schema).map(expenseTypeType => {
                return <ExpenseTypeType type={expenseTypeType}>
                    {Object.keys(this.state.schema[String(expenseTypeType)]).sort((a, b) => (this.state.schema[String(expenseTypeType)][String(a)].number || "") - (this.state.schema[String(expenseTypeType)][String(b)].number || "")).map(expenseTypeId => {
                        return <ExpenseType expenseType={this.state.schema[String(expenseTypeType)][String(expenseTypeId)]}>
                            {Object.keys(this.state.schema[String(expenseTypeType)][String(expenseTypeId)].categories).sort((a, b) => (this.state.schema[String(expenseTypeType)][String(expenseTypeId)].categories[String(a)].number || "") - (this.state.schema[String(expenseTypeType)][String(expenseTypeId)].categories[String(b)].number || "")).map(budgetCategoryId => {
                                return <BudgetCategory calculations={this.props.calculations.filter(calculation => String(calculation.budget_category_id) === String(budgetCategoryId))} valueMapCallback={valueMap => {
                                    const values = this.state.values
                                    values[String(budgetCategoryId)] = valueMap
                                    this.setState({ values })
                                }} valueMap={this.state.values[String(budgetCategoryId)] || {}} budgetPoolId={data.id} dates={dates} budgetCategory={this.state.schema[String(expenseTypeType)][String(expenseTypeId)].categories[String(budgetCategoryId)]} />
                            })}
                        </ExpenseType>
                    })}
                </ExpenseTypeType>
            })}
        </div>
    }
}

class ExpenseTypeType extends Component {
    state = { open: true }

    render() {
        return <StyledExpenseTypeType>
            <div className="header" onClick={() => this.setState({ open: !this.state.open })}>
                <div>
                    {this.props.type === "CAPEX" ? <><i className="fa-duotone fa-money-bills" style={{ color: "#3b97d3" }} /> CapEx - Capital Expenditures</> : (this.props.type === "OPEX" ? <><i className="fa-duotone fa-coin" style={{ color: "#3b97d3" }} /> OpEx - Operational Expenditures</> : <><i className="fa-duotone fa-cog" style={{ color: "#3b97d3" }} /> Sonstige Kosten</>)}
                </div>
                <div>
                    {this.state.open && <i className="fas fa-chevron-down" />}
                    {!this.state.open && <i className="fas fa-chevron-up" />}
                </div>
            </div>
            {this.state.open && <div className="children">
                {this.props.children}
            </div>}
        </StyledExpenseTypeType>
    }
}

class ExpenseType extends Component {
    render() {
        return <StyledExpenseType>
            {this.props.expenseType?.name && <div className="header">
                {this.props.expenseType.number && <p>{this.props.expenseType.number}</p>}{this.props.expenseType.name}
            </div>}
            <div className="children">
                {this.props.children}
            </div>
        </StyledExpenseType>
    }
}

class BudgetCategory extends Component {
    render() {
        const valueMap = this.props.valueMap
        const calculations = this.props.calculations
        const used = calculations.map(calc => calc.actualCosts + calc.presumedCosts + calc.obligo).reduce((a, b) => a + b, 0)
        const allSum = Math.max(Object.values(valueMap).filter(val => val !== null).reduce((a, b) => a + b, 0), used)
        const allObligo = calculations.map(calc => calc.obligo).reduce((a, b) => a + b, 0)
        const allActualCosts = calculations.map(calc => calc.actualCosts).reduce((a, b) => a + b, 0)
        const allPresumedCosts = calculations.map(calc => calc.presumedCosts).reduce((a, b) => a + b, 0)
        const left = Object.values(valueMap).filter(val => val !== null).reduce((a, b) => a + b, 0) - used

        return <StyledBudgetCategory>
            <div className="header">
                <div>{this.props.budgetCategory.number && <p>{this.props.budgetCategory.number}</p>}<div className="color" style={{ backgroundColor: this.props.budgetCategory.color }} /> {this.props.budgetCategory.name}</div>
                <div><b style={{ color: left > 0 ? "#1abc9c" : (left < 0 ? "#e74c3c" : "#000000") }}>{formatter.format(left)}</b> / {formatter.format(Object.values(valueMap).filter(val => val !== null).reduce((a, b) => a + b, 0))}</div>
                <div>{formatter.format(used)} verwendet</div>
                <div className="progress">
                    <div className="usedBar" style={{ width: Math.min(100, ((allActualCosts / allSum) * 100)) + "%" }} />
                    <div className="obligoBar" style={{ width: Math.min(100, ((allObligo / allSum) * 100)) + "%" }} />
                    <div className="plannedBar" style={{ width: Math.min(100, ((allPresumedCosts / allSum) * 100)) + "%" }} />
                </div>
            </div>
            <div className="fields">
                {this.props.dates.map((date, index) => {
                    const totalSum = Math.max(1, Math.max((valueMap[String(index)] >= 0 ? valueMap[String(index)] : 0), calculations.filter(calculation => calculation.index === index).map(calculation => calculation.obligo + calculation.presumedCosts + calculation.actualCosts).reduce((a, b) => a + b, 0)))
                    const calculation = calculations.find(cal => cal.index === index)
                    
                    if(!calculation) return null

                    const used = calculations.filter(calculation => calculation.index === index).map(calculation => calculation.obligo + calculation.presumedCosts + calculation.actualCosts).reduce((a, b) => a + b, 0)
                    const left = (valueMap[String(index)] >= 0 ? valueMap[String(index)] : 0) - used

                    return <div>
                        <Input adjustWidth key={index} label={date} onBlur={value => {
                            fetch(`${scanmetrix.nestURL}/v2/budget-pool/value/${this.props.budgetPoolId}/${this.props.budgetCategory.id}`, {
                                method: "POST",
                                credentials: "include",
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    value,
                                    index
                                })
                            }).then(result => result.json())
                        }} value={valueMap[String(index)] || null} thin float onChange={value => {
                            valueMap[String(index)] = value
                            this.props.valueMapCallback(valueMap)
                        }} format={value => value === null ? null : formatter.format(value)} />
                        <div className="used">{formatter.format(used)} / <b style={{ color: left > 0 ? "#1abc9c" : (left < 0 ? "#e74c3c" : "#000000") }}>{formatter.format(left)}</b></div>
                        <div className="singleProgress">
                            <div className="usedBar"
                                 style={{width: Math.min(100, ((calculation.actualCosts / totalSum) * 100)) + "%"}}/>
                            <div className="obligoBar"
                                 style={{width: Math.min(100, ((calculation.obligo / totalSum) * 100)) + "%"}}/>
                            <div className="plannedBar"
                                 style={{width: Math.min(100, ((calculation.presumedCosts / totalSum) * 100)) + "%"}}/>
                        </div>
                    </div>
                })}
            </div>
        </StyledBudgetCategory>
    }
}

const StyledExpenseTypeType = styled.div`
    width: 100%;

    > .header {
        background: white;
        user-select: none;
        height: 48px;
        width: 100%;
        justify-content: space-between;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0 24px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 6px -4px;
        cursor: pointer;
        transition: opacity 0.3s;
        
        &:hover {
            opacity: 0.75;
        }
        
        > div:first-child i {
            width: 32px;
        }
    }
    
    > .children {
        box-sizing: border-box;
        padding: 0 24px;
    }
`

const StyledExpenseType = styled.div`
    box-sizing: border-box;
    padding: 24px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 24px;
    
    > .header {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        
        > p {
            background: #20242b;
            color: white;
            padding: 6px 8px;
            margin-right: 8px;
            border-radius: 5px;
        }
    }
    
    > .children {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`

const StyledBudgetCategory = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    padding: 16px;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 6px -4px;
    
    > .header {
        width: 400px;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 90px;
        word-break: keep-all;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        padding-right: 24px;
        box-sizing: border-box;
        
        .color {
            width: 12px;
            height: 12px;
            border-radius: 100%;
            margin-right: 8px;
        }
        
        > .progress {
            width: 100%;
            background-color: rgba(0, 0, 0, 0.2);
            height: 4px;
            box-sizing: border-box;
            display: flex;
            
            > .usedBar {
                background: #3b97d3;
                height: 100%;
            }
            
            > .obligoBar {
                background: #20242b;
                height: 100%;
            }
            
            > .plannedBar {
                background: #e67e22;
                height: 100%;
            }
        }

        > div:first-child {
            display: flex;
            align-items: center;
            
            > p {
                color: #20242b;
                margin-right: 8px;
                font-weight: bold;
            }
        }
        
        > div:nth-child(2) {
            font-weight: bold;
        }

        > div:nth-child(3) {
            font-size: 12px;
        }
    }
    
    > .fields {
        width: 100%;
        display: grid;
        grid-gap: 8px;
        grid-auto-flow: column;
        box-sizing: border-box;
        grid-auto-columns: minmax(0, 1fr);
        padding-left: 24px;

        .used {
            margin-top: 4px;
            font-size: 11px;
            text-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            
            b {
                font-weight: bold;
            }
        }
        
        .singleProgress {
            width: 100%;
            height: 4px;
            margin-top: 4px;
            background-color:  rgba(0, 0, 0, 0.2);
            display: flex;

            .usedBar {
                height: 100%;
                background: #3b97d3;
            }
            
            .obligoBar {
                height: 100%;
                background: #20242b;
            }
            
            .plannedBar {
                height: 100%;
                background: #e67e22;
            }
        }
        
        > .div {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }
`

const Legend = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
    
    > div {
        display: flex;
        align-items: center;
        
        > div {
            width: 12px;
            border-radius: 100%;
            height: 12px;
            margin-right: 8px;
        }
    }
`
