import React, { Component } from "react"
import { Modal, Input, Select } from "scanmetrix-components"
import {CirclePicker} from "react-color";
import styled from "styled-components"

const CircleDesign = styled.div`
    transform: translateX(7px);
`

export default class extends Component {
    state = {}

    render() {
        return <Modal minWidth="400px" instance={this.props.instance} initialize={budgetCategory => this.setState(budgetCategory)}>
            <Modal.Head title="Budget Kategorie aktualisieren" icon="piggy-bank" />
            <Modal.Body padding={24} adjustHeight="364px">
                <Input.PostProvider value={{
                    url: `/v2/budget-category/${this.state.id}`,
                    data: this.state,
                    onSave: () => this.props.refresh()
                }}>
                    <div style={{
                        padding: "16px",
                        border: `3px dashed ${this.state.color || "#cccccc"}`,
                        display: "grid",
                        gridGap: "32px",
                        gridTemplateColumns: "1fr",
                        width: "380px"
                    }}>
                        <Select
                            label="Ausgabeart"
                            adjustWidth
                            field="expense_type_id"
                            icon="wallet"
                            fetch={{
                                url: "/v2/expense-type",
                                map: dataSet => ({ key: dataSet.id, title: `${dataSet.type} / ${dataSet.name}` })
                            }}
                        />
                        <Input label="Name" required icon="tag" field="name" adjustWidth/>
                        <Input label="Nummer" icon="table" field="number" adjustWidth/>
                        <CircleDesign>
                            <CirclePicker onChange={color => {
                                this.setState({color: color.hex})

                                fetch(`${scanmetrix.nestURL}/v2/budget-category/${this.state.id}`, {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                    credentials: "include",
                                    body: JSON.stringify({
                                        color: color.hex
                                    })
                                }).then(() => this.props.refresh())
                            }} width="100%"/>
                        </CircleDesign>
                    </div>
                </Input.PostProvider>
            </Modal.Body>
        </Modal>
    }
}
