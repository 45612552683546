import React, { Component } from "react"

import Header from "./styled/Header"
import Tabs from "./styled/Tabs"
import Parent from "./styled/Parent"
import Body from "./styled/Body"
import Item from "./styled/Item"

class Section extends Component {
    state = { selected: 0 }

    constructor(props) {
        super(props)

        if(props.selected !== undefined) this.state.selected = props.selected
        if(props.initialSelected !== undefined) this.state.selected = props.initialSelected
    }

    componentWillReceiveProps(props) {
        let newState = {}

        if(this.state.selected > [].concat(props.children).length - 1) {
            if(props.onSelected) {
                props.onSelected([].concat(props.children).length - 1)
            } else newState = { selected: [].concat(props.children).length - 1 }
        }

        if(props.selected !== undefined) newState.selected = props.selected

        this.setState(newState)
    }

    render() {
        return <Parent className={this.props.className} margin={this.props.bodyMargin} responsiveZoom={this.props.responsiveZoom}>
            <Header>
                <Tabs>
                    {[].concat(this.props.children).map((parentChild, index) => {
                        let child = React.cloneElement(parentChild, { key: index, index, switch: this.props.onSwitch || null, childrenTotal: this.props.children.length, selected: index === this.state.selected, onContextMenu: e => this.props.onContextMenu ? this.props.onContextMenu(e, index) : null, select: () => {
                                if(this.props.onSelected) this.props.onSelected(index)
                                else this.setState({ selected: index })
                            } })

                        return child
                    })}
                </Tabs>
            </Header>
            <Body padding={this.props.bodyPadding} margin={this.props.bodyMargin}>
                {[].concat(this.props.children).map((parentChild, index) => {
                    if(parentChild.props.mount) {
                        if(index !== this.state.selected) {
                            return <div style={{ opacity: 0, visibility: "hidden", display: "none" }}>{React.cloneElement(parentChild, { raw: true })}</div>
                        }

                        return React.cloneElement(parentChild, { raw: index === this.state.selected, key: index })
                    } else {
                        if(index !== this.state.selected) return null

                        return React.cloneElement(parentChild, { raw: index === this.state.selected, key: index })
                    }
                })}
            </Body>
        </Parent>
    }
}

class AmountComponent extends Component {
    render() {
        if(this.props.amount === null || this.props.amount === undefined) return null
        return <div className="amount" style={{ background: this.props.amount === 0 ? "#a9a9a9" : (this.props.amountColor || "#3b97d3")}}>{this.props.amount}</div>
    }
}

Section.Item = class extends Component {
    render() {
        if(this.props.raw) return this.props.children || null

        if(this.props.hidden) return null

        return <Item disabled={this.props.disabled} style={this.props.style} alwaysCenter={!this.props.switch} showLeftArrow={this.props.selected && this.props.index !== 0} showRightArrow={this.props.selected && this.props.index !== this.props.childrenTotal - 1} onContextMenu={e => this.props.onContextMenu(e)} onClick={() => {
            if(!this.props.disabled) this.props.select()
        }} selected={this.props.selected}>
            {this.props.switch && <i className="far fa-arrow-left arrowButton arrowButtonLeft" onClick={() => {
                if(this.props.index === 0 || this.props.childrenTotal === 0) return

                let newIndex = this.props.index - 1
                this.props.switch(this.props.index, newIndex)
            }} />}
            <p>
                {this.props.icon && <i className={`${this.props.iconType || "fa-duotone"} fa-${this.props.icon} mainIcon`} />}
                {this.props.title}
            </p>
            {this.props.switch && <i className="far fa-arrow-right arrowButton arrowButtonRight" onClick={() => {
                if(this.props.index === this.props.childrenTotal - 1 || this.props.childrenTotal === 0) return

                let newIndex = this.props.index + 1
                this.props.switch(this.props.index, newIndex)
            }} />}
            <AmountComponent amount={this.props.amount} amountColor={this.props.amountColor} />
        </Item>
    }
}

export default Section
