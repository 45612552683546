import React, { Component } from "react"
import { Breadcrumbs, Page, Section } from "scanmetrix-components"
import i18next from "i18next"
import BudgetPoolSubsidiaries from "./tabs/subsidiaries"
import BudgetPool from "./tabs/budgetPool"

export default class extends Component {
    state = {
        budgetPoolSubsidiaryAmount: 0,
        budgetPool: null,
        calculations: []
    }

    constructor(props) {
        super(props);

        this.fetch = this.fetch.bind(this)

        this.fetch()
    }

    fetch() {
        fetch(`${scanmetrix.nestURL}/v2/budget-pool/${this.props.match.params.budgetpoolid}`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(budgetPool => {
            fetch(`${scanmetrix.nestURL}/v2/budget-pool/calculations/${this.props.match.params.budgetpoolid}`, {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(result => result.json()).then(calculations => {
                this.setState({ calculations, budgetPool })
            })
        })
    }

    render() {
        const { budgetPool } = this.state

        return <Page {...this.props}>
            <Breadcrumbs values={[
                {
                    icon: "piggy-bank",
                    title: i18next.t("page.private.budget.breadCrumbsTitle")
                },
                {
                    icon: "chart-pie-simple-circle-dollar",
                    title: "Budget Pools",
                    link: "/budget"
                },
                {
                    title: budgetPool?.name
                }
            ]} />
            <Section bodyPadding="32px 0 0 0">
                <Section.Item title="Budget Pool" icon="chart-pie-simple-circle-dollar">
                    {budgetPool && <BudgetPool data={budgetPool} calculations={this.state.calculations} />}
                </Section.Item>
                <Section.Item mount title="Niederlassungen" icon="map-marker"
                              amount={this.state.budgetPoolSubsidiaryAmount}>
                    {budgetPool && <BudgetPoolSubsidiaries
                        metaCallback={meta => this.setState({budgetPoolSubsidiaryAmount: meta.total})}
                        budgetPoolId={budgetPool.id}/>}
                </Section.Item>
            </Section>
        </Page>
    }
}
