import React, { Component } from 'react'
import { Button, Modal, Input, Grid, GridElement, Select } from 'scanmetrix-components'

export default class extends Component {
    state = { content: null, name: null, loading: false }

    render() {
        return <Modal minWidth="768px" instance={this.props.instance} initialize={() => this.setState({ type: null, name: null, number: null, loading: false })}>
            <Modal.Head title="Ausgabeart anlegen" icon="wallet" />
            <Modal.Body padding={24}>
                <Grid gap="16px">
                    <GridElement>
                        <Select
                            label="Typ"
                            adjustWidth
                            noUnselect
                            required
                            value={this.state.type}
                            onChange={type => this.setState({ type })}
                            icon="cog"
                            items={[
                                { key: "CAPEX", title: "CapEx - Capital Expenditures", icon: "money-bills" },
                                { key: "OPEX", title: "OpEx - Operational Expenditures", icon: "coin" },
                            ]}
                        />
                    </GridElement>
                    <GridElement>
                        <Input label="Name" icon="tag" value={this.state.name} required onChange={name => this.setState({ name })} adjustWidth />
                    </GridElement>
                    <GridElement>
                        <Input label="Nummer" icon="table" value={this.state.number} onChange={number => this.setState({ number })} adjustWidth />
                    </GridElement>
                </Grid>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} disabled={!this.state.name || !this.state.type} thick title="Ausgabeart anlegen" icon="wallet" onClick={() => {
                this.setState({ loading: true })

                fetch(`${scanmetrix.nestURL}/v2/expense-type`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        name: this.state.name,
                        type: this.state.type,
                        number: this.state.number
                    })
                }).then(result => {
                    if(result.status === 201) {
                        this.props.refresh().then(() => {
                            instance.close()
                        })
                    } else {
                        this.setState({ loading: false })
                    }
                }).catch(() => this.setState({ loading: false }))
            }} />} />
        </Modal>
    }
}
