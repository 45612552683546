import React, { Component } from "react"
import { Modal, Table } from "scanmetrix-components"
import moment from "moment"

const renderStatus = status => {
    switch(status) {
        case "pending": return <><i className="fa-duotone fa-clock" /> Ausstehend</>
        case "sent": return <><i style={{ color: "#3b97d3" }} className="fa-duotone fa-envelope" /> Verschickt</>
        case "completed": return <><i style={{ color: "#2ecc71" }} className="fa-duotone fa-check" /> Unterschrieben</>
        case "rejected": return <><i style={{ color: "#e74c3c" }} className="fa-duotone fa-times" /> Abgelehnt</>
        case "soft_bounced": return <><i style={{ color: "#9b59b6" }} className="fa-duotone fa-hourglass" /> Zustellung wird erneut versucht</>
        case "hard_bounced": return <><i style={{ color: "#e74c3c" }} className="fa-duotone fa-times" /> Zustellung fehlgeschlagen</>
        case "failed": return <><i style={{ color: "#e74c3c" }} className="fa-duotone fa-times" /> Fehlgeschlagen</>
        case "replaced": return <><i style={{ color: "#9b59b6" }} className="fa-duotone fa-history" /> Ersetzt</>
    }

    return <><i style={{ color: "#3b97d3" }} className="fa-duotone fa-question" /> Unbekannt</>
}

export default class extends Component {
    state = { measureApprovalId: null, error: false, data: null }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={measureApprovalId => {
            this.setState({ measureApprovalId, error: false, data: null })

            fetch(`${scanmetrix.nestURL}/v2/measure-approval/${measureApprovalId}/signatures`, {
                method: "GET",
                credentials: "include"
            }).then(async result => {
                const data = await result.json()

                if(Array.isArray(data)) this.setState({ data })
            })
        }}>
            <Modal.Head title="Signaturstatus ansehen" icon="users" />
            <Modal.Body>
                {this.state.measureApprovalId && <Table
                  noControls
                  head={[
                      { title: "E-Mail", width: "35%", column: "email" },
                      { title: "Vor- und Nachname", width: "25%", column: "name" },
                      { title: "Status", width: "30%", column: "status" },
                      { title: "Datum", width: "10%", column: "date" },
                  ]}
                  items={this.state.data ? this.state.data.map(signature => ({
                      ...signature,
                      date: signature.date ? moment(signature.date).format("DD.MM.YYYY HH:mm:ss") : null,
                      status: renderStatus(signature.status)
                  })) : null}
                />}
            </Modal.Body>
        </Modal>
    }
}
