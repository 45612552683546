import React, { Component } from "react"
import CreateBudgetPoolSubsidiaryModal from "./modals/CreateBudgetPoolSubsidiaryModal"
import DeleteBudgetPoolSubsidiaryModal from "./modals/DeleteBudgetPoolSubsidiaryModal"
import { GridElement, Table, Button } from "scanmetrix-components"

const inlineAddress = parent => (parent.address_additional ? parent.address_additional + ", " : "") + parent.address_street_name + " " + parent.address_street_number + ", " + parent.address_postal_code + " " + parent.address_city + ", " + parent.address_country

export default class extends Component {
    render() {
        return <>
            <CreateBudgetPoolSubsidiaryModal budgetPoolId={this.props.budgetPoolId} instance={ref => this.createBudgetPoolSubsidiaryModal = ref} refresh={() => this.refreshBudgetPoolSubsidiaries()} />
            <DeleteBudgetPoolSubsidiaryModal instance={ref => this.deleteBudgetPoolSubsidiaryModal = ref} refresh={() => this.refreshBudgetPoolSubsidiaries()} />
            <GridElement styled title="Niederlassungen" icon="map-marker" rightContent={<Button icon="map-marker" title="Niederlassung hinzufügen" thick primary onClick={() => this.createBudgetPoolSubsidiaryModal.open()} />}>
                <Table
                    refresh={refresh => this.refreshBudgetPoolSubsidiaries = refresh}
                    head={[
                        { title: "Name", width: "15%", column: "name", orderField: "subsidiary.name" },
                        { title: "Rechtliche Bezeichnung", width: "15%", column: "legal_name", orderField: "subsidiary.legal_name" },
                        { title: "Kennz.", width: "10%", column: "label", orderField: "subsidiary.label" },
                        { title: "Adresse", width: "40%", column: "address" },
                        { title: "Bearbeitet", width: "10%", column: "updated_at", orderField: "updated_at" },
                        { title: "Erstellt", width: "10%", column: "created_at", orderField: "created_at" },
                    ]}
                    contextItems={[
                        { title: "Niederlassung entfernen", icon: "trash", onClick: item => this.deleteBudgetPoolSubsidiaryModal.open(item.id)}
                    ]}
                    metaCallback={this.props.metaCallback}
                    query={`${scanmetrix.nestURL}/v2/budget-pool-subsidiary/${this.props.budgetPoolId}`}
                    defaultSort="created_at_DESC"
                    map={budgetPoolSubsidiaries => budgetPoolSubsidiaries.map(budgetPoolSubsidiary => ({
                        ...budgetPoolSubsidiary,
                        name: budgetPoolSubsidiary.subsidiary.name,
                        label: budgetPoolSubsidiary.subsidiary.label,
                        legal_name: budgetPoolSubsidiary.subsidiary.legal_name,
                        address: inlineAddress(budgetPoolSubsidiary.subsidiary),
                        updated_at: moment(budgetPoolSubsidiary.updated_at).fromNow(),
                        created_at: moment(budgetPoolSubsidiary.created_at).fromNow(),
                    }))}
                />
            </GridElement>
        </>
    }
}
