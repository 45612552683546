import React, { Component } from 'react'
import { Button, Modal, GridElement, Input, Select } from 'scanmetrix-components'
import { Document, Page } from 'react-pdf'
import styled from "styled-components"
import { MoonLoader as LoadingAnimation } from "react-spinners"
import Dropzone from "react-dropzone";
import prettyBytes from "pretty-bytes";
import i18next from "i18next";

const pageScale = 2.5
const pageWidth = 210 * pageScale
const pageHeight = 297 * pageScale

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

const UploadBody = styled.div`
  flex: 1;
  width: 500px;
  height: 250px;
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 250ms ease;
  position: relative;
  box-shadow: ${props => props.isDragActive ? "inset 0 0 0 4px #3b97d3" : "inset 0 0 0 1px rgba(0, 0, 0, 0.25)"};
  border-radius: 5px;

  &:hover {
    opacity: 0.75;
  }
  
  input {
    display: none;
  }

  i {
    font-size: 28px;
    margin-bottom: 24px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    width: 50%;
  }
`

const StyledFile = styled.div`
    padding: 24px;
    width: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    border-radius: 5px;
  
    .name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 350px;
        margin-right: 16px;
    }
  
    .delete {
        margin-left: 12px;
        cursor: pointer;
        color: #e74c3c;
        transition: all 0.3s;
      
        &:hover {
            opacity: 0.75;
        }
    }
`

const StyledPage = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 16px;
  
    .controls {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      
        .page {
            user-select: none;
            flex-shrink: 0;
            width: ${pageWidth / 3.5 + "px"};
            text-align: center;
        }
    }
  
    .pageDocument {
        width: ${pageWidth + "px"};
        height: ${pageHeight + "px"};
        background: white;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
        user-select: none;
        transition: opacity 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 5px;
      
        &:hover {
            opacity: 0.75;
        }
    }
`

const StyledModal = styled.div`
    display: grid;
    grid-template-columns: ${pageWidth + "px"} 1fr;
    grid-gap: 32px;

    .right {
        .grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 32px;
        }
    }
`

const LoadingContainer = styled.div`
    padding: 64px 0;
`

class Loading extends Component {
    render() {
        return <LoadingContainer>
            <LoadingAnimation sizeUnit="px" size={64} color="#3b97d3" />
        </LoadingContainer>
    }
}

export default class extends Component {
    state = {
        type: "upload",
        measureOfferRequestId: null,
        numPages: null,
        loading: false,
        page: 1,
        date: null,
        number: null,
        net: null,
        gross: null,
        serviceProviders: []
    }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query {
                    ServiceProviders {
                        nodes {
                            id
                            name
                            address {
                                streetName
                                streetNumber
                                additional
                                postalCode
                                city
                                country
                            }
                        }
                    }
                }
            `
        }).then(data => {
            this.setState({ serviceProviders: data.data.ServiceProviders.nodes })
        })
    }

    render() {
        return <Modal instance={this.props.instance} initialize={measureOfferRequestId => this.setState({
            measureOfferRequestId,
            type: "upload",
            page: 1,
            numPages: null,
            loading: false,
            date: null,
            number: null,
            net: null,
            budgetCategoryId: null,
            gross: null,
            file: null
        })}>
            <Modal.Head title={i18next.t("page.private.measure.updateOfferModal.title")} icon="pencil" />
            <Modal.Body padding={32}>
                {this.state.type === "upload" && <>
                    {!this.state.file && <Dropzone accept={[ "application/pdf" ]} multiple={false} onDrop={acceptedFiles => {
                        if(acceptedFiles.length && !this.state.loading) {
                            const file = acceptedFiles[0]

                            this.setState({ file, type: "create" })
                        }
                    }}>
                        {({ getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive }) => <UploadBody tabIndex={0} {...getRootProps({ isDragActive, isDragAccept, isDragReject, refKey: "innerRef", className: 'dropzone' })}>
                            <input {...getInputProps()} tabIndex={-1} accept='application/pdf' multiple={true} type='file' autoComplete='off' />

                            <i className="far fa-upload" />
                            <p className="title">{i18next.t("page.private.measure.updateOfferModal.description")}</p>
                        </UploadBody>}
                    </Dropzone>}
                    {this.state.file && <StyledFile>
                        <div className="name">{this.state.file.name}</div>
                        <div className="size">{prettyBytes(this.state.file.size)} <i className="far fa-trash delete" onClick={() => {
                            if(!this.state.loading) this.setState({ file: null })
                        }} /></div>
                    </StyledFile>}
                </>}
                {this.state.type === "create" && <>
                    <StyledModal>
                        <StyledPage>
                            <div className="pageDocument">
                                <Document loading={<Loading />} options={{ withCredentials: true }} file={this.state.file} onLoadSuccess={({ numPages }) => {
                                    this.setState({ numPages })
                                }}>
                                    <Page pageNumber={this.state.page} width={pageWidth} height={pageHeight} />
                                </Document>
                            </div>
                            <div className="controls">
                                <Button secondary thick icon="arrow-left-to-line" noIconMargin disabled={this.state.page === 1 || this.state.numPages === null} onClick={() => this.setState({ page: 1 })} />
                                <Button secondary thick icon="arrow-left" title={i18next.t("page.private.measure.updateOfferModal.backButton")} disabled={this.state.page === 1 || this.state.numPages === null} onClick={() => this.setState({ page: this.state.page - 1 })} />
                                {this.state.numPages !== null && <div className="page">{i18next.t("page.private.measure.updateOfferModal.page")} {this.state.page} {i18next.t("page.private.measure.updateOfferModal.from")} {this.state.numPages}</div>}
                                {this.state.numPages === null && <div className="page">{i18next.t("page.private.measure.updateOfferModal.pdfDownload")}</div>}
                                <Button secondary thick icon="arrow-right" title={i18next.t("page.private.measure.updateOfferModal.nextButton")} disabled={this.state.page === this.state.numPages || this.state.numPages === null} onClick={() => this.setState({ page: this.state.page + 1 })} />
                                <Button secondary thick icon="arrow-right-to-line" noIconMargin disabled={this.state.page === this.state.numPages || this.state.numPages === null} onClick={() => this.setState({ page: this.state.numPages })} />
                            </div>
                        </StyledPage>

                        <div className="right">
                            <div className="grid">
                                <GridElement columnStart={1} columnEnd={3}>
                                    <Modal.Divider title={i18next.t("page.private.measure.updateOfferModal.offerData")} />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={2}>
                                    <Input readOnly={this.state.loading} label={i18next.t("page.private.measure.updateOfferModal.offerDate")} icon="calendar-day" date required adjustWidth value={this.state.date} onChange={date => this.setState({ date })} />
                                </GridElement>
                                <GridElement columnStart={2} columnEnd={3}>
                                    <Input readOnly={this.state.loading} label={i18next.t("page.private.measure.updateOfferModal.offerNo")} icon="file-invoice" required adjustWidth value={this.state.number} onChange={number => this.setState({ number })} />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={2}>
                                    <Input readOnly={this.state.loading} label={i18next.t("page.private.measure.updateOfferModal.net")} icon="piggy-bank" format={val => val !== null ? formatter.format(val) : val} float required adjustWidth value={this.state.net} onChange={net => this.setState({ net })} />
                                </GridElement>
                                <GridElement columnStart={2} columnEnd={3}>
                                    <Input readOnly={this.state.loading} label={i18next.t("page.private.measure.updateOfferModal.gross")} icon="piggy-bank" format={val => val !== null ? formatter.format(val) : val} float required adjustWidth value={this.state.gross} onChange={gross => this.setState({ gross })} />
                                </GridElement>
                                <GridElement columnStart={1} columnEnd={3}>
                                    <Select adjustWidth value={this.state.budgetCategoryId} onChange={budgetCategoryId => this.setState({ budgetCategoryId })} icon="piggy-bank" label="Budget Kategorie" fetch={{
                                        url: "/v2/budget-category",
                                        map: dataSet => ({ key: dataSet.id, title: `${dataSet.expenseType?.type || "Sonstige"} / ${dataSet.expenseType?.name || "Sonstige"} / ${dataSet.name}` })
                                    }} />
                                </GridElement>
                                {!this.state.measureOfferRequestId && <GridElement columnStart={1} columnEnd={3}>
                                    <Select required upwards value={this.state.serviceProviderId} onChange={serviceProviderId => {
                                        this.setState({ serviceProviderId })
                                    }} icon="user-tie" label={i18next.t("page.private.workOrders.createPurchaseOrderModal.serviceProvider")} adjustWidth items={this.state.serviceProviders.map(serviceProvider => ({ key: serviceProvider.id, title: (serviceProvider.address.postalCode && serviceProvider.address.city) ? `${serviceProvider.name} / ${serviceProvider.address.postalCode} ${serviceProvider.address.city}` : serviceProvider.name, data: serviceProvider }))} />
                                </GridElement>}
                            </div>
                        </div>
                    </StyledModal>
                </>}
            </Modal.Body>
            {this.state.type === "create" && <Modal.Footer buttons={instance => <Button loading={this.state.loading} disabled={(!this.state.serviceProviderId && !this.state.measureOfferRequestId) || !this.state.net || !this.state.gross || !this.state.date || !this.state.number} thick title={i18next.t("page.private.measure.updateOfferModal.exitButton")} icon="pencil" onClick={() => {
                if(this.state.type === "upload") {
                    this.setState({ type: "create" })
                } else {
                    this.setState({ loading: true })

                    let variables = {
                        measureOfferRequestId: this.state.measureOfferRequestId,
                        serviceProviderId: this.state.serviceProviderId,
                        measureId: this.props.measureId,
                        number: this.state.number,
                        net: this.state.net,
                        gross: this.state.gross,
                        file: this.state.file,
                        budgetCategoryId: this.state.budgetCategoryId,
                        date: moment(this.state.date, "DD.MM.YYYY").toDate()
                    }

                    return scanmetrix.client.mutate({
                        mutation: scanmetrix.gql`
                        mutation($measureId: ID!, $measureOfferRequestId: ID, $budgetCategoryId: ID, $serviceProviderId: ID, $number: String!, $net: Float!, $gross: Float!, $date: DateTime!, $file: Upload!) {
                            uploadMeasureOffer(measureId: $measureId, serviceProviderId: $serviceProviderId, budgetCategoryId: $budgetCategoryId, measureOfferRequestId: $measureOfferRequestId, number: $number, net: $net, gross: $gross, date: $date, file: $file)
                        }   
                    `,
                        variables
                    }).then(data => {
                        if(data.data.uploadMeasureOffer) {
                            this.props.refresh().then(() => instance.close())
                        } else {
                            this.setState({ loading: false })
                        }
                    })
                }
            }} />} />}
        </Modal>
    }
}
