import React, { Component } from 'react'
import { Button, Modal, Input, Grid, GridElement, Select } from 'scanmetrix-components'

export default class extends Component {
    state = {}

    render() {
        return <Modal minWidth="768px" instance={this.props.instance} initialize={() => this.setState({ name: null, budgetTemplateId: null, phasing: null, begin: null, end: null, error: false, loading: false })}>
            <Modal.Head title="Budget Pool anlegen" icon="chart-pie-simple-circle-dollar" />
            <Modal.Body padding={24} adjustHeight="450px">
                <Grid gap="16px" columns={[ "1fr", "1fr" ]}>
                    <GridElement>
                        <Input label="Beginn" date icon="hourglass-start" value={this.state.begin} required onChange={begin => this.setState({ begin })} adjustWidth />
                    </GridElement>
                    <GridElement>
                        <Input label="Ende" date icon="hourglass-end" value={this.state.end} required onChange={end => this.setState({ end })} adjustWidth />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={3}>
                        <Select noUnselect label="Budget Vorlage" icon="filter-circle-dollar" value={this.state.budgetTemplateId} required onChange={budgetTemplateId => this.setState({ budgetTemplateId })} adjustWidth fetch={{
                            url: "/v2/budget-template",
                            map: dataSet => ({ key: dataSet.id, title: dataSet.name })
                        }} />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={3}>
                        <Select noUnselect label="Phasing" icon="arrows-spin" value={this.state.phasing} required onChange={phasing => this.setState({ phasing })} adjustWidth items={[
                            { key: "MONTHLY", title: "Monatsweise" },
                            { key: "QUARTERLY", title: "Quartalsweise" },
                            { key: "YEARLY", title: "Jahresweise" },
                        ]} />
                    </GridElement>
                    <GridElement columnStart={1} columnEnd={3}>
                        <Input label="Name" icon="tag" value={this.state.name} required onChange={name => this.setState({ name })} adjustWidth />
                    </GridElement>
                    {this.state.error && <GridElement columnStart={1} columnEnd={3}>
                        <Modal.Error>Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.</Modal.Error>
                    </GridElement>}
                </Grid>
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} disabled={!this.state.name || !this.state.budgetTemplateId || !this.state.phasing || !this.state.begin || !this.state.end} thick title="Budget Pool anlegen" icon="chart-pie-simple-circle-dollar" onClick={() => {
                this.setState({ loading: true, error: false })

                fetch(`${scanmetrix.nestURL}/v2/budget-pool`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        name: this.state.name,
                        budget_template_id: this.state.budgetTemplateId,
                        phasing: this.state.phasing,
                        begin: moment(this.state.begin, "DD.MM.YYYY").toDate(),
                        end: moment(this.state.end, "DD.MM.YYYY").toDate()
                    })
                }).then(result => {
                    if(result.status === 201) {
                        this.props.refresh().then(() => {
                            instance.close()
                        })
                    } else {
                        this.setState({ loading: false, error: true })
                    }
                }).catch(() => this.setState({ loading: false }))
            }} />} />
        </Modal>
    }
}
