import React, { Component } from 'react'
import { Button, Modal, Select } from 'scanmetrix-components'

export default class extends Component {
    state = { content: null, name: null, loading: false }

    render() {
        return <Modal minWidth="768px" instance={this.props.instance} initialize={() => this.setState({ subsidiaryId: null, loading: false })}>
            <Modal.Head title="Niederlassung hinzufügen" icon="map-marker" />
            <Modal.Body padding={24} adjustHeight="330px">
                <Select
                    label="Niederlassung"
                    adjustWidth
                    noUnselect
                    icon="map-marker"
                    value={this.state.subsidiaryId}
                    onChange={subsidiaryId => this.setState({ subsidiaryId })}
                    fetch={{
                        url: "/v2/subsidiary",
                        queryParams: { notAssignedToBudgetPoolId: this.props.budgetPoolId },
                        map: dataSet => ({ key: dataSet.id, title: `${dataSet.label ? (dataSet.label + " / ") : ""}${dataSet.name} / ${dataSet.address_postal_code} ${dataSet.address_city}` })
                    }}
                />
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} disabled={!this.state.subsidiaryId} thick title="Niederlassung hinzufügen" icon="map-marker" onClick={() => {
                this.setState({ loading: true })

                fetch(`${scanmetrix.nestURL}/v2/budget-pool-subsidiary`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        subsidiary_id: this.state.subsidiaryId,
                        budget_pool_id: this.props.budgetPoolId
                    })
                }).then(result => {
                    if(result.status === 201) {
                        this.props.refresh().then(() => {
                            instance.close()
                        })
                    } else {
                        this.setState({ loading: false })
                    }
                }).catch(() => this.setState({ loading: false }))
            }} />} />
        </Modal>
    }
}
