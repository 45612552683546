import React, { Component } from "react"
import styled from "styled-components"
import Input from "../Input"
import Select from "../Select"
import Button from "../Button"
import * as JsSearch from "js-search"
import Avatar from "react-avatar"
import ContentLoader from "react-content-loader"
import {contextMenu, Item, Menu} from 'react-contexify'

import i18next from "i18next"

const StyledTable = styled.table`
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  
  @media screen and (max-width: 1280px) {
    zoom: 0.75;
  }
`

const TableHead = styled.thead`
  height: 64px;

  tr th {
    text-align: left;
    height: 64px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: normal;
    font-size: 1.1em;
    padding: 0 16px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:last-child {
      text-align: right;
    }
  }
`

const TableBody = styled.tbody`
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);    
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  width: 100%;
  min-height: 400px;

  tr {
    cursor: pointer;
    user-select: none;
      
    &:hover {
      background: rgba(0, 0, 0, 0.07)
    }
    
    &:nth-child(even) td {
      background: rgba(0, 0, 0, 0.04);
    }
    
    td {
      padding: 8px 16px;
      box-sizing: border-box;
      
      &:last-child {
        text-align: right;
      }
      
      i {
        margin-right: 8px;
        
        &.green {
          color: #27dbb8;
          font-size: 1.1em;
        }
                
        &.red {
          color: #e74c3c;
          font-size: 1.1em;
        }
                
        &.orange {
          color: #e67e22;
          font-size: 1.1em;
         }
         
        &.blue {
          color: #3B97D3;
          font-size: 1.1em;
        }
      }
      
      b {
        color: #3B97D3;
      }
    }
  }
`

const TableFooteer = styled.tfoot`
  
`

const TR = styled.tr`
  position: relative;
`

const TH = styled.th`
  width: ${props => props.width};
  user-select: none;
`

const TD = styled.td`
  height: 48px;
  font-size: 1.05em;
  color: rgba(0, 0, 0, 0.75);
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  
  &:first-child {
      overflow: visible;
  }
`

const ProfilePicture = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 32px;
  margin-right: 8px;
`

const DisplayUser = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TableControls = styled.div`
  padding: ${props => props.padding};
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media screen and (max-width: 1280px) {
    flex-direction: column-reverse;
  }
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-left: 32px;
  
  @media screen and (max-width: 1280px) {
    margin-left: 0;
  }
  
  button {
    margin-right: 16px;
    
    &:last-child {
      margin-right: 0;
    }
  }
  
  p.current {
    margin-right: 16px;
    white-space: nowrap;
    user-select: none;
    
    b {
      color: #3b97d3;
    }
  }
`

const Placeholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;
    width: 100%;
    user-select: none;
    
    h2 {
      font-weight: normal;
    }
    
    img {
        width: 150px;
        margin-top: 16px;
        -webkit-user-drag: none;
    }
`

class Table extends Component {
    state = { page: 0, limit: 15, search: "", items: null, filtersOpen: false, filters: {}, meta: {} }

    constructor(props) {
        super(props)

        this.getLength = this.getLength.bind(this)
        this.getPages = this.getPages.bind(this)
        this.search = this.search.bind(this)

        if(props.items !== undefined) this.state.items = props.items

        if(!props.query) this.search(null)

        this.fetch = this.fetch.bind(this)

        if(props.refresh) props.refresh(() => this.fetch())

        if(props.defaultSort) {
            this.state.orderBy = props.defaultSort.substring(0, props.defaultSort.lastIndexOf("_"))
            this.state.orderDirection = props.defaultSort.substring(props.defaultSort.lastIndexOf("_") + 1, props.defaultSort.length)

            console.log(this.state)
        }

        if(props.query) {
            this.state.page = 1
            this.fetch(1)
        }
    }

    fetch(page) {
        const url = this.props.query
        const defaultSort = this.props.defaultSort || "id_DESC"
        const perPage = 20

        this.setState({ items: null })

        const orderField = this.state.orderBy ? `${this.state.orderBy}_${this.state.orderDirection}` : defaultSort

        return fetch(`${url}?${new URLSearchParams({
            orderBy: orderField,
            page: page || this.state.meta.currentPage,
            perPage,
            search: this.state.search || ""
        }).toString()}`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(result => result.json()).then(({ data, meta }) => {
            if(meta.currentPage > meta.lastPage && meta.lastPage !== 0) {
                return this.fetch(meta.lastPage)
            } else {
                this.setState({ items: this.props.map(data), meta })
                if(this.props.metaCallback) this.props.metaCallback(meta)
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.items) {
            this.search(this.state.search, nextProps.items)
        }
    }

    getLength() {
        return (this.state.items || []).length || 1
    }

    getPages(length = this.getLength()) {
        if(this.props.query) return this.state.meta.lastPage || 1
        return Math.floor(length / this.state.limit) + (length % this.state.limit === 0 ? 0 : 1)
    }

    search(searchString, base) {
        if(this.lastSearch) clearTimeout(this.lastSearch)

        this.setState({ search: searchString })

        this.lastSearch = setTimeout(() => {
            if(this.props.query) {
                this.setState({ items: null }, () => this.fetch(this.state.meta.currentPage))
            } else {
                let search = new JsSearch.Search(this.props.searchIndex || "id")

                search.addDocuments((base || this.props.items) || [])

                this.props.head.forEach(td => {
                    if(td.column) search.addIndex(td.column)
                })

                if(this.props.additionalSearchIndexes) this.props.additionalSearchIndexes.forEach(td => {
                    search.addIndex(td)
                })

                let raw = searchString ? search.search(searchString) : base

                if(base) {
                    this.setState({
                        items: raw,
                        search: searchString,
                        page: Math.min(this.state.page, (this.getPages((raw || []).length) || 1) - 1)
                    })
                } else if (raw) {
                    this.state.items = raw
                }
            }
        }, 200)
    }

    render() {
        let items = []

        let filtered = this.state.items || []

        if(Object.keys(this.state.filters)) {
            Object.keys(this.state.filters).forEach(key => {
                const val = this.state.filters[key]
                const filter = this.props.filters[key]

                if(val && filter) filtered = filter.apply(filtered, val)
            })
        }

        if(this.props.noControls || this.props.query) {
            items = [ ...filtered ]
        } else if(!this.props.query) {
            if (filtered) {
                for (let i = this.state.page * this.state.limit; i < this.state.page * this.state.limit + this.state.limit && i < filtered.length; i++) {
                    items.push(filtered[i])
                }
            }
        }

        return <>
            {this.props.contextItems && <Menu id={this.props.menuName || "context_menu"}>
                {this.props.contextItems.map((item, key) => {
                    return <Item disabled={({ props }) => item.disabled === true || (item.disabled && item.disabled(props.item))} key={key} onClick={({ props }) => {
                        item.onClick(props.item)
                    }}>
                        <i style={{ width: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }} className={`far fa-${item.icon}`} />{item.title}
                    </Item>
                })}


            </Menu>}
            {!this.props.noControls && !this.props.query && <TableControls padding={this.props.controlsPadding || "32px 16px"}>
                <Input adjustWidth label={i18next.t("component.table.searchPlaceholder")} value={this.state.search} onChange={searchString => this.search(searchString, this.props.items)} />
                <Buttons>
                    {this.props.filters && <div style={{ position: "relative" }}>
                        <Button title="Filter" icon="filter" onClick={() => this.setState({ filtersOpen: !this.state.filtersOpen })} />
                        <Filters open={this.state.filtersOpen} filters={this.props.filters} callback={filters => this.setState({ filters })} />
                    </div>}
                    <Button title={i18next.t("component.table.button.begin")} icon="chevron-double-left" disabled={this.state.page === 0} onClick={() => this.setState({ page: 0 })} />
                    <Button title={i18next.t("component.table.button.back")} icon="chevron-left"  disabled={this.state.page === 0} onClick={() => this.setState({ page: this.state.page - 1 })} />
                    <p className="current">{i18next.t("component.table.page")} <b>{this.state.page + 1}</b> / <b>{this.getPages()}</b> (<i>{this.getLength()} {i18next.t("component.table.entries")})</i></p>
                    <Button title={i18next.t("component.table.button.further")} icon="chevron-right" disabled={this.state.page === this.getPages() - 1} onClick={() => this.setState({ page: this.state.page + 1 })} />
                    <Button title={i18next.t("component.table.button.end")} icon="chevron-double-right" disabled={this.state.page === this.getPages() - 1} onClick={() => this.setState({ page: this.getPages() - 1 })} />
                </Buttons>
            </TableControls>}
            {!this.props.noControls && !!this.props.query && <TableControls padding={this.props.controlsPadding || "32px 16px"}>
                <Input adjustWidth label={i18next.t("component.table.searchPlaceholder")} value={this.state.search} onChange={searchString => this.search(searchString, this.props.items)} />
                <Buttons>
                    {this.props.filters && <div style={{ position: "relative" }}>
                        <Button title="Filter" icon="filter" onClick={() => this.setState({ filtersOpen: !this.state.filtersOpen })} />
                        <Filters open={this.state.filtersOpen} filters={this.props.filters} callback={filters => this.setState({ filters })} />
                    </div>}
                    <Button title={i18next.t("component.table.button.begin")} icon="chevron-double-left" disabled={!this.state.meta.prev} onClick={() => this.fetch(1)} />
                    <Button title={i18next.t("component.table.button.back")} icon="chevron-left"  disabled={!this.state.meta.prev} onClick={() => this.fetch(this.state.meta.prev)} />
                    <p className="current">{i18next.t("component.table.page")} <b>{this.state.meta.currentPage}</b> / <b>{this.state.meta.lastPage}</b> (<i>{this.state.meta.total} {i18next.t("component.table.entries")})</i></p>
                    <Button title={i18next.t("component.table.button.further")} icon="chevron-right" disabled={!this.state.meta.next} onClick={() => this.fetch(this.state.meta.next)} />
                    <Button title={i18next.t("component.table.button.end")} icon="chevron-double-right" disabled={!this.state.meta.next} onClick={() => this.fetch(this.state.meta.lastPage)} />
                </Buttons>
            </TableControls>}
            <StyledTable>
                <TableHead>
                    <TR>
                        {this.props.head.map((td, index) => <TH onClick={() => {
                            if(this.state.orderBy === td.orderField) {
                                if(this.state.orderDirection === "DESC") this.setState({ orderDirection: "ASC" }, this.fetch)
                                else if(this.state.orderDirection === "ASC") this.setState({ orderDirection: "DESC" }, this.fetch)
                            } else {
                                this.setState({ orderBy: td.orderField, orderDirection: "DESC" }, this.fetch)
                            }
                        }} style={{ cursor: td.orderField ? "pointer" : "default" }} key={index} width={td.width}>{td.title}{td.orderField && <i style={{ opacity: 0.5, marginLeft: 6 }} className={`fas fa-${this.state.orderBy === td.orderField ? (this.state.orderDirection === "DESC" ? "sort-down" : "sort-up") : "sort"}`} />}</TH>)}
                    </TR>
                </TableHead>
                <TableBody>
                    {this.state.items && items.map((item, index) => <TR onContextMenu={this.props.contextItems ? event => {
                        event.preventDefault()
                        contextMenu.show({ id: this.props.menuName || "context_menu", event, props: { item } })
                    } : null} onClick={() => (this.props.onItemClick || (() => {}))(item)} key={index}>
                        {this.props.head.map((td, index) => <TD key={index}>{(td.format || (val => val))(item[td.column])}</TD>)}
                    </TR>)}
                    {this.state.items === null && <>
                        {[ 0, 0, 0, 0, 0 ].map((_, index) => <tr key={index}>
                            {this.props.head.map((td, index) => <td key={index}>
                                <ContentLoader
                                    speed={2}
                                    width={index === this.props.head.length - 1 ? "100%" : "calc(100% + 16px)"}
                                    height={24}
                                    viewBox="0 0 100% 24"
                                    style={{ padding: "8px 0 4px 0" }}
                                    foregroundColor="rgba(0, 0, 0, 0.15)"
                                    backgroundColor="rgba(0, 0, 0, 0.05)">
                                    <rect x="0" y="0" rx="4" ry="4" width="100%" height="24"/>
                                </ContentLoader>
                            </td>)}
                        </tr>)}
                    </>}
                </TableBody>
            </StyledTable>
            {this.state.items && this.state.items.length === 0 && <Placeholder colspan={this.props.head.length}>
                <h2>{i18next.t("component.table.content")}</h2>
                <img src="/empty.svg" />
            </Placeholder>}
        </>
    }
}

const StyledFilters = styled.div`
    background: white;
    box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.35);
    position: absolute;
    z-index: 20;
    top: 48px;
    left: 0;
    padding: 16px;
    width: 250px;
    height: 64px;
    border-radius: 5px;
    
    .arrow {
      position: absolute;
      top: -12px;
      left: 16px;
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        
        border-bottom: 12px solid white;
    }
`

class FilterSelect extends Component {
    state = { value: null }

    constructor(props) {
        super(props)

        if(props.value) this.state.value = props.value
    }

    render() {
        return <div style={{ width: "100%" }}>
            <Select items={this.props.items} label={this.props.label} adjustWidth onChange={value => this.setState({ value }, () => this.props.onActiveChange(value))} value={this.state.value} />
        </div>
    }
}

class Filters extends Component {
    state = { active: {} }

    render() {
        if(!this.props.open) return <div></div>

        return <StyledFilters>
            <div className="arrow" />
            {this.props.filters.map((filter, index) => {
                if(filter.type === "select") return <FilterSelect value={this.state.active[index] || null} key={index} items={filter.items} label={filter.label} onActiveChange={val => {
                    let active = this.state.active
                    active[index] = val
                    this.setState({ active }, () => this.props.callback(this.state.active))
                }} />

                return null
            })}
        </StyledFilters>
    }
}

Table.DisplayUser = (name, url) => <DisplayUser>{url !== "data:image/jpg;base64,null" && <ProfilePicture src={url} />}{url === "data:image/jpg;base64,null" && <Avatar size="32" round style={{ marginRight: 8 }} name={name} className="avatar" />}{name}</DisplayUser>

export default Table
