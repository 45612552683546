import React, { Component } from "react"
import CreateExpenseTypeModal from "./modals/CreateExpenseTypeModal"
import DeleteExpenseTypeModal from "./modals/DeleteExpenseTypeModal"
import UpdateExpenseTypeModal from "./modals/UpdateExpenseTypeModal"
import { GridElement, Table, Button } from "scanmetrix-components"

export default class extends Component {
    render() {
        return <>
            <CreateExpenseTypeModal instance={ref => this.createExpenseTypeModal = ref} refresh={() => this.refreshExpenseTypes()} />
            <DeleteExpenseTypeModal instance={ref => this.deleteExpenseTypeModal = ref} refresh={() => this.refreshExpenseTypes()} />
            <UpdateExpenseTypeModal instance={ref => this.updateExpenseTypeModal = ref} refresh={() => this.refreshExpenseTypes()} />
            <GridElement styled title="Ausgabearten" icon="wallet" rightContent={<Button icon="wallet" title="Ausgabeart anlegen" thick primary onClick={() => this.createExpenseTypeModal.open()} />}>
                <Table
                    refresh={refresh => this.refreshExpenseTypes = refresh}
                    head={[
                        { title: "Nummer", width: "7.5%", column: "number", orderField: "number" },
                        { title: "Name", width: "47.5%", column: "name", orderField: "name" },
                        { title: "Typ", width: "25%", column: "typeFormatted", orderField: "type" },
                        { title: "Bearbeitet", width: "10%", column: "updated_at", orderField: "updated_at" },
                        { title: "Erstellt", width: "10%", column: "created_at", orderField: "created_at" },
                    ]}
                    contextItems={[
                        { title: "Ausgabeart löschen", icon: "trash", onClick: item => this.deleteExpenseTypeModal.open(item.id)}
                    ]}
                    metaCallback={this.props.metaCallback}
                    query={`${scanmetrix.nestURL}/v2/expense-type`}
                    defaultSort="created_at_DESC"
                    onItemClick={expenseType => this.updateExpenseTypeModal.open(expenseType)}
                    map={expenseTypes => expenseTypes.map(expenseType => ({
                        ...expenseType,
                        typeFormatted: expenseType.type === "CAPEX" ? <><i className="fa-duotone fa-money-bills" style={{ color: "#3b97d3" }} /> CapEx - Capital Expenditures</> : <><i className="fa-duotone fa-coin" style={{ color: "#3b97d3" }} /> OpEx - Operational Expenditures</>,
                        updated_at: moment(expenseType.updated_at).fromNow(),
                        created_at: moment(expenseType.created_at).fromNow(),
                    }))}
                />
            </GridElement>
        </>
    }
}
