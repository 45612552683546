import React, { Component } from "react"
import { Breadcrumbs, Page, Section } from "scanmetrix-components"
import i18next from "i18next"
import ExpenseTypes from "./tabs/expenseTypes"
import BudgetCategories from "./tabs/budgetCategories"
import BudgetTemplates from "./tabs/budgetTemplates"
import BudgetPools from "./tabs/budgetPools"

export default class extends Component {
    state = {
        expenseTypeAmount: 0,
        budgetCategoryAmount: 0,
        budgetPoolAmount: 0,
        budgetTemplateAmount: 0
    }

    render() {
        return <Page {...this.props}>
            <Breadcrumbs values={[
                {
                    icon: "piggy-bank",
                    title: i18next.t("page.private.budget.breadCrumbsTitle")
                }
            ]} />
            <Section bodyPadding="32px 0 0 0">
                <Section.Item title="Budget Pools" icon="chart-pie-simple-circle-dollar" amount={this.state.budgetPoolAmount}>
                    <BudgetPools history={this.props.history} metaCallback={meta => this.setState({ budgetPoolAmount: meta.total })} />
                </Section.Item>
                <Section.Item mount title="Budget Vorlagen" icon="filter-circle-dollar" amount={this.state.budgetTemplateAmount}>
                    <BudgetTemplates metaCallback={meta => this.setState({ budgetTemplateAmount: meta.total })} />
                </Section.Item>
                <Section.Item mount title="Budget Kategorien" icon="piggy-bank" amount={this.state.budgetCategoryAmount}>
                    <BudgetCategories metaCallback={meta => this.setState({ budgetCategoryAmount: meta.total })} />
                </Section.Item>
                <Section.Item mount title="Ausgabearten" icon="wallet" amount={this.state.expenseTypeAmount}>
                    <ExpenseTypes metaCallback={meta => this.setState({ expenseTypeAmount: meta.total })} />
                </Section.Item>
            </Section>
        </Page>
    }
}
