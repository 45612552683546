import React, { Component } from "react"
import styled from "styled-components"
import OutsideClickHandler from "react-outside-click-handler"
import * as JsSearch from "js-search"

import Input from "../Input/"
import {ClipLoader} from "react-spinners"
import { withRouter } from "react-router-dom"

import i18next from "i18next"

const SelectContainer = styled.div`
  height: 64px;
  position: relative;
  display: ${props => props.adjustWidth ? "block" : "inline-block"};
  width: ${props => props.adjustWidth ? "100%" : "auto"};
  font-size: 1.1em;
  margin: ${props => props.margin || 0};
  z-index: ${props => props.zIndex ? 30 : 0};
opacity: ${props => props.disabled ? 0.5 : 1};
pointer-events: ${props => props.disabled ? "none" : "all"};
    
  &:after {
    opacity: ${props => props.gradientVisible ? 1 : 0};
    display: block;
    position: absolute;
    content: " ";
    height: 47px;
    width: 64px;
    right: 0;
    bottom: 1px;
    background: linear-gradient(90deg,rgba(255, 255, 255, 0),white,#ffffff);
    z-index: 4;
    transition: opacity 0.3s;
  }
`

const SelectLabel = styled.label`
  position: absolute;
  z-index: 7;
  line-height: ${props => props.isFocused ? "16px" : "80px"};
  opacity: ${props => props.isFocused ? 1 : 0.5};
  font-size: ${props => props.isFocused ? "0.8em" : "0.9em"};
  transition: line-height 0.3s, opacity 0.3s, font-size 0.3s;
  user-select: none;
  pointer-events: none;
  width: calc(100% - 20px);
  display: flex;
  overflow: hidden;
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SelectIcon = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  line-height: 48px;
  z-index: 6;
  color: #20242b;
  transition: opacity 0.3s;
  opacity: ${props => props.value ? 1 : 0.5};
`

const Select = styled.input`
  outline: 0;
  border: none;
  padding-right: 24px;
  background: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: 64px;
  box-sizing: border-box;
  padding-top: 16px;
  transition: border-color 0.3s;
  z-index: 7;
  width: 100%;
  font-size: 0.95em;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  
  &::placeholder {
    opacity: ${props => props.isFocused ? 0.75 : 0};
    transition: opacity 0.5s;
  }
  
  &:focus {
    border-color: #20242b;
  }
`

const SelectDropdown = styled.div`
  position: absolute;
  top: ${props => props.upwards ? "calc(-216px)" : 0};
  width: 100%;
  margin-left: -16px;
  margin-top: -16px;
  background: white;
  box-shadow: 0 0 12px -2px rgba(32,36,43,0.4);
  border-radius: 5px;
  padding: ${props => props.upwards ? "16px 16px 96px 16px" : "96px 16px 16px 16px"};
  z-index: 6;
  transition: all 0.3s;
  visibility: ${props => props.visible ? "visible" : "hidden"};
  opacity: ${props => props.visible ? 1 : 0};
  
  .noItems {
      margin-left: 16px;
      font-size: 14px;
      user-select: none;
    
      i {
          margin-right: 4px;
      }
  }
  
  ul {
    max-height: 200px;
    overflow-y: scroll;
    padding-right: 16px;
    
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.05);
    }
      
    &::-webkit-scrollbar-thumb {
      background: rgb(32, 36, 43);
    }
      
    &::-webkit-scrollbar {
      padding-left: 16px;
    }
      
    li {
      list-style-type: none;
      height: 40px;
      line-height: 40px;
      font-size: 0.9em;
      cursor: pointer;
      padding-left: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      
      &.selected {
        font-weight: bold;
      }
      
      i {
        width: 24px;
      }
      
      &:nth-child(odd) {
        background: rgba(0, 0, 0, 0.025);
      }
      
      &.remove {
        background: white;
        font-size: 0.8em;
        height: 32px;
        line-height: 32px;
        margin-bottom: 8px;
        color: #ff4e4e;
        
        &:hover {
          opacity: 0.75;
          background: white;
          color: #ff4e4e;
        }
      }
      
      &:hover {
        background: rgb(32, 36, 43);
        color: white;
      }
    }
  }
`


const Required = styled.div`
    color: #3b97d3;
    margin-left: 8px;
    font-size: 0.6em;
`

const ExLink = styled.i`
    cursor: pointer !important;
    z-index: 50;
    left: 0;
    top: -2px;
    user-select: all;
    position: absolute;
    font-size: 12px;
    background: #20242b;
    color: white;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    transition: opacity 0.3s;
  
    &:hover {
        opacity: 0.5;
    }
`

const SelectClass = withRouter(class extends Component {
    state = { search: null, zIndex: false, value: null, items: [], isFocused: false, saving: false, saved: false, error: false }

    constructor(props) {
        super(props)
        if(props.items) this.state.items = props.items

        let initialValue = props.value

        console.log(initialValue)

        const mutationContext = props.mutationContext
        const postContext = props.postContext

        if(mutationContext) {
            if(!mutationContext.listeners) mutationContext.listeners = []

            if(props.contextualValue) {
                mutationContext.listeners.push(() => {
                    this.forceUpdate()
                })
            }

            if(mutationContext.data && props.field) {
                const data = mutationContext.data
                const fieldValue = data[props.field]

                if(props.date) {
                    if(fieldValue) initialValue = moment(fieldValue).format("DD.MM.YYYY")
                    else initialValue = null
                } else {
                    if(props.field.includes(".")) {
                        const structure = props.field.split(".")
                        let tempValue = data

                        structure.forEach(key => {
                            if(tempValue === undefined) tempValue = null
                            else if(tempValue !== null) tempValue = tempValue[key]
                        })

                        initialValue = tempValue
                    } else initialValue = fieldValue === undefined ? null : fieldValue
                }
            }
        } else if(postContext) {
            if(!postContext.listeners) postContext.listeners = []

            if(props.contextualValue) {
                postContext.listeners.push(() => {
                    this.forceUpdate()
                })
            }

            if(postContext.data && props.field) {
                const data = postContext.data
                const fieldValue = data[props.field]

                if(props.date) {
                    if(fieldValue) initialValue = moment(fieldValue).format("DD.MM.YYYY")
                    else initialValue = null
                } else {
                    if(props.field.includes(".")) {
                        const structure = props.field.split(".")
                        let tempValue = data

                        structure.forEach(key => {
                            if(tempValue === undefined) tempValue = null
                            else if(tempValue !== null) tempValue = tempValue[key]
                        })

                        initialValue = tempValue
                    } else initialValue = fieldValue === undefined ? null : fieldValue
                }
            }
        }

        if(initialValue) this.state.value = initialValue

        this.savedValue = this.state.value

        this.fetch = this.fetch.bind(this)

        if(props.fetch) this.fetch()
        if(props.refresh && props.fetch) props.refresh(this.fetch)
    }

    fetch() {
        if(!this.props.fetch) return false

        fetch(`${scanmetrix.nestURL}${this.props.fetch.url}?${new URLSearchParams({
            search: this.state.search || "",
            perPage: 100,
            orderBy: "id_DESC",
            ...(this.props.fetch.queryParams || {})
        }).toString()}`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(async result => {
            if(result.status === 200) {
                const json = await result.json()

                const items = json.data.map(item => this.props.fetch.map(item))

                console.log(items)

                this.setState({ items })
            }
        })
    }

    mutate(mutationContext, value) {
        this.setState({ saving: true })

        const fieldString = this.props.field
        const parts = fieldString.split(".").reverse()

        let lastObj = {}

        parts.forEach((part, index) => {
            lastObj = { [part]: index === 0 ? ("$" + this.props.field.replace(/\./g, "_")) : lastObj }
        })

        const str = JSON.stringify(lastObj)
        const fieldStringParsed = str.substring(1, str.length - 1).replace(/"/g, "")

        scanmetrix.client.mutate({
            mutation: scanmetrix.gql`mutation($id: ID!, $${this.props.field.replace(/\./g, "_")}: ${this.props.field.endsWith(".id") ? "ID" : "String"}) {
            ${mutationContext.name}(id: $id, ${fieldStringParsed})
            }`,
            variables: {
                id: mutationContext.id,
                [this.props.field.replace(/\./g, "_")]: value
            }
        }).then(data => {
            if(data.data[mutationContext.name]) {
                if(mutationContext.onSave) mutationContext.onSave()

                this.setState({ saving: false, saved: true, error: false })

                this.savedValue = value

                const updateAt = (object, path, value) => {
                    let o = object

                    for(let i = 0; i < path.length; i++) {
                        if(i < path.length - 1) {
                            if(!o[path[i]]) o[path[i]] = {}
                            o = o[path[i]]
                        }
                        else o[path[i]] = value
                    }
                }

                if(value === null && this.props.field.endsWith(".id")) mutationContext.data[this.props.field.split(".")[0]] = null
                else updateAt(mutationContext.data, this.props.field.split("."), value)

                const list = mutationContext.listeners || []

                list.forEach(listener => listener())

                setTimeout(() => this.setState({ saved: false }), 1000)
            } else {
                this.setState({ saving: false, saved: false, error: true })
            }
        })
    }

    post(postContext, value) {
        this.setState({ saving: true })

        fetch(`${scanmetrix.nestURL}${postContext.url}`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                [this.props.field]: value
            })
        }).then(result => {
            if(result.status === 201) {
                if(postContext.onSave) postContext.onSave()

                this.setState({ saving: false, saved: true, error: false })

                this.savedValue = value

                const list = postContext.listeners || []

                list.forEach(listener => listener())

                setTimeout(() => this.setState({ saved: false }), 1000)
            } else {
                this.setState({ saving: false, saved: false, error: true })
            }
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let next = null

        if(nextProps.items) {
            if(!(prevState.items.length === nextProps.items.length && prevState.items.every((value, index) => value === nextProps.items[index]))) {
                next = { items: nextProps.items }
            }
        }

        if(nextProps.value || nextProps.value === null) {
            if(nextProps.value !== prevState.value) {
                next = next ? { ...next, value: nextProps.value } : { value: nextProps.value }
            }
        }

        return next
    }

    render() {
        let search = new JsSearch.Search("key")

        if(!this.props.fetch) {
            search.addDocuments(this.state.items)
            search.addIndex("title")
        }

        const items = (this.props.fetch ? this.state.items : (this.state.search ? search.search(this.state.search) : this.state.items))

        const mutationContext = this.props.mutationContext
        const postContext = this.props.postContext

        let value = null

        if(this.props.contextualValue) {
            if(mutationContext) {
                value = this.props.contextualValue(mutationContext.data)
            } else if(postContext) {
                value = this.props.contextualValue(postContext.data)
            }
        } else {
            value = this.state.value
        }

        return <OutsideClickHandler onOutsideClick={() => {
            if(this.state.isFocused) {
                this.setState({ isFocused: false, search: null })

                setTimeout(() => this.setState({ zIndex: false }), 300)
            }
        }}>
            <SelectContainer zIndex={this.state.zIndex} gradientVisible={!this.state.isFocused} adjustWidth={this.props.adjustWidth} margin={this.props.margin} disabled={this.props.disabled}>
                {value && this.props.link && <ExLink className="far fa-link" onClick={() => this.props.history.push(this.props.link(value))} />}
                {this.props.label && <SelectLabel style={{ paddingLeft: (value && this.props.link) ? 24 : 0 }} isFocused={this.state.isFocused || (value !== null && value !== undefined)}>{this.props.label}{this.props.required && <Required><i className="far fa-asterisk" /></Required>}</SelectLabel>}
                <SelectDropdown upwards={this.props.upwards} visible={this.state.isFocused} onClick={e => e.stopPropagation()}>
                    <ul>
                        {!this.props.noUnselect && this.state.zIndex && <li className="remove" onClick={() => {
                            if(this.props.field) {
                                if(mutationContext) {
                                    this.mutate(mutationContext, null)
                                } else if(postContext) {
                                    this.post(postContext, null)
                                }
                            }

                            this.setState({ value: null, search: null, isFocused: false }, () => {
                                if(this.props.onChange) this.props.onChange(null)
                            })

                            setTimeout(() => this.setState({ zIndex: false }), 300)
                        }}>
                            <i className="far fa-trash" />
                            {i18next.t("component.select_gewerke_dropdown.removeSelection")}
                        </li>}
                        {items.map((item, index) => <li key={index} className={String(value) === String(item.key) ? "selected" : ""} onClick={() => {
                            if(this.props.field && item.key !== this.savedValue) {
                                if(mutationContext) {
                                    this.mutate(mutationContext, item.key)
                                } else if(postContext) {
                                    this.post(postContext, item.key)
                                }
                            }

                            this.setState({ value: item.key, search: null, isFocused: false }, () => {
                                if(this.props.onChange) this.props.onChange(item.key, item)
                            })

                            setTimeout(() => this.setState({ zIndex: false }), 300)
                        }}>
                            {item.icon && <i className={`far fa-${item.icon}`} />}
                            {item.title}
                        </li>)}
                    </ul>
                    {items.length === 0 && <div className="noItems">
                        <i className="far fa-binoculars" /> {i18next.t("component.select_gewerke_dropdown.noResult")}
                    </div>}
                </SelectDropdown>
                <Select
                    autoComplete="false"
                    autoCorrect="false"
                    placeholder={i18next.t("component.select_gewerke_dropdown.searchPlaceholder")}
                    autoCapitalize="false"
                    isFocused={this.state.isFocused}
                    spellCheck="false"
                    readOnly={!!this.props.readOnly || !!this.props.disabled}
                    onFocus={() => {
                        if(!this.props.readOnly && !this.props.disabled && !this.state.saved && !this.state.saving) this.setState({ isFocused: true, zIndex: true })
                    }}
                    value={this.state.isFocused ? (this.state.search || "") : ((this.state.items.find(item => String(item.key) === String(value)) || {}).title || "")}
                    onChange={value => {
                        this.setState({ search: value.target.value || null }, this.fetch)
                    }}
                />
                {this.props.icon && !this.state.saved && !this.state.error && !this.state.saving && <SelectIcon value={value}><i className={`far fa-${this.props.icon}`} /></SelectIcon>}
                {this.state.saved && <SelectIcon value={true}><i style={{ color: "#3b97d3" }} className="far fa-check-circle" /></SelectIcon>}
                {this.state.error && <SelectIcon value={true}><i style={{ color: "#e74c3c" }} className="fas fa-exclamation-circle" /></SelectIcon>}
                {this.state.saving && !this.state.error && !this.state.saved && <div style={{ position: "absolute", zIndex: 15, right: 0, bottom: 14 }}>
                    <ClipLoader color="#3b97d3" size={12} />
                </div>}
            </SelectContainer>
        </OutsideClickHandler>
    }
})

class SelectClassWrap extends Component {
    render() {
        return <Input.MutationConsumer>
            {mutationContext => <Input.PostConsumer>
                {postContext => <SelectClass {...this.props} mutationContext={mutationContext} postContext={postContext} /> }
            </Input.PostConsumer> }
        </Input.MutationConsumer>
    }
}

export default SelectClassWrap
