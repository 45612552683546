import React, { Component } from "react"
import { Page, Breadcrumbs, GridElement, Section, Input, Button, TextEditor, Table, Select } from "scanmetrix-components"
import styled from "styled-components"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import DeletePurchaseOrderModal from "./modals/DeletePurchaseOrderModal";
import UpdatePurchaseOrderModal from "./modals/UpdatePurchaseOrderModal";
import PDFModal from "./modals/PDFModal"
import i18next from "i18next";
import { v4 as uuid } from "uuid"
import ApprovePurchaseOrderModal from "./modals/ApprovePurchaseOrderModal"
import CommissionPurchaseOrderModal from "./modals/CommissionPurchaseOrderModal";
import ConfirmPurchaseOrderModal from "./modals/ConfirmPurchaseOrderModal";
import {getNotices} from "../accounting/tabs/modals/FactualClearanceModal";
import {FileManager} from "../documents";
import PurchaseOrderEmailModal from "./modals/PurchaseOrderEmailModal";
import CFDSModal from "./modals/CFDSModal";

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})


const Status = styled.div`
    position: absolute;
    left: -16px;
    width: 32px;
    height: 32px;
    background: ${props => props.yellow ? "#e67e22" : (!!props.status ? "#27ae60" : (props.status === false ? "#c0392b" : "#2c3e50"))};
    border-radius: 32px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(50% - 16px);
    font-size: 0.9em;
    box-shadow: 0 3px 4px -2px rgba(0, 0, 0, 0.3);

    i {
        margin: 0 !important;
    }
`


const ObjectBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  padding-top: 32px;

  .button-view {
    display: grid;
    grid-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: auto;
    margin-bottom: 32px;
    position: absolute;
    right: 0;
    top: -24px;

    .button {
      background: linear-gradient(45deg, #3B97D3, #81C6F3);
      box-shadow: 0 2px 8px -3px #3B97D3;
      padding: 10px 15px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      box-sizing: border-box;
      user-select: none;
      cursor: pointer;
      transition: all 250ms ease;


      &:hover {
        opacity: 0.75;
      }

      i {
        color: white;
        margin-right: 8px;
        font-size: 1.125em;
      }

      p {
        font-size: 1.125em;
        color: white;
      }
    }
  }

  .body-view {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 32px;

    .box {
      width: 100%;
      height: 100%;
      padding: 16px;
      background: white;
      border-radius: 5px;
      box-shadow: 0 8px 24px -16px rgba(0, 0, 0, .4);
      box-sizing: border-box;
      max-height: 720px;

      .scroll {
          max-height: 648px;
          overflow-y: scroll;
      }
    }

    .status-box {
      background: linear-gradient(45deg,#C0392B,#FF6252);
      grid-column-start: 1;
      grid-column-end: 2;
      display: flex;
      flex-direction: row;
      align-items: center;
      user-select: none;

      i {
        font-size: 2.5em;
        color: white;
        margin-right: 16px;
      }

      .value {
        color: white;
        font-size: 1.75em;
      }
    }

    .input-field {
      width: 100%;
      padding: 16px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      grid-column-start: 1;
      grid-column-end: 2;

      .header {
        width: 50%;
        display: inline-block;
        margin-bottom: 32px;
        border-bottom: 1px solid rgba(0, 0, 0, .25);
        user-select: none;

        p {
          display: inline-block;
          color: rgba(0, 0, 0, .75);
          padding-bottom: 10px;
          font-size: 0.85em;
        }
      }

      .column-view {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .input-view {
          width: 100%;
          position: relative;

          input {
            color: #20242B;
            width: 100%;
            padding: 10px 30px 10px 5px;
            border: none;
            border-bottom: 2px solid rgba(0, 0, 0, .25);
            outline: none;
            box-shadow: none;
            background: transparent;
            box-sizing: border-box;
            transition: all 250ms ease;
            font-size: 1.125em;
            line-height: 20px;

            &:focus {
              border-bottom: 2px solid #3B97D3;
            }
          }

          i {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 10px;
            right: 10px;
            font-size: 1.125em;
          }
        }
      }
    }

    .table {
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 2;
      grid-column-end: 3;
      height: fit-content;
      box-shadow: none;
      padding: 0;
      background: transparent;
    }

    @media screen and (max-width: 1280px) {
      grid-template-columns: 1fr;
      grid-template-rows: 64px auto auto;

      .status-box {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1;
      }

      .input-field {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 2;
        grid-row-end: 2;
      }

      .table {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 3;
        grid-row-end: 3;
      }
    }
  }
`

const getItems = count =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k}`,
        content: `item ${k}`
    }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, even) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,

    // change background colour if dragging
    background: isDragging ? "rgba(0, 0, 0, 0.05)" : "white",
    borderRadius: "5px",
    marginBottom: 16,
    marginRight: 16,
    boxShadow: "0 2px 6px -2px rgba(0, 0, 0, 0.25)",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = () => ({
    width: "100%"
})

const ItemBody = styled.div`
    display: grid;
    grid-template-columns: 12px 1.5fr 3fr 0.5fr 1fr 0.5fr 0.5fr 1.5fr 32px;
    grid-gap: 16px;

    input {
        outline: 0;
        padding: 8px;
        border: 1px solid rgba(0, 0, 0, 0.35);
        border-radius: 4px;
        min-width: 64px;
    }

    .inputContainer {
        position: relative;
        width: 100px;

        input {
            box-sizing: border-box;
            width: 100px;
        }

        i {
            position: absolute;
            right: 8px;
            color: #3b97d3;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .id {
        font-weight: bold;
    }

    .total {
        font-weight: bold;
        color: #3b97d3;
    }

    .total .fa-equals {
        margin-right: 8px;
    }

    .price, .total, .amount, .id {
        display: flex;
        align-items: center;
    }

    .amount {
        justify-content: center;
        text-align: center;
    }

    .price, .total {
        text-align: right;
        justify-content: flex-end;
    }
`

const Total = styled.div`
  user-select: none;
  box-sizing: border-box;

  p:last-child {
      margin-top: 8px;
  }

  b {
      color: #3b97d3;
  }
`

const ProgressSteps = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 32px;
    margin-bottom: 32px;
`

const ProgressStep = styled.div`
    background: white;
    border-radius: 5px;
    box-shadow: 0 8px 24px -16px rgba(0,0,0,.4);
    box-sizing: border-box;
    padding: 16px;
    position: relative;
    user-select: none;
    border: 2px solid white;

    .doneAt {
        color: #27ae60;
        font-weight: bold;

        i {
            margin-right: 8px;
        }
    }

  .warning {
    color: #e67e22;
    font-weight: bold;

    i {
      margin-right: 8px;
    }
  }

    .download {
        color: #3b97d3;
        font-size: 0.8em;
        margin-top: 8px;
        display: block;
    }

    &.active {
        border-color: #3b97d3;
    }

    &.done {
        border-color: #27ae60;
    }

    .title {
        font-size: 1.4em;
        margin-bottom: 16px;
    }

    .next {
        position: absolute;
        right: -26px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        background: #20242b;
        color: white;
        font-size: 0.8em;
    }

    .button {
        background: linear-gradient(30deg,#0f7abf,#58b9f9);
        font-size: 0.9em;
        color: white;
        display: inline-block;
        padding: 8px 12px;
        border-radius: 5px;
        box-shadow: 0 0 6px 0 #1a699c66;
        cursor: pointer;
        transition: opacity 0.3s;

      &.secondary {
          background: #20242b;
          box-shadow: 0 2px 8px -3px #20242b;
      }

        &.disabled {
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.5 !important;
            filter: grayscale(100%);
            box-shadow: none;
        }

        &:hover {
            opacity: 0.75;
        }

        i {
            margin-right: 8px;
        }
    }

    .badge {
        position: absolute;
        right: -8px;
        top: -8px;
        color: white;
        background: #3b97d3;
        padding: 4px 8px;
        font-size: 0.8em;
        border-radius: 5px;

        &.gray {
            background: gray;
        }

        &.green {
            background: #27ae60;
        }
    }
`

const StyledTrash = styled.div`
    width: 32px;
    height: 32px;
    cursor: pointer;
    user-select: none;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s, background 0.3s;

    &:hover {
        background: rgba(0, 0, 0, 0.075);
        color: #3b97d3;
    }
`

export default class extends Component {
    render() {
        return <Page {...this.props}>
            <PurchaseOrderPage {...this.props} />
        </Page>
    }
}

class PurchaseOrderPage extends Component {
    state = { purchaseOrder: null, positions: [], confirmationUploaded: false, loading: false, accountingInvoices: null }

    constructor(props) {
        super(props);

        this.onDragEnd = this.onDragEnd.bind(this);

        this.fetch = this.fetch.bind(this)
        this.updatePositions = this.updatePositions.bind(this)
        this.updateWithCooldown = this.updateWithCooldown.bind(this)

        this.fetch()
    }

    fetch() {
        fetch(scanmetrix.backendURL + "/purchaseorder-confirmation/pdf/" + this.props.match.params.purchaseorderid, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(data => {
            this.setState({ confirmationUploaded: data.status === 200 })
        })

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!, $status: String!) {
                    AccountingInvoices(filter: { status_eq: $status, purchaseOrderId_eq: $id }) {
                        nodes {
                            id
                            number
                            date
                            net
                            gross
                            notes
                            notesAccounting
                            declined
                            status
                            accountingInvoiceDocument {
                                id
                                name
                            }
                            serviceProvider {
                                id
                                name
                            }
                            purchaseOrder {
                                id
                                number
                                date
                                positions
                            }
                            subsidiary {
                                id
                                name
                                label
                            }
                        }
                    }
                    PurchaseOrder(id: $id) {
                        id
                        title
                        number
                        date
                        type
                        positions
                        headerText
                        footerText
                        reference
                        status
                        cfds
                        finishedAt
                        approvedAt
                        confirmedAt
                        commissionedAt
                        budgetCategory {
                            id
                            name
                        }
                        measure {
                            id
                            title
                            number
                        }
                        letterHead {
                            id
                            name
                        }
                        serviceProvider {
                            id
                            name
                            globalId
                            contactEmail
                        }
                        subsidiary {
                            id
                            name
                            label
                            legalName
                            address {
                                postalCode
                                city
                            }
                        }
                    }
                }
            `,
            variables: {
                id: this.props.match.params.purchaseorderid,
                status: "factual_clearance"
            }
        }).then(data => {
            this.setState({ accountingInvoices: data.data.AccountingInvoices.nodes, purchaseOrder: data.data.PurchaseOrder, positions: (typeof data.data.PurchaseOrder.positions === "string") ? JSON.parse(data.data.PurchaseOrder.positions) : data.data.PurchaseOrder.positions })
        })
    }

    onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        const positions = reorder(
            this.state.positions,
            result.source.index,
            result.destination.index
        );

        this.setState({
            positions
        }, this.updatePositions)
    }

    updatePositions() {
        this.setState({ loading: true })

        scanmetrix.client.mutate({
            mutation: scanmetrix.gql`
                mutation($id: ID!, $positions: JSON!) {
                    updatePurchaseOrderPositions(id: $id, positions: $positions)
                }
            `,
            variables: {
                id: this.state.purchaseOrder.id,
                positions: (this.state.positions || []).map(position => ({
                    ...position,
                    amount: isNaN(parseFloat(position.amount, 10)) ? 0 : parseFloat(position.amount, 10),
                    price: isNaN(parseFloat(position.price, 10)) ? 0 : parseFloat(position.price, 10),
                    taxRate: isNaN(parseFloat(position.taxRate, 10)) ? 0 : parseFloat(position.taxRate, 10)
                }))
            }
        }).then(data => {
            if(data.data.updatePurchaseOrderPositions) this.setState({ loading: false })
        })
    }

    updateWithCooldown() {
        if(this.cooldown) {
            clearTimeout(this.cooldown)
        }

        this.cooldown = setTimeout(() => {
            this.updatePositions()

            this.cooldown = null
        }, 750)
    }

    render() {
        const purchaseOrder = this.state.purchaseOrder

        if(!purchaseOrder) return null

        return <>
            <CFDSModal refresh={this.fetch} purchaseOrderId={purchaseOrder.id} instance={ref => this.cfdsModal = ref} />
            <DeletePurchaseOrderModal history={this.props.history} purchaseOrderId={purchaseOrder.id} instance={ref => this.deletePurchaseOrderModal = ref} />
            <UpdatePurchaseOrderModal refresh={this.fetch} purchaseOrder={purchaseOrder} instance={ref => this.updatePurchaseOrderModal = ref} />
            <ApprovePurchaseOrderModal refresh={this.fetch} purchaseOrderId={purchaseOrder.id} instance={ref => this.approvePurchaseOrderModal = ref} />
            <CommissionPurchaseOrderModal refresh={this.fetch} purchaseOrderId={purchaseOrder.id} instance={ref => this.commissionPurchaseOrderModal = ref} sendEmail={() => this.purchaseOrderEmailModal.open()} cfds={() => this.cfdsModal.open()} />
            <ConfirmPurchaseOrderModal refresh={this.fetch} purchaseOrderId={purchaseOrder.id} instance={ref => this.confirmPurchaseOrderModal = ref} />
            <PurchaseOrderEmailModal purchaseOrder={purchaseOrder} refresh={this.fetch} purchaseOrderId={purchaseOrder.id} instance={ref => this.purchaseOrderEmailModal = ref} />
            <PDFModal instance={ref => this.pdfModal = ref} purchaseOrderId={purchaseOrder.id} />
            <Breadcrumbs values={[
                {
                    icon: "comment-alt-check",
                    title: i18next.t("page.private.purchaseOrder.breadCrumbsTitle"),
                    link: "/purchaseorders"
                },
                {
                    icon: "file-signature",
                    title: purchaseOrder.number
                }
            ]} />
            <Section>
                <Section.Item title={i18next.t("page.private.purchaseOrder.orders.sectionElementTitle")} icon="comment-alt-check">
                    <ObjectBody>
                        <ProgressSteps>
                            <ProgressStep className={purchaseOrder.status === "draft" ? "active" : ""}>
                                {purchaseOrder.status === "draft" && <div className="badge">{i18next.t("page.private.purchaseOrder.orders.draft.currentStatus")}</div>}
                                <p className="title">{i18next.t("page.private.purchaseOrder.orders.draft.titleDraft")}</p>
                                {purchaseOrder.status === "draft" && <div className={`button ${scanmetrix.checkPermission("PurchaseOrder") < 3 ? "disabled" : ""}`} onClick={() => {
                                    scanmetrix.client.mutate({
                                        mutation: scanmetrix.gql`
                                            mutation($id: ID!, $status: String!) {
                                                updatePurchaseOrderStatus(id: $id, status: $status)
                                            }
                                        `,
                                        variables: {
                                            id: purchaseOrder.id,
                                            status: "approval"
                                        }
                                    }).then(() => this.fetch())
                                }}><i className="far fa-lock" />{i18next.t("page.private.purchaseOrder.orders.draft.finish")}</div>}
                                {purchaseOrder.status !== "draft" && <div className={"button secondary " + ((purchaseOrder.status === "done" || scanmetrix.checkPermission("PurchaseOrder")) ? "disabled" : "")} onClick={() => {
                                    scanmetrix.client.mutate({
                                        mutation: scanmetrix.gql`
                                            mutation($id: ID!, $status: String!) {
                                                updatePurchaseOrderStatus(id: $id, status: $status)
                                            }
                                        `,
                                        variables: {
                                            id: purchaseOrder.id,
                                            status: "draft"
                                        }
                                    }).then(() => this.fetch())
                                }}><i className="far fa-lock-open" />{i18next.t("page.private.purchaseOrder.orders.draft.resetDraft")}</div>}
                                <div className="next"><i className="far fa-arrow-right" /></div>
                            </ProgressStep>
                            <ProgressStep className={purchaseOrder.status === "approval" ? "active" : ""}>
                                {purchaseOrder.status === "approval" && <div className="badge">{i18next.t("page.private.purchaseOrder.orders.internalRelease.currentStatus")}</div>}
                                <p className="title">{i18next.t("page.private.purchaseOrder.orders.internalRelease.titleInternalRelease")}</p>
                                {!(purchaseOrder.status === "execution" || purchaseOrder.status === "done" || purchaseOrder.status === "confirmation" || purchaseOrder.status === "commission") && scanmetrix.checkPermission("PurchaseOrderApproval") >= 2 && <div onClick={() => this.approvePurchaseOrderModal.open()} className={"button " + ((purchaseOrder.status !== "approval") ? "disabled" : "")}><i className="far fa-check" />{i18next.t("page.private.purchaseOrder.orders.internalRelease.release")}</div>}
                                {!(purchaseOrder.status === "execution" || purchaseOrder.status === "done" || purchaseOrder.status === "confirmation" || purchaseOrder.status === "commission") && scanmetrix.checkPermission("PurchaseOrderApproval") < 2 && <p className="warning"><i className="far fa-exclamation-circle" />{i18next.t("page.private.purchaseOrder.orders.internalRelease.noReleaseAuthorization")}</p>}
                                {(purchaseOrder.status === "execution" || purchaseOrder.status === "done" || purchaseOrder.status === "confirmation" || purchaseOrder.status === "commission") && <p className="doneAt"><i className="far fa-check-circle" />Freigegeben am {moment(purchaseOrder.approvedAt).format("DD.MM.YYYY")}</p>}
                                <div className="next"><i className="far fa-arrow-right" /></div>
                            </ProgressStep>
                            <ProgressStep className={purchaseOrder.status === "commission" ? "active" : ""}>
                                {purchaseOrder.status === "commission" && <div className="badge">{i18next.t("page.private.purchaseOrder.orders.commissioning.currentStatus")}</div>}
                                <p className="title">{i18next.t("page.private.purchaseOrder.orders.commissioning.titleCommissioning")}</p>
                                {!(purchaseOrder.status === "execution" || purchaseOrder.status === "done" || purchaseOrder.status === "confirmation") && <div onClick={() => this.commissionPurchaseOrderModal.open()} className={"button " + ((purchaseOrder.status !== "commission" || !scanmetrix.checkPermission("PurchaseOrder")) ? "disabled" : "")}><i className="far fa-paper-plane" />{i18next.t("page.private.purchaseOrder.orders.commissioning.dispatch")}</div>}
                                {(purchaseOrder.status === "execution" || purchaseOrder.status === "done" || purchaseOrder.status === "confirmation") && <p className="doneAt"><i className="far fa-check-circle" />{i18next.t("page.private.purchaseOrder.orders.commissioning.commissionedOn")} {moment(purchaseOrder.commissionedAt).format("DD.MM.YYYY")}{purchaseOrder.cfds ? " (CFDS)" : ""}</p>}
                                <div className="next"><i className="far fa-arrow-right" /></div>
                            </ProgressStep>
                            <ProgressStep className={purchaseOrder.status === "confirmation" ? "active" : ""}>
                                {purchaseOrder.status === "confirmation" && <div className="badge">{i18next.t("page.private.purchaseOrder.orders.orderConfirmation.currentStatus")}</div>}
                                <p className="title">{i18next.t("page.private.purchaseOrder.orders.orderConfirmation.titleOrderConfirmation")}</p>
                                {!(purchaseOrder.status === "execution" || purchaseOrder.status === "done") && <div onClick={() => this.confirmPurchaseOrderModal.open()} className={"button " + ((purchaseOrder.status !== "confirmation" || !scanmetrix.checkPermission("PurchaseOrder")) ? "disabled" : "")}><i className="far fa-spell-check" />{i18next.t("page.private.purchaseOrder.orders.orderConfirmation.confirm")}</div>}
                                {(purchaseOrder.status === "execution" || purchaseOrder.status === "done") && <p className="doneAt"><i className="far fa-check-circle" />{i18next.t("page.private.purchaseOrder.orders.orderConfirmation.confirmedOn")} {moment(purchaseOrder.confirmedAt).format("DD.MM.YYYY")}</p>}
                                {(purchaseOrder.status === "execution" || purchaseOrder.status === "done") && this.state.confirmationUploaded && <a href={`${scanmetrix.backendURL}/purchaseorder-confirmation/pdf/${purchaseOrder.id}`} className="download" target="_blank">{i18next.t("page.private.purchaseOrder.orders.orderConfirmation.downloadOrderConfirmation")}</a>}
                                <div className="next"><i className="far fa-arrow-right" /></div>
                            </ProgressStep>
                            <ProgressStep className={purchaseOrder.status === "execution" ? "active" : (purchaseOrder.status === "done" ? "done" : "")}>
                                {purchaseOrder.status === "execution" && <div className="badge">{i18next.t("page.private.purchaseOrder.orders.implementation.currentStatus")}</div>}
                                {purchaseOrder.status === "done" && <div className="badge green">{i18next.t("page.private.purchaseOrder.orders.implementation.completed")}</div>}
                                <p className="title">{i18next.t("page.private.purchaseOrder.orders.implementation.titleImplementation")}</p>
                                {purchaseOrder.status !== "done" && <div onClick={() => {
                                    scanmetrix.client.mutate({
                                        mutation: scanmetrix.gql`
                                            mutation($id: ID!, $status: String!) {
                                                updatePurchaseOrderStatus(id: $id, status: $status)
                                            }
                                        `,
                                        variables: {
                                            id: purchaseOrder.id,
                                            status: "done"
                                        }
                                    }).then(() => this.fetch())
                                }} className={"button " + ((purchaseOrder.status !== "execution" || !scanmetrix.checkPermission("PurchaseOrder")) ? "disabled" : "")}><i className="far fa-check-double" />{i18next.t("page.private.purchaseOrder.orders.implementation.finalize")}</div>}
                                {purchaseOrder.status === "done" && <p className="doneAt"><i className="far fa-check-circle" />{i18next.t("page.private.purchaseOrder.orders.implementation.completedOn")} {moment(purchaseOrder.finishedAt).format("DD.MM.YYYY")}</p>}
                            </ProgressStep>
                        </ProgressSteps>
                        <div className='button-view'>
                            <Button loading={this.state.loading} secondary thick icon="file-pdf" title={i18next.t("page.private.purchaseOrder.orders.pdfView")} onClick={() => this.pdfModal.open()} />
                            {/*<Button thick icon="paper-plane" title="Versenden" />*/}
                        </div>
                        <div style={{ marginBottom: 32, display: "grid", gridGap: 32, gridTemplateColumns: "1fr 1fr" }}>
                            <GridElement styled title={i18next.t("page.private.purchaseOrder.orders.headerText")} icon="font">
                                <TextEditor readOnly={purchaseOrder.status !== "draft" || scanmetrix.checkPermission("PurchaseOrder") < 3} value={purchaseOrder.headerText} onChange={value => {
                                    scanmetrix.client.mutate({
                                        mutation: scanmetrix.gql`
                                            mutation($id: ID!, $headerText: String!) {
                                                updatePurchaseOrderText(id: $id, headerText: $headerText)
                                            }
                                        `,
                                        variables: {
                                            id: purchaseOrder.id,
                                            headerText: value
                                        }
                                    })
                                }} />
                            </GridElement>
                            <GridElement styled title={i18next.t("page.private.purchaseOrder.orders.footerText")} icon="font">
                                <TextEditor readOnly={purchaseOrder.status !== "draft" || scanmetrix.checkPermission("PurchaseOrder") < 3} value={purchaseOrder.footerText} onChange={value => {
                                    scanmetrix.client.mutate({
                                        mutation: scanmetrix.gql`
                                            mutation($id: ID!, $footerText: String!) {
                                                updatePurchaseOrderText(id: $id, footerText: $footerText)
                                            }
                                        `,
                                        variables: {
                                            id: purchaseOrder.id,
                                            footerText: value
                                        }
                                    })
                                }} />
                            </GridElement>
                        </div>
                        <div className='body-view'>
                            {/*<div className='box status-box'>
                                <i className='far fa-times-circle' />
                                <p className='value'>Offen</p>
                            </div>*/}
                            <GridElement styled icon="database" title={i18next.t("page.private.purchaseOrder.orders.frameworkData.gridElementTitle")} rightContent={<div style={{ display: "grid", gridGap: 16, gridTemplateColumns: "max-content max-content" }}>
                                {purchaseOrder.status === "draft" && <Button secondary icon="trash" thick disabled={scanmetrix.checkPermission("PurchaseOrder") < 4} title={i18next.t("page.private.purchaseOrder.orders.frameworkData.deleteButton")} onClick={() => this.deletePurchaseOrderModal.open()} />}
                                {purchaseOrder.status === "draft" && <Button icon="pencil-alt" thick disabled={scanmetrix.checkPermission("PurchaseOrder") < 3} title={i18next.t("page.private.purchaseOrder.orders.frameworkData.editButton")} onClick={() => this.updatePurchaseOrderModal.open()} />}
                            </div>}>
                                <div style={{ padding: 16, display: "grid", gridTemplateColumns: "100%", gridGap: "16px" }}>
                                    <Input readOnly label={i18next.t("page.private.purchaseOrder.orders.frameworkData.number")} icon="comment-alt-check" value={purchaseOrder.number} />
                                    <Input readOnly margin="16px 0 0 0" label={i18next.t("page.private.purchaseOrder.orders.frameworkData.letterHead")} icon="scroll" value={purchaseOrder.letterHead ? purchaseOrder.letterHead.name : null} />
                                    <Select adjustWidth readOnly margin="16px 0 0 0" value={purchaseOrder.budgetCategory?.id} icon="piggy-bank" label="Budget Kategorie" fetch={{
                                        url: "/v2/budget-category",
                                        map: dataSet => ({ key: dataSet.id, title: `${dataSet.expenseType?.type || "Sonstige"} / ${dataSet.expenseType?.name || "Sonstige"} / ${dataSet.name}` })
                                    }} />
                                    <Input readOnly margin="16px 0 0 0" label={i18next.t("page.private.purchaseOrder.orders.frameworkData.type")} icon={purchaseOrder.type === "service" ? "hard-hat" : (purchaseOrder.type === "construction" ? "digging" : (purchaseOrder.type === "goods" ? "truck-loading" : "question"))} value={i18next.t("page.private.purchaseOrder.type." + purchaseOrder.type)} />
                                    <Input readOnly margin="16px 0 0 0" label={i18next.t("page.private.purchaseOrder.orders.frameworkData.date")} icon="calendar-day" value={moment(purchaseOrder.date).format("DD.MM.YYYY")} />
                                    <Select adjustWidth link={() => purchaseOrder.measure ? `/measure/${purchaseOrder.measure.id}` : null} readOnly margin="16px 0 0 0" label={i18next.t("page.private.purchaseOrder.orders.frameworkData.measure")} icon="network-wired" value={purchaseOrder.measure?.id || null} items={purchaseOrder.measure ? [ { key: purchaseOrder.measure.id, title: `${purchaseOrder.measure.number} / ${purchaseOrder.measure.title}` } ] : []} />
                                    <Input readOnly margin="16px 0 0 0" label={i18next.t("page.private.purchaseOrder.orders.frameworkData.serviceProvider")} icon="user-tie" value={purchaseOrder.serviceProvider.name} />
                                    <Input readOnly margin="16px 0 0 0" label={i18next.t("page.private.purchaseOrder.orders.frameworkData.branch")} icon="map-marker" value={`${purchaseOrder.subsidiary.name} (${purchaseOrder.subsidiary.address.postalCode} ${purchaseOrder.subsidiary.address.city})`} />
                                    <Input readOnly margin="16px 0 0 0" label={i18next.t("page.private.purchaseOrder.orders.frameworkData.title")} icon="font" value={purchaseOrder.title} />
                                    <Input readOnly margin="16px 0 0 0" label={i18next.t("page.private.purchaseOrder.orders.frameworkData.referenz")} icon="shopping-cart" value={purchaseOrder.reference} />
                                </div>
                            </GridElement>
                            <div className='box table'>
                                <div style={{ display: "flex", height: 64, marginBottom: 16, justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                    <Total>
                                        <p>{i18next.t("page.private.purchaseOrder.orders.vatNetto")} <b>{formatter.format((this.state.positions || []).map(item => (parseFloat(item.price, 10) || 0) * (parseFloat(item.amount, 10) || 0)).reduce((a, b) => a + b, 0))}</b></p>
                                        <p>{i18next.t("page.private.purchaseOrder.orders.vatBrutto")} <b>{formatter.format((this.state.positions || []).map(item => (parseFloat(item.price, 10) || 0) * (parseFloat(item.amount, 10) || 0) * (((parseFloat(item.taxRate, 10) || 0) + 100) / 100)).reduce((a, b) => a + b, 0))}</b></p>
                                    </Total>
                                    {purchaseOrder.status === "draft" && <Button disabled={scanmetrix.checkPermission("PurchaseOrder") < 3} thick icon="cart-plus" title={i18next.t("page.private.purchaseOrder.orders.newPositionButton")} onClick={() => {
                                        let positions = this.state.positions

                                        positions.push({
                                            id: uuid(),
                                            articleNumber: null,
                                            title: null,
                                            amount: 1,
                                            price: 0,
                                            taxRate: 19,
                                            unit: "Stück"
                                        })

                                        this.setState({ positions }, this.updatePositions)
                                    }} />}
                                </div>
                                <div className="scroll">
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >
                                                    {(this.state.positions || []).map((item, index) => (
                                                        <Draggable isDragDisabled={purchaseOrder.status !== "draft" || scanmetrix.checkPermission("PurchaseOrder") < 3} key={`item-${item.id}`} draggableId={`item-${item.id}`} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style,
                                                                        index % 2 === 0
                                                                    )}
                                                                >
                                                                    <ItemBody>
                                                                        <p className="id">{index + 1}</p>
                                                                        <input readOnly={purchaseOrder.status !== "draft" || scanmetrix.checkPermission("PurchaseOrder") < 3} autoCorrect="false" spellCheck="false" type="text" placeholder={i18next.t("page.private.purchaseOrder.orders.searchPlaceholder.articleNumber")} value={item.articleNumber || ""} className="articleNumber" onChange={event => {
                                                                            const value = event.target.value

                                                                            this.setState({ positions: (this.state.positions || []).map(position => {
                                                                                    if(position.id === item.id) position.articleNumber = value

                                                                                    return position
                                                                                }) }, this.updateWithCooldown)
                                                                        }} />
                                                                        <input readOnly={purchaseOrder.status !== "draft" || scanmetrix.checkPermission("PurchaseOrder") < 3} autoCorrect="false" spellCheck="false" type="text" value={item.title || ""} className="title" placeholder={i18next.t("page.private.purchaseOrder.orders.searchPlaceholder.positionDescription")} onChange={event => {
                                                                            const value = event.target.value

                                                                            this.setState({ positions: (this.state.positions || []).map(position => {
                                                                                    if(position.id === item.id) position.title = value

                                                                                    return position
                                                                                }) }, this.updateWithCooldown)
                                                                        }} />
                                                                        <input readOnly={purchaseOrder.status !== "draft" || scanmetrix.checkPermission("PurchaseOrder") < 3} autoCorrect="false" spellCheck="false" type="text" value={item.amount === null ? "" : item.amount} className="title" placeholder={i18next.t("page.private.purchaseOrder.orders.searchPlaceholder.amount")} onChange={event => {
                                                                            const value = event.target.value

                                                                            this.setState({ positions: (this.state.positions || []).map(position => {
                                                                                    if(position.id === item.id) position.amount = value.replace(/,/g, ".").replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2")

                                                                                    return position
                                                                                }) }, this.updateWithCooldown)
                                                                        }} />
                                                                        <input readOnly={purchaseOrder.status !== "draft" || scanmetrix.checkPermission("PurchaseOrder") < 3} autoCorrect="false" spellCheck="false" type="text" value={item.unit || ""} className="title" placeholder={i18next.t("page.private.purchaseOrder.orders.searchPlaceholder.unit")} onChange={event => {
                                                                            const value = event.target.value

                                                                            this.setState({ positions: (this.state.positions || []).map(position => {
                                                                                    if(position.id === item.id) position.unit = value

                                                                                    return position
                                                                                }) }, this.updateWithCooldown)
                                                                        }} />
                                                                        <div className="inputContainer">
                                                                            <input readOnly={purchaseOrder.status !== "draft" || scanmetrix.checkPermission("PurchaseOrder") < 3} autoCorrect="false" spellCheck="false" type="text" value={item.price === null ? "" : item.price} className="title" placeholder={i18next.t("page.private.purchaseOrder.orders.searchPlaceholder.price")} onChange={event => {
                                                                                const value = event.target.value

                                                                                this.setState({ positions: (this.state.positions || []).map(position => {
                                                                                        if(position.id === item.id) position.price = value.replace(/,/g, ".").replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2")

                                                                                        return position
                                                                                    }) }, this.updateWithCooldown)
                                                                            }} />
                                                                            <i className="far fa-euro-sign" />
                                                                        </div>
                                                                        <div className="inputContainer">
                                                                            <input readOnly={purchaseOrder.status !== "draft" || scanmetrix.checkPermission("PurchaseOrder") < 3} autoCorrect="false" spellCheck="false" type="text" value={item.taxRate === null ? "" : item.taxRate} className="title" placeholder={i18next.t("page.private.purchaseOrder.orders.searchPlaceholder.taxRate")} onChange={event => {
                                                                                const value = event.target.value

                                                                                this.setState({ positions: (this.state.positions || []).map(position => {
                                                                                        if(position.id === item.id) position.taxRate = value.replace(/,/g, ".").replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2")

                                                                                        return position
                                                                                    }) }, this.updateWithCooldown)
                                                                            }} />
                                                                            <i className="far fa-percentage" />
                                                                        </div>
                                                                        <p className="total"><i className="far fa-equals" /> {formatter.format(item.price * item.amount)}</p>
                                                                        {purchaseOrder.status === "draft" && scanmetrix.checkPermission("PurchaseOrder") >= 4 && <StyledTrash onClick={() => {
                                                                            let positions = (this.state.positions || []).filter(position => position.id !== item.id)

                                                                            this.setState({ positions }, this.updatePositions)
                                                                        }}><i className="far fa-trash" /></StyledTrash>}
                                                                    </ItemBody>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </div>
                        </div>
                    </ObjectBody>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.purchaseOrder.invoice.sectionElementTitle")} icon="coins" amount={this.state.accountingInvoices ? this.state.accountingInvoices.length : 0}>
                    <div style={{ marginTop: 32 }}>
                        <GridElement styled icon="coins" title={i18next.t("page.private.purchaseOrder.invoice.gridElementTitle")}>
                            <Table
                                head={[
                                    { title: i18next.t("page.private.purchaseOrder.invoice.table.status"), width: "12.5%", column: "status" },
                                    { title: i18next.t("page.private.purchaseOrder.invoice.table.number"), width: "12.5%", column: "number" },
                                    { title: i18next.t("page.private.purchaseOrder.invoice.table.date"), width: "10%", column: "date" },
                                    { title: i18next.t("page.private.purchaseOrder.invoice.table.serviceProvider"), width: "15%", column: "serviceProvider" },
                                    { title: i18next.t("page.private.purchaseOrder.invoice.table.net"), width: "10%", column: "net" },
                                    { title: i18next.t("page.private.purchaseOrder.invoice.table.gross"), width: "10%", column: "gross" },
                                    { title: i18next.t("page.private.purchaseOrder.invoice.table.branch"), width: "17.5%", column: "subsidiary" },
                                    { title: i18next.t("page.private.purchaseOrder.invoice.table.purchaseOrder"), width: "12.5%", column: "purchaseOrder" }
                                ]}
                                onItemClick={item => window.open(scanmetrix.backendURL + "/accounting-invoice-document/" + item.data.accountingInvoiceDocument.id, "_blank")}
                                items={this.state.accountingInvoices ? this.state.accountingInvoices.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()).map(accountingInvoice => ({
                                    ...accountingInvoice,
                                    data: accountingInvoice,
                                    net: formatter.format(accountingInvoice.net),
                                    gross: formatter.format(accountingInvoice.gross),
                                    date: moment(accountingInvoice.date).format("DD.MM.YYYY"),
                                    serviceProvider: accountingInvoice.serviceProvider.name,
                                    subsidiary: accountingInvoice.subsidiary ? (accountingInvoice.subsidiary.name + (accountingInvoice.subsidiary.label ? (" (" + accountingInvoice.subsidiary.label + ")") : "")) : null,
                                    purchaseOrder: accountingInvoice.purchaseOrder ? `${accountingInvoice.purchaseOrder.number} / ${moment(accountingInvoice.purchaseOrder.date).format("DD.MM.YYYY")}` : null,
                                    contract: accountingInvoice.contract ? `${accountingInvoice.contract.title} ${accountingInvoice.contract.number ? (" (" + accountingInvoice.contract.number + ")") : ""}` : null,
                                    status: <div style={{ paddingLeft: "16px" }}><Status yellow={getNotices(accountingInvoice).warnings.length > 0} status={accountingInvoice.declined ? false : null}><i className={`far fa-${accountingInvoice.declined ? "reply-clock" : (getNotices(accountingInvoice).warnings.length > 0 ? "message-exclamation" : "hourglass")}`} /></Status>{getStatus(accountingInvoice.status)}</div>,
                                })) : null}
                            />
                        </GridElement>
                    </div>
                </Section.Item>
                <Section.Item title={i18next.t("page.private.purchaseOrder.documents.sectionTitle")} icon="folder-tree">
                    <div style={{ marginTop: 32 }}>
                        <FileManager purchaseOrderId={this.props.match.params.purchaseorderid} title={i18next.t("page.private.purchaseOrder.documents.title")} icon="folder-tree" scope="purchaseOrder" onPathChange={() => {}} />
                    </div>
                </Section.Item>
            </Section>
        </>
    }
}

const getStatus = status => {
    switch(status) {
        case "factual_clearance": return "Sachliche Prüfung"
        case "booking": return "FI-Buchung"
        case "archived": return "Archiviert"
        case "waiting_for_payment": return "Warte auf Zahlung"
    }

    return "Gebucht"
}
