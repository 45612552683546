[
  {
    "version": "24.4.149",
    "date": "21.01.2025",
    "description": "Dieses Update verbessert die eSignatures in Freigabeanfragen.",
    "categories": [
      {
        "title": "eSignatures für Freigabeanfragen (Beta)",
        "icon": "file-signature",
        "items": [
          "In Freigabeanfragen wird bei Unterzeichnern nun zwischen intern & extern unterschieden.",
          "In Freigabeanfragen wird bei Unterzeichnern nun zwischen Unterschrift & Initialen unterschieden.",
          "In Freigabeanfragen wird nun zwischen sequenziellem und parallelem Unterschreiben unterschieden."
        ]
      }
    ]
  },
  {
    "version": "24.4.146",
    "date": "17.01.2025",
    "description": "Dieses Update führt eine neue Möglichkeit der Freigabeanfragen in den Maßnahmen ein.",
    "categories": [
      {
        "title": "eSignatures für Freigabeanfragen (Beta)",
        "icon": "sparkles",
        "items": [
          "Freigabeanfragen aus Maßnahmen können nun via eSignatures direkt über das Portal signiert werden."
        ]
      }
    ]
  },
  {
    "version": "24.4.145",
    "date": "08.01.2025",
    "description": "Dieses Update verbessert die Public Roadmap und entfernt den alten Neuigkeitsbereich.",
    "categories": [
      {
        "title": "Neue Public Roadmap",
        "icon": "sparkles",
        "items": [
          "Heute veröffentlichen wir unsere neue Public Roadmap mit verbesserter Statusübersicht, Live Updates, Authentifizierung und vielem mehr. Die Public Roadmap ist wie gewohnt über das Megafonsymbol erreichbar."
        ]
      },
      {
        "title": "Dashboard",
        "icon": "tachometer",
        "items": [
          "Die Neuigkeitensektion wurde durch die neue Roadmap ersetzt."
        ]
      }
    ]
  },
  {
    "version": "24.4.144",
    "date": "07.01.2025",
    "description": "Dieses Update führt die Betaversion der neuen Budgetierung ein.",
    "categories": [
      {
        "title": "Budgetierung (Beta)",
        "icon": "sparkles",
        "items": [
          "Heute veröffentlichen wir die Betaversion des überarbeiteten Budgetierungsmoduls mit der Möglichkeit, mehrere Budgetpools & Budgetvorlagen anzulegen, eine Unterteilung in CapEx/OpEx vorzunehmen und Kosten in verschiedene Ausgabearten zu unterteilen. Das neue Modul befindet sich in einer sehr frühen Version und wird zukünftig um weitere KPIs, Makros, Exporte und Auswertungen erweitert.",
          "Eingangsangebote in Maßnahmen können nun eine Budget Kategorie besitzen.",
          "Bestellungen können nun eine Budget Kategorie besitzen.",
          "Eingangsrechnungen können nun eine Budget Kategorie besitzen.",
          "Leistungszyklen können nun eine Budget Kategorie besitzen."
        ]
      },
      {
        "title": "Zähler",
        "icon": "monitor-heart-rate",
        "items": [
          "Die Zeitzone für den Export von Zählern wurde auf Berlin angepasst."
        ]
      }
    ]
  },
  {
    "version": "24.4.138",
    "date": "17.12.2024",
    "description": "Dieses Update behebt einen Fehler in den Maßnahmen.",
    "categories": [
      {
        "title": "Maßnahmen",
        "icon": "network-wired",
        "items": [
          "Fehler behoben, der unter Umständen dazu führt, dass keine Maßnahmen erstellt werden können."
        ]
      }
    ]
  },
  {
    "version": "24.4.135",
    "date": "12.12.2024",
    "description": "Dieses Update führt das Adressbuch ein.",
    "categories": [
      {
        "title": "Adressbuch",
        "icon": "address-book",
        "items": [
          "In vielen Feldern für den Versand von E-Mails - bspw. bei der Weiterleitung von Tickets - gibt es nun ein Adressbuch, um noch einfacher auf E-Mail-Adressen im System zuzugreifen."
        ]
      }
    ]
  },
  {
    "version": "24.4.134",
    "date": "11.12.2024",
    "description": "Dieses Update verbessert die API und veröffentlicht scanmetrix One.",
    "categories": [
      {
        "title": "API Änderungen",
        "icon": "server",
        "items": [
          "Die API v2 wurde um viele weitere Routen und Funktionen erweitert."
        ]
      },
      {
        "title": "scanmetrix One",
        "icon": "sparkles",
        "items": [
          "Die Beta-Version von scanmetrix One wurde veröffentlicht. Bei scanmetrix One handelt es sich um eine Schnittstelle zwischen scanmetrix Cockpit (CAFM) und scanmetrix Service Provider (ERP), um FM-Dienstleistern und Generalunternehmen die Möglichkeit der Prozessautomatisierung zu gewährleisten. scanmetrix One ist aktuell nur für Closed Book Dienstleister auf Anfrage erhältlich."
        ]
      }
    ]
  },
  {
    "version": "24.4.131",
    "date": "13.11.2024",
    "description": "Dieses Update behebt diverse Fehler und bringt kleinere Verbesserungen mit sich.",
    "categories": [
      {
        "title": "Servicedesk",
        "icon": "bell-concierge",
        "items": [
          "Das Servicedesk kann nun auf Anfrage ein benutzerdefiniertes Branding erhalten."
        ]
      },
      {
        "title": "Dienstleister",
        "icon": "user-tie",
        "items": [
          "Die Tabelle 'Bestellungen' in einem Dienstleisterdatensatz zeigt nun nur noch Bestellungen dieses Dienstleisters an."
        ]
      }
    ]
  },
  {
    "version": "24.4.129",
    "date": "07.11.2024",
    "description": "Dieses Update behebt diverse Fehler und bringt kleinere Verbesserungen mit sich.",
    "categories": [
      {
        "title": "Allgemein",
        "icon": "cog",
        "items": [
          "Kleinere Bugfixes in der Anmeldung.",
          "Diverse Behebungen von Rechtschreibfehlern."
        ]
      },
      {
        "title": "Maßnahmen",
        "icon": "network-wired",
        "items": [
          "Termine/Erinnerungen sind nun aus der Maßnahme heraus löschbar."
        ]
      }
    ]
  },
  {
    "version": "24.4.128",
    "date": "06.11.2024",
    "description": "Dieses Update behebt diverse Fehler und bringt kleinere Verbesserungen mit sich.",
    "categories": [
      {
        "title": "Allgemein",
        "icon": "cog",
        "items": [
          "Formatierbare Textfelder wurden erheblich verbessert."
        ]
      },
      {
        "title": "Rollen",
        "icon": "shield-halved",
        "items": [
          "Fehler behoben, der dafür gesorgt hat, dass Rollen nicht gelöscht werden können."
        ]
      },
      {
        "title": "Dokumente",
        "icon": "folder-tree",
        "items": [
          "Fehler behoben, der dafür gesorgt hat, dass bestimmte Dateien nicht gelöscht werden können."
        ]
      },
      {
        "title": "Ticket-Eskalationsstufen",
        "icon": "message-exclamation",
        "items": [
          "Fehler behoben, der dafür gesorgt hat, dass zusätzliche Empfänger-E-Mails von Eskalationsstufen nicht gespeichert werden."
        ]
      },
      {
        "title": "Begehungen",
        "icon": "person-walking",
        "items": [
          "Fehler behoben, der dafür gesorgt hat, dass in der Tabelle anstehender Begehungen 'undefined undefined' angezeigt wird."
        ]
      }
    ]
  },
  {
    "version": "24.4.126",
    "date": "05.11.2024",
    "description": "Dieses Update behebt diverse Fehler.",
    "categories": [
      {
        "title": "Ticket-System",
        "icon": "comments-alt",
        "items": [
          "Fehler behoben, der dafür gesorgt hat, dass das Ticket-System in seltenen Fällen einen weißen Bildschirm anzeigt.",
          "Fehler behoben, der dafür gesorgt hat, dass beim Weiterleiten von Tickets als E-Mail 'undefined' als Versender angezeigt wird."
        ]
      }
    ]
  },
  {
    "version": "24.4.124",
    "date": "28.10.2024",
    "description": "Dieses Update behebt einen wichtigen Bug in der Anmeldung.",
    "categories": [
      {
        "title": "Anmeldung",
        "icon": "sign-in",
        "items": [
          "Fehler behoben, der dafür gesorgt hat, dass die Anmeldung mit E-Mail-Adressen, die einen Großbuchstaben enthalten, nicht funktioniert."
        ]
      }
    ]
  },
  {
    "version": "24.4.123",
    "date": "27.10.2024",
    "description": "Dieses Update verbessert Bestellungen und veröffentlicht das neue Backend.",
    "categories": [
      {
        "title": "Bestellungen",
        "icon": "comment-alt-check",
        "items": [
          "Beim Erzeugen einer Bestellung aus einem Angebot wird die Angebotsnummer nun als Referenz übernommen.",
          "Benutzerdefinierte Bestellvorlagen für die PDF-Datei sind nun auf Anfrage möglich."
        ]
      },
      {
        "title": "Neues Backend",
        "icon": "server",
        "items": [
          "Die API v2 des neuen Backends ist nun für alle verfügbar. Die API-Dokumentation kann von Administratoren über ein neues Symbol in der Seitenleiste aufgerufen werden. Warnung: Es handelt sich weiterhin um eine sehr frühe Version des neuen Backends, welches noch nicht in allen Modulen implementiert ist.",
          "Die Begehungstabelle im Cockpit ist die erste Funktion, welche das neue, performantere Backend verwendet. In dieser sollten nun erhebliche Geschwindigkeitsverbesserungen bemerkbar sein."
        ]
      }
    ]
  },
  {
    "version": "24.4.109",
    "date": "09.09.2024",
    "description": "Dieses Update verbessert diverse Funktionen der Software.",
    "categories": [
      {
        "title": "Leistungszyklen",
        "icon": "history",
        "items": [
          "Neue Möglichkeit, Leistungszyklen zu kommentieren, um bspw. die Ursache für eine Überfälligkeit zu dokumentieren.",
          "Neue Möglichkeit, direkt aus einem anstehenden Leistungszyklus heraus einen Termin im Kalender zu erzeugen."
        ]
      },
      {
        "title": "Bestellungen",
        "icon": "comment-alt-check",
        "items": [
          "In einer Bestellung wird nun die dazugehörige Maßnahme angezeigt."
        ]
      }
    ]
  },
  {
    "version": "24.4.108",
    "date": "09.09.2024",
    "description": "Dieses Update verbessert die Zählerablesung.",
    "categories": [
      {
        "title": "Allgemein",
        "icon": "cog",
        "items": [
          "Fehler behoben, der dazu geführt hat, dass der Changelog zu häufig angezeigt wird."
        ]
      },
      {
        "title": "Zähler",
        "icon": "monitor-heart-rate",
        "items": [
          "Zählern können nun QR-Codes zugewiesen werden, um die Ablesung im Servicedesk zu vereinfachen."
        ]
      }
    ]
  },
  {
    "version": "24.4.105",
    "date": "15.08.2024",
    "description": "Dieses Update führt eine nützliche Funktion für das Ticket-System ein.",
    "categories": [
      {
        "title": "Ticket-System",
        "icon": "comments-alt",
        "items": [
          "Externe haben nun die Möglichkeit, auf als E-Mail weitergeleitete Tickets direkt per E-Mail zu antworten. Diese Antworten werden in der Ticketunterhaltung angezeigt. Antworten aus E-Mails sind nur im Cockpit - nicht im Servicedesk - ersichtlich. Diese Funktion ist noch experimentell. Eine direkte Rückantwort an den E-Mail-Sender aus dem Cockpit heraus ist noch nicht möglich."
        ]
      }
    ]
  },
  {
    "version": "24.4.102",
    "date": "13.08.2024",
    "description": "Dieses Update führt ein E-Mail-Postfach für Rechnungen ein.",
    "categories": [
      {
        "title": "Eingangsbelege",
        "icon": "coins",
        "items": [
          "Jedes Cockpit hat nun eine eigene E-Mail-Adresse erhalten, an die Rechnungen als PDF gesendet werden können, um diese automatisch in den Eingangsbelegen hochzuladen."
        ]
      }
    ]
  },
  {
    "version": "24.4.99",
    "date": "13.08.2024",
    "description": "Dieses Update behebt kleinere Fehler.",
    "categories": [
      {
        "title": "Dashboard",
        "icon": "tachometer",
        "items": [
          "Fehler behoben, der bei Nutzern mit PM-Perspektive zu einem weißen Bildschirm führen kann."
        ]
      }
    ]
  },
  {
    "version": "24.4.98",
    "date": "12.08.2024",
    "description": "Dieses Update behebt diverse kleinere Fehler und führt einen neuen Export ein.",
    "categories": [
      {
        "title": "Benutzer",
        "icon": "users",
        "items": [
          "Fehler im Registrierungsprozess behoben, der unter Umständen zu einer Fehlermeldung führen kann.",
          "Fehler im Benutzerprofil behoben, der dazu führen kann, dass dieses nicht korrekt gespeichert wird."
        ]
      },
      {
        "title": "Leistungszyklen",
        "icon": "history",
        "items": [
          "Fehler in der Berechnung von Leistungszyklen behoben, welcher zu einem falschen Fälligkeitsdatum führen kann."
        ]
      },
      {
        "title": "Ticket-System",
        "icon": "comments-alt",
        "items": [
          "Neuer Export in den Einstellungen, um alle Tickets als Exceltabelle zu speichern."
        ]
      }
    ]
  },
  {
    "version": "24.4.94",
    "date": "16.07.2024",
    "description": "Dieses Update führt eine neue Funktion im Ticket-System ein.",
    "categories": [
      {
        "title": "Ticket-System",
        "icon": "comments-alt",
        "items": [
          "Neue Unterhaltungsfunktion für Melder & Bearbeiter, um bilateral im Kontext eines Tickets zu kommunizieren.",
          "Antwortet ein Bearbeiter auf ein Ticket, erhält der Melder automatisch eine E-Mail-Benachrichtigung.",
          "Neuer Reiter im Ticket-System, der alle Tickets mit durch den Nutzer noch unbeantworteten Unterhaltungen tabellarisch darstellt."
        ]
      }
    ]
  },
  {
    "version": "24.4.93",
    "date": "04.07.2024",
    "description": "Dieses Update behebt diverse Fehler.",
    "categories": [
      {
        "title": "Allgemein",
        "icon": "cog",
        "items": [
          "Fehler behoben, der das Anlegen mehrerer E-Mail-Domänen gleichzeitig verhindert hat.",
          "Es wurden Vorbereitungen für die Plansynchro durch scanmetrix Mission geschaffen.",
          "Arbeitsgruppen und dessen Mitglieder können nun gelöscht werden.",
          "Fehler behoben, der die zuletzt aktiven Nutzer auf dem Dashboard falsch sortiert hat.",
          "Die Zeitzone des Cockpits wurde auf Europa/Berlin angepasst."
        ]
      },
      {
        "title": "Leistungsnachweise",
        "icon": "file-certificate",
        "items": [
          "Fehler behoben, der bei Leistungsnachweisen die Verwendung des Filters verhindert hat.",
          "Fehler behoben, der bei Leistungsnachweisen als Datum 'invalid date' angezeigt hat.",
          "Fehler behoben, der das Beenden der Nachverfolgung von Leistungsnachweisen verhindert hat."
        ]
      },
      {
        "title": "Ticket-System",
        "icon": "comments-alt",
        "items": [
          "Die allgemeine Systemperformanz beim Laden von Tickets wurde verbessert.",
          "Fehler behoben, der das Öffnen von in E-Mails weitergeleiteten Anhängen für Externe verhindert hat."
        ]
      }
    ]
  },
  {
    "version": "24.4.92",
    "date": "20.06.2024",
    "description": "Dieses Update behebt einen Darstellungsfehler im Servicedesk.",
    "categories": [
      {
        "title": "Servicedesk",
        "icon": "bell-concierge",
        "items": [
          "Fehler behoben, der dazu geführt hat, dass auf mobilen Geräten in der Auswahl der Ticketart unter Umständen kein Text angezeigt wird."
        ]
      }
    ]
  },
  {
    "version": "24.4.91",
    "date": "12.06.2024",
    "description": "Dieses Update bringt kleinere Verbesserungen mit sich.",
    "categories": [
      {
        "title": "Allgemein",
        "icon": "cog",
        "items": [
          "In Feldern, in denen ein Dienstleister selektiert wird, wird nun auch die Postleitzahl und der Ort dargestellt, um einfacher zwischen Regionalgesellschaften zu unterscheiden."
        ]
      },
      {
        "title": "Kalender",
        "icon": "calendar",
        "items": [
          "Darstellung des Termintooltips bei langen Texten behoben."
        ]
      },
      {
        "title": "Eingangsbelege",
        "icon": "coins",
        "items": [
          "Neue OCR-Funktion für die Buchung von Eingangsbelegen, welche Metadaten automatisch vorausfüllt."
        ]
      }
    ]
  },
  {
    "version": "24.4.90",
    "date": "11.06.2024",
    "description": "Dieses Update behebt kleinere Fehler.",
    "categories": [
      {
        "title": "Vertragsmanagement",
        "icon": "file-contract",
        "items": [
          "Fehler behoben, welcher bei vielen Leistungsnachweisen zu Performanzproblemen geführt hat."
        ]
      }
    ]
  },
  {
    "version": "24.4.86",
    "date": "11.06.2024",
    "description": "Dieses Update behebt kleinere Fehler.",
    "categories": [
      {
        "title": "Maßnahmen",
        "icon": "network-wired",
        "items": [
          "Fehler behoben, der den Maßnahmentyp falsch dargestellt hat."
        ]
      },
      {
        "title": "Vertragsmanagement",
        "icon": "file-contract",
        "items": [
          "Diverse kleinere Darstellungsfehler behoben."
        ]
      }
    ]
  },
  {
    "version": "24.4.85",
    "date": "10.06.2024",
    "description": "Dieses Update behebt diverse Fehler und bringt kleinere Verbesserungen mit sich.",
    "categories": [
      {
        "title": "Dashboard",
        "icon": "tachometer",
        "items": [
          "Fehler im Dashboard behoben, welcher zu einer fehlerhaften Darstellung der Fälligkeiten geführt hat."
        ]
      },
      {
        "title": "Vertragsmanagement",
        "icon": "file-contract",
        "items": [
          "Fehler in Nachverfolgung Leistungsnachweise behoben, welcher zu falscher Datumsdarstellung geführt hat.",
          "Fehler in Nachverfolgung Leistungsnachweise behoben, welcher zu Performanzproblemen geführt hat.",
          "Verträge können nun dupliziert werden."
        ]
      },
      {
        "title": "Wartungen",
        "icon": "toolbox",
        "items": [
          "Fehler behoben, der zur Überlappung von Bildern geführt hat."
        ]
      },
      {
        "title": "Bestellungen",
        "icon": "comment-alt-check",
        "items": [
          "Bestellungen können nun direkt aus Maßnahmen-Angeboten mittels OCR generiert werden."
        ]
      },
      {
        "title": "Flächenmanagement",
        "icon": "chair-office",
        "items": [
          "Fehler behoben, der in der Tabelle eine falsch formatierte Anzahl von Räumen angezeigt hat."
        ]
      }
    ]
  },
  {
    "version": "24.4.84",
    "date": "10.06.2024",
    "description": "Dieses Update behebt diverse Fehler.",
    "categories": [
      {
        "title": "TGA-Fälligkeiten & Mängel",
        "icon": "folder-tree",
        "items": [
          "Fehler im Systemcache behoben, der dazu führte, dass Anlagen und Mängel teilweise nicht auftauchen."
        ]
      }
    ]
  },
  {
    "version": "24.4.74",
    "date": "14.05.2024",
    "description": "Dieses Update verbessert das Nutzermanagement.",
    "categories": [
      {
        "title": "Benutzer",
        "icon": "users",
        "items": [
          "Diverse Erweiterungen für das Benutzermanagement wie Rollenvergabe, Bearbeiten und Sperren von Nutzern."
        ]
      }
    ]
  },
  {
    "version": "24.4.72",
    "date": "10.05.2024",
    "description": "Dieses Update verbessert das Ticket-System.",
    "categories": [
      {
        "title": "Ticket-System",
        "icon": "comments-alt",
        "items": [
          "Anhänge von Tickets werden nun als Link versendet, um auch das Versenden von sehr großen Dateien zu ermöglichen."
        ]
      }
    ]
  },
  {
    "version": "24.4.68",
    "date": "07.05.2024",
    "description": "Dieses Update behebt kleinere Fehler.",
    "categories": [
      {
        "title": "Bestellungen",
        "icon": "comment-alt-check",
        "items": [
          "Fehler behoben, der dafür gesorgt hat, dass ein falsches Beauftragungsdatum angezeigt wird."
        ]
      }
    ]
  },
  {
    "version": "24.4.67",
    "date": "02.05.2024",
    "description": "Dieses Update verbessert das Ticket-System, Bestellwesen und Vertragsmanagement.",
    "categories": [
      {
        "title": "Ticket-System",
        "icon": "comments-alt",
        "items": [
          "Ticketarten können nun individuell in den Systemeinstellungen angepasst und (de-)aktiviert werden.",
          "Fehler behoben, der das Bearbeiten von Ticket-Eskalationsstufen in einigen Fällen verhindert hat."
        ]
      },
      {
        "title": "Bestellungen",
        "icon": "comment-alt-check",
        "items": [
          "Fehler behoben, der beim Generieren einer Bestellung aus einem Maßnahmen-Angebot dafür gesorgt hat, dass die Positionen nicht immer direkt auf der PDF dargestellt werden."
        ]
      },
      {
        "title": "Vertragsmanagement",
        "icon": "file-contract",
        "items": [
          "Fehler behoben, der bei der Einheit für die Vertragsverlängerung einen falschen Text dargestellt hat."
        ]
      }
    ]
  },
  {
    "version": "24.4.64",
    "date": "30.04.2024",
    "description": "Dieses Update verbessert die Performanz von Begehungen.",
    "categories": [
      {
        "title": "Begehungen",
        "icon": "person-walking",
        "items": [
          "Die Performanz der Begehungstabelle im Cockpit wurde maßgeblich verbessert."
        ]
      }
    ]
  },
  {
    "version": "24.4.63",
    "date": "29.04.2024",
    "description": "Dieses Update behebt kleinere Fehler.",
    "categories": [
      {
        "title": "Maßnahmen",
        "icon": "network-wired",
        "items": [
          "Fehler behoben, der den Gesamtstatus offener Freigabeanfragen falsch dargestellt hat."
        ]
      }
    ]
  },
  {
    "version": "24.4.62",
    "date": "29.04.2024",
    "description": "Dieses Update behebt kleinere Fehler.",
    "categories": [
      {
        "title": "Begehungen",
        "icon": "person-walking",
        "items": [
          "Deutliche Verbesserung der Performanz für die Generierung von Begehungsberichten."
        ]
      }
    ]
  },
  {
        "version": "24.4.60",
        "date": "18.04.2024",
        "description": "Dieses Update behebt kleinere Fehler.",
        "categories": [
            {
                "title": "Niederlassungen",
                "icon": "map-marker",
                "items": [
                    "Fehler behoben, der in seltenen Fällen dazu geführt hat, dass eine Niederlassung nicht angelegt werden kann."
                ]
            }
        ]
    },
    {
    "version": "24.4.59",
    "date": "12.04.2024",
    "description": "Dieses Update behebt kleinere Fehler.",
    "categories": [
      {
        "title": "Maßnahmen",
        "icon": "network-wired",
        "items": [
          "Fehler behoben, der in der Dienstleister-Erinnerungsfunktion für Angebotsanfragen falsche Textbausteine einsetzt."
        ]
      }
    ]
  },
  {
    "version": "24.4.58",
    "date": "12.04.2024",
    "description": "Dieses Update verbessert diverse Performanzprobleme.",
    "categories": [
      {
        "title": "Allgemeine Systemperformanz",
        "icon": "gauge-high",
        "items": [
          "Diverse Module innerhalb der Software wurden im Hinblick auf Performanz verbessert."
        ]
      }
    ]
  },
  {
        "version": "24.4.55",
        "date": "05.03.2024",
        "description": "Dieses Update verbessert das Ticket-System.",
        "categories": [
            {
                "title": "Ticket-System",
                "icon": "comments-alt",
                "items": [
                    "Neue Tabellenansicht für Tickets wurde eingeführt.",
                    "In der Kanbanansicht werden pro Lane aus Performanzgründen nun noch höchstens 100 Tickets angezeigt. Die übrige Bearbeitung kann über die Tabellenansicht erfolgen."
                ]
            },
            {
                "title": "Eingangsbelege",
                "icon": "coins",
                "items": [
                    "Die Suche nach Eingangsbelegen wurde verbessert."
                ]
            },
            {
                "title": "Exporte",
                "icon": "file-export",
                "items": [
                    "Der Export von Leistungsnachweisen wurde verbessert, um sehr lange Dateinamen automatisch zu kürzen."
                ]
            }
        ]
    },
    {
    "version": "24.4.54",
    "date": "29.02.2024",
    "description": "Dieses Update verbessert die Rechnungsbuchung und Budgetierung.",
    "categories": [
      {
        "title": "Eingangsbelege",
        "icon": "coins",
        "items": [
          "Buchungsschritte können nun per Systemkonfiguration deaktiviert werden."
        ]
      }
    ]
  },
  {
    "version": "24.4.53",
    "date": "28.02.2024",
    "description": "Dieses Update enthält wichtige Fehlerbehebungen und Darstellungsverbesserungen.",
    "categories": [
      {
        "title": "Maßnahmen",
        "icon": "network-wired",
        "items": [
          "Fehler behoben, der bei manuellen Angeboten dazu führte, dass einige Freigabeanfragen nicht versendet werden konnten.",
          "Fehler behoben, der beim Wandeln von manuellen Angeboten zu Bestellungen dazu führte, dass nicht alle Metadaten übernommen wurden."
        ]
      }
    ]
  },
  {
    "version": "24.4.52",
    "date": "26.02.2024",
    "description": "Dieses Update enthält diverse Darstellungsverbesserungen und Änderungen am Ticket-System und Servicedesk.",
    "categories": [
      {
        "title": "Allgemein",
        "icon": "cog",
        "items": [
          "Der Changelog für Updates wurde wieder eingeführt."
        ]
      },
      {
        "title": "Ticket-System",
        "icon": "comments-alt",
        "items": [
          "Bessere Darstellung der Filter, um die Ansicht für Bildschirme zu optimieren.",
          "Im Reiter 'Tickets' in den Niederlassungen werden nun standardmäßig erledigte und archivierte Tickets ausgeblendet."
        ]
      },
      {
        "title": "Servicedesk",
        "icon": "bell-concierge",
        "items": [
          "Die Suchfunktion für Tickets wurde verbessert.",
          "Nutzern werden nun auch Tickets angezeigt, die lediglich zugewiesen und nicht selbst-erstellt sind. Freigabemeldungen sind trotzdem möglich.",
          "Neue Suchfunktion für Begehungen."
        ]
      }
    ]
  },
  {
    "version": "24.4.51",
    "date": "22.02.2024",
    "description": "Dieses Update verbessert die Filtermöglichkeiten für das Ticket-System.",
    "categories": [
      {
        "title": "Ticket-System",
        "icon": "comments-alt",
        "items": [
          "Neue Filtermöglichkeit nach 'Begehungsart'.",
          "Neue Filtermöglichkeit nach 'Begehungsvorlage'."
        ]
      }
    ]
  },
  {
    "version": "24.4.50",
    "date": "21.02.2024",
    "description": "Dieses Update verbessert Maßnahmen.",
    "categories": [
      {
        "title": "Maßnahmen",
        "icon": "network-wired",
        "items": [
          "Angebote von Dienstleistern können nun auch ohne vorherige Angebotsanfrage hochgeladen werden."
        ]
      }
    ]
  },
  {
    "version": "24.4.48",
    "date": "20.02.2024",
    "description": "Dieses Update verbessert Maßnahmen.",
    "categories": [
      {
        "title": "Maßnahmen",
        "icon": "network-wired",
        "items": [
          "Aus Tickets können nun Maßnahmen erzeugt werden."
        ]
      }
    ]
  }
]
