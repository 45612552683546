import React, { Component } from "react"
import CreateBudgetPoolModal from "./modals/CreateBudgetPoolModal"
import DeleteBudgetPoolModal from "./modals/DeleteBudgetPoolModal"
import { GridElement, Table, Button } from "scanmetrix-components"

const phasings = {
    MONTHLY: "Monatsweise",
    QUARTERLY: "Quartalsweise",
    YEARLY: "Jahresweise"
}

export default class extends Component {
    render() {
        return <>
            <CreateBudgetPoolModal instance={ref => this.createBudgetPoolModal = ref} refresh={() => this.refreshBudgetPools()} />
            <DeleteBudgetPoolModal instance={ref => this.deleteBudgetPoolModal = ref} refresh={() => this.refreshBudgetPools()} />
            <GridElement styled title="Budget Pools" icon="chart-pie-simple-circle-dollar" rightContent={<Button icon="chart-pie-simple-circle-dollar" title="Budget Pool anlegen" thick primary onClick={() => this.createBudgetPoolModal.open()} />}>
                <Table
                    refresh={refresh => this.refreshBudgetPools = refresh}
                    head={[
                        { title: "Name", width: "25%", column: "name", orderField: "name" },
                        { title: "Phasing", width: "10%", column: "phasing", orderField: "phasing" },
                        { title: "Beginn", width: "10%", column: "begin", orderField: "begin" },
                        { title: "Ende", width: "10%", column: "end", orderField: "end" },
                        { title: "Budget Vorlage", width: "25%", column: "template", orderField: "budgetTemplate.name" },
                        { title: "Bearbeitet", width: "10%", column: "updated_at", orderField: "updated_at" },
                        { title: "Erstellt", width: "10%", column: "created_at", orderField: "created_at" },
                    ]}
                    contextItems={[
                        { title: "Budget Pool löschen", icon: "trash", onClick: item => this.deleteBudgetPoolModal.open(item.id)}
                    ]}
                    metaCallback={this.props.metaCallback}
                    query={`${scanmetrix.nestURL}/v2/budget-pool`}
                    defaultSort="created_at_DESC"
                    onItemClick={budgetPool => this.props.history.push(`/budget-pool/${budgetPool.id}`)}
                    map={budgetPools => budgetPools.map(budgetPool => ({
                        ...budgetPool,
                        begin: moment(budgetPool.begin).format("DD.MM.YYYY"),
                        end: moment(budgetPool.end).format("DD.MM.YYYY"),
                        phasing: phasings[budgetPool.phasing],
                        template: budgetPool.budgetTemplate.name,
                        updated_at: moment(budgetPool.updated_at).fromNow(),
                        created_at: moment(budgetPool.created_at).fromNow(),
                    }))}
                />
            </GridElement>
        </>
    }
}
