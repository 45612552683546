import React, { Component } from "react"
import CreateBudgetTemplateModal from "./modals/CreateBudgetTemplateModal"
import DeleteBudgetTemplateModal from "./modals/DeleteBudgetTemplateModal"
import UpdateBudgetTemplateModal from "./modals/UpdateBudgetTemplateModal"
import { GridElement, Table, Button } from "scanmetrix-components"

export default class extends Component {
    render() {
        return <>
            <CreateBudgetTemplateModal instance={ref => this.createBudgetTemplateModal = ref} refresh={() => this.refreshBudgetTemplates()} />
            <DeleteBudgetTemplateModal instance={ref => this.deleteBudgetTemplateModal = ref} refresh={() => this.refreshBudgetTemplates()} />
            <UpdateBudgetTemplateModal instance={ref => this.updateBudgetTemplateModal = ref} refresh={() => this.refreshBudgetTemplates()} />
            <GridElement styled title="Budget Vorlagen" icon="filter-circle-dollar" rightContent={<Button icon="filter-circle-dollar" title="Budget Vorlage anlegen" thick primary onClick={() => this.createBudgetTemplateModal.open()} />}>
                <Table
                    refresh={refresh => this.refreshBudgetTemplates = refresh}
                    head={[
                        { title: "Name", width: "80%", column: "name", orderField: "name" },
                        { title: "Bearbeitet", width: "10%", column: "updated_at", orderField: "updated_at" },
                        { title: "Erstellt", width: "10%", column: "created_at", orderField: "created_at" },
                    ]}
                    contextItems={[
                        { title: "Budget Vorlage löschen", icon: "trash", onClick: item => this.deleteBudgetTemplateModal.open(item.id)}
                    ]}
                    metaCallback={this.props.metaCallback}
                    query={`${scanmetrix.nestURL}/v2/budget-template`}
                    defaultSort="created_at_DESC"
                    onItemClick={budgetTemplate => this.updateBudgetTemplateModal.open(budgetTemplate)}
                    map={budgetTemplates => budgetTemplates.map(budgetTemplate => ({
                        ...budgetTemplate,
                        updated_at: moment(budgetTemplate.updated_at).fromNow(),
                        created_at: moment(budgetTemplate.created_at).fromNow(),
                    }))}
                />
            </GridElement>
        </>
    }
}
