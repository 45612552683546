import React, { Component } from "react"
import { Modal, Button } from "scanmetrix-components"

export default class extends Component {
    state = { budgetPoolSubsidiaryId: null, loading: false }

    render() {
        return <Modal minWidth="650px" instance={this.props.instance} initialize={budgetPoolSubsidiaryId => this.setState({ budgetPoolSubsidiaryId, loading: false })}>
            <Modal.Head title="Niederlassung entfernen" icon="trash" />
            <Modal.Body padding={24}>
                Sind Sie sicher, dass Sie diese Niederlassung entfernen möchten? Diese Aktion ist nicht widerrufbar.
                {this.state.error && <Modal.Error>{this.state.error}</Modal.Error>}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button loading={this.state.loading} thick title="Niederlassung entfernen" icon="trash" onClick={() => {
                this.setState({ loading: true })

                fetch(`${scanmetrix.nestURL}/v2/budget-pool-subsidiary/${this.state.budgetPoolSubsidiaryId}`, {
                    method: "DELETE",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    }
                }).then(result => {
                    if(result.status !== 200) {
                        return this.setState({ loading: false, error: "Das Löschen des Datensatzes konnte nicht durchgeführt werden." })
                    }

                    this.props.refresh().then(() => {
                        instance.close()
                    })
                }).catch(({ message }) => {
                    this.setState({ loading: false, error: message })
                })
            }} />} />
        </Modal>
    }
}
