import React, { Component } from "react"
import { Modal, Button, Input, TextEditor, Checkbox, Select } from "scanmetrix-components"
import i18next from "i18next";
import isEmail from "validator/lib/isEmail";

const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
})

export default class extends Component {
    state = { selectedStandardFiles: [], selectedFiles: [], files: [], standardFiles: [], selectedOffers: [], recipients: [], type: "sequential", text: null, title: null, loading: false }

    constructor(props) {
        super(props)

        scanmetrix.client.query({
            query: scanmetrix.gql`
                query($id: ID!) {
                    StandardFiles {
                        nodes {
                            id
                            name
                        }
                    }
                    Files(filter: { measureId_eq: $id }) {
                        nodes {
                            id
                            name
                            path
                        }
                    }
                }
            `,
            variables: {
                id: this.props.measure.id
            }
        }).then(data => {
            this.setState({ files: data.data.Files.nodes, standardFiles: data.data.StandardFiles.nodes })
        })
    }

    render() {
        return <Modal minWidth="1280px" instance={this.props.instance} initialize={() => {
            this.setState({ selectedOffers: [], recipients: [ { email: null, position: null, name: null, internal: false, type: null } ], title: this.props.measure.title, type: "sequential", loading: false, text: null })
        }}>
            <Modal.Head title="Freigabeanfrage mit eSignaturen erzeugen" icon="envelope" />
            <Modal.Body padding={24}>
                <Modal.Divider title="Zu unterzeichnende Empfänger" />
                <div style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: 16, marginBottom: 16, marginTop: 16 }}>
                    {this.state.recipients.map((recipient, index) => {
                        return <div key={index} style={{ display: "grid", width: "100%", gridTemplateColumns: "3fr 2fr 2fr 1.5fr 1fr 0.25fr", gridGap: 16 }}>
                            <Input readOnly={this.state.loading} addressBookCallback={data => {
                                recipient.email = data.email
                                if(data.name) recipient.name = data.name
                                this.setState({ recipients: this.state.recipients })
                            }} emailAddressBook adjustWidth label="E-Mail-Adresse" icon="envelope" placeholder="max.mustermann@email.com" value={recipient.email} onChange={email => {
                                recipient.email = email
                                this.setState({ recipients: this.state.recipients })
                            }} required />
                            <Input readOnly={this.state.loading} adjustWidth label="Vor- und Nachname" icon="user" placeholder="Max Mustermann" value={recipient.name} onChange={name => {
                                recipient.name = name
                                this.setState({ recipients: this.state.recipients })
                            }} required />
                            <Input readOnly={this.state.loading} adjustWidth label="Verantwortlichkeit" icon="user-tie" placeholder="Facility Manager" value={recipient.position} onChange={position => {
                                recipient.position = position
                                this.setState({ recipients: this.state.recipients })
                            }} required />
                            <Select value={recipient.type} onChange={type => {
                                recipient.type = type
                                this.setState({ recipients: this.state.recipients })
                            }} items={[
                                { key: "signature", title: "Unterschrift" },
                                { key: "initials", title: "Initialen" },
                                //{ key: "readOnly", title: "Nur lesen" },
                            ]} required adjustWidth label="Unterschriftsart" icon="file-signature" />
                            <div style={{ height: "100%", display: "flex", alignItems: "flex-end", boxSizing: "border-box", paddingBottom: 14 }}>
                                <Checkbox value={recipient.internal} onChange={internal => {
                                    recipient.internal = internal
                                    this.setState({ recipients: this.state.recipients })
                                }} label="Intern" />
                            </div>
                            <i onClick={() => {
                                if(!this.state.loading) this.setState({ recipients: this.state.recipients.filter(r => r !== recipient) })
                            }} className="fa-duotone fa-trash" style={{ height: "100%", color: "#3b97d3", cursor: "pointer", display: "flex", alignItems: "flex-end", boxSizing: "border-box", paddingBottom: 16 }} />
                        </div>
                    })}
                    <Button disabled={this.state.loading} thick primary title="Unterzeichner hinzufügen" icon="file-signature" onClick={() => {
                        const recipients = this.state.recipients

                        recipients.push({
                            email: null,
                            name: null,
                            position: null,
                            internal: false,
                            type: null
                        })

                        this.setState({ recipients })
                    }} />
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "5fr 2fr", gridGap: 24 }}>
                    <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 24 }}>
                        <Modal.Divider title="Titel der Freigabeanfrage in E-Mail & PDF" />
                        <div style={{ display: "grid", gridTemplateColumns: "2fr 1fr", gridGap: 16 }}>
                            <Input readOnly={this.state.loading} adjustWidth label={i18next.t("page.private.measure.createMeasureApprovalModal.subject")} icon="heading" placeholder={i18next.t("page.private.measure.createMeasureApprovalModal.searchPlaceholderPo2023")} value={this.state.title} onChange={title => this.setState({ title })} required />
                            <Select adjustWidth value={this.state.type} onChange={type => this.setState({ type })} label="Unterschriftsablauf" icon="history" items={[
                                { key: "sequential", title: "Sequenziell (nacheinander)" },
                                { key: "parallel", title: "Parallel (alle gleichzeitig)" },
                            ]} />
                        </div>
                        <Modal.Divider title="Empfehlungstext in E-Mail & PDF" />
                        <div style={{ border: "1px solid rgba(0, 0, 0, 0.2)", height: "min-content" }}>
                            <TextEditor
                                readOnly={this.state.loading}
                                label="Empfehlungstext"
                                icon="font"
                                placeholder="Die Maßnahme ist dringend notwendig, da..."
                                value={this.state.text}
                                height={300}
                                onChange={text => this.setState({ text })}
                            />
                        </div>
                    </div>
                    <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 16, height: "100%" }}>
                        <div style={{ overflowY: "auto" }}>
                            <Modal.Divider title={i18next.t("page.private.measure.createMeasureApprovalModal.offersAwaitingRelease")} />
                            <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                                {this.props.offers.filter(offer => offer.status === "unedited").map((offer, index) => <Checkbox disabled={this.state.loading} key={index} label={`${formatter.format(offer.net)} ${i18next.t("page.private.measure.createMeasureApprovalModal.net")} ${offer.number} ${i18next.t("page.private.measure.createMeasureApprovalModal.from")} ${moment(offer.date).format("DD.MM.YYYY")} / ${offer.serviceProvider ? offer.serviceProvider.name : offer.request.serviceProvider.name}`} value={this.state.selectedOffers.includes(offer.id)} onChange={value => {
                                    if(value) {
                                        let selected = this.state.selectedOffers
                                        selected.push(offer.id)
                                        selected = [...new Set(selected)]
                                        this.setState({ selectedOffers: selected })
                                    } else {
                                        let selected = this.state.selectedOffers.filter(id => id !== offer.id)
                                        this.setState({ selectedOffers: selected })
                                    }
                                }} />)}
                            </div>
                        </div>
                        <div style={{ overflowY: "auto" }}>
                            <Modal.Divider title={i18next.t("page.private.measure.createMeasureApprovalModal.offersAwaitingApproval")} />
                            <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                                {this.props.offers.filter(offer => offer.status === "approval").map((offer, index) => <Checkbox disabled={this.state.loading} key={index} label={`${formatter.format(offer.net)} ${i18next.t("page.private.measure.createMeasureApprovalModal.net")} ${offer.number} ${i18next.t("page.private.measure.createMeasureApprovalModal.from")} ${moment(offer.date).format("DD.MM.YYYY")} / ${offer.serviceProvider ? offer.serviceProvider.name : offer.request.serviceProvider.name}`} value={this.state.selectedOffers.includes(offer.id)} onChange={value => {
                                    if(value) {
                                        let selected = this.state.selectedOffers
                                        selected.push(offer.id)
                                        selected = [...new Set(selected)]
                                        this.setState({ selectedOffers: selected })
                                    } else {
                                        let selected = this.state.selectedOffers.filter(id => id !== offer.id)
                                        this.setState({ selectedOffers: selected })
                                    }
                                }} />)}
                            </div>
                        </div>
                        <div style={{ overflowY: "auto" }}>
                            <Modal.Divider title={i18next.t("page.private.measure.createMeasureOfferRequestModal.standardDocumentAttachment")} />
                            <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                                {this.state.standardFiles.map((standardFile, index) => <Checkbox disabled={this.state.loading} key={index} label={standardFile.name} value={this.state.selectedStandardFiles.includes(standardFile.id)} onChange={value => {
                                    if(value) {
                                        let selected = this.state.selectedStandardFiles
                                        selected.push(standardFile.id)
                                        selected = [...new Set(selected)]
                                        this.setState({ selectedStandardFiles: selected })
                                    } else {
                                        let selected = this.state.selectedStandardFiles.filter(id => id !== standardFile.id)
                                        this.setState({ selectedStandardFiles: selected })
                                    }
                                }} />)}
                            </div>
                        </div>
                        <div style={{ overflowY: "auto" }}>
                            <Modal.Divider title={i18next.t("page.private.measure.createMeasureOfferRequestModal.standardAttachment")} />
                            <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                                {this.state.files.map((file, index) => <Checkbox disabled={this.state.loading} key={index} label={file.path + file.name} value={this.state.selectedFiles.includes(file.id)} onChange={value => {
                                    if(value) {
                                        let selected = this.state.selectedFiles
                                        selected.push(file.id)
                                        selected = [...new Set(selected)]
                                        this.setState({ selectedFiles: selected })
                                    } else {
                                        let selected = this.state.selectedStandardFiles.filter(id => id !== file.id)
                                        this.setState({ selectedFiles: selected })
                                    }
                                }} />)}
                            </div>
                        </div>
                        <div style={{ overflowY: "auto" }}>
                            <Modal.Divider title={i18next.t("page.private.measure.createMeasureApprovalModal.offersAlreadyApproved")} />
                            <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                                {this.props.offers.filter(offer => offer.status === "accepted").map((offer, index) => <Checkbox disabled={this.state.loading} key={index} label={`${formatter.format(offer.net)} ${i18next.t("page.private.measure.createMeasureApprovalModal.net")} ${offer.number} ${i18next.t("page.private.measure.createMeasureApprovalModal.from")} ${moment(offer.date).format("DD.MM.YYYY")} / ${offer.serviceProvider ? offer.serviceProvider.name : offer.request.serviceProvider.name}`} value={this.state.selectedOffers.includes(offer.id)} onChange={value => {
                                    if(value) {
                                        let selected = this.state.selectedOffers
                                        selected.push(offer.id)
                                        selected = [...new Set(selected)]
                                        this.setState({ selectedOffers: selected })
                                    } else {
                                        let selected = this.state.selectedOffers.filter(id => id !== offer.id)
                                        this.setState({ selectedOffers: selected })
                                    }
                                }} />)}
                            </div>
                        </div>
                        <div style={{ overflowY: "auto" }}>
                            <Modal.Divider title={i18next.t("page.private.measure.createMeasureApprovalModal.offersAlreadyRejected")} />
                            <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                                {this.props.offers.filter(offer => offer.status === "declined").map((offer, index) => <Checkbox disabled={this.state.loading} key={index} label={`${formatter.format(offer.net)} ${i18next.t("page.private.measure.createMeasureApprovalModal.net")} ${offer.number} ${i18next.t("page.private.measure.createMeasureApprovalModal.from")} ${moment(offer.date).format("DD.MM.YYYY")} / ${offer.serviceProvider ? offer.serviceProvider.name : offer.request.serviceProvider.name}`} value={this.state.selectedOffers.includes(offer.id)} onChange={value => {
                                    if(value) {
                                        let selected = this.state.selectedOffers
                                        selected.push(offer.id)
                                        selected = [...new Set(selected)]
                                        this.setState({ selectedOffers: selected })
                                    } else {
                                        let selected = this.state.selectedOffers.filter(id => id !== offer.id)
                                        this.setState({ selectedOffers: selected })
                                    }
                                }} />)}
                            </div>
                        </div>
                        <div style={{ overflowY: "auto" }}>
                            <Modal.Divider title={i18next.t("page.private.measure.createMeasureApprovalModal.offersAlreadyCommissioned")} />
                            <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 8 }}>
                                {this.props.offers.filter(offer => offer.status === "purchased").map((offer, index) => <Checkbox disabled={this.state.loading} key={index} label={`${formatter.format(offer.net)} ${i18next.t("page.private.measure.createMeasureApprovalModal.net")} ${offer.number} ${i18next.t("page.private.measure.createMeasureApprovalModal.from")} ${moment(offer.date).format("DD.MM.YYYY")} / ${offer.serviceProvider ? offer.serviceProvider.name : offer.request.serviceProvider.name}`} value={this.state.selectedOffers.includes(offer.id)} onChange={value => {
                                    if(value) {
                                        let selected = this.state.selectedOffers
                                        selected.push(offer.id)
                                        selected = [...new Set(selected)]
                                        this.setState({ selectedOffers: selected })
                                    } else {
                                        let selected = this.state.selectedOffers.filter(id => id !== offer.id)
                                        this.setState({ selectedOffers: selected })
                                    }
                                }} />)}
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.error === true && <Modal.Error>Die Unterschriftsmappe konnte nicht erzeugt werden. Bitte überprüfen Sie, ob alle E-Mail-Adressen korrekt sind und ausschließlich PDF-Dateien ausgewählt wurden.</Modal.Error>}
                {this.state.error && this.state.error !== true && <Modal.Error>Sie sind derzeit nicht als Absender von eSignaturen verifiziert. Sie erhalten in wenigen Minuten eine E-Mail, mit welcher Sie sich verifizieren können. Erstellen Sie die Freigabeanfrage anschließend erneut.</Modal.Error>}
            </Modal.Body>
            <Modal.Footer buttons={instance => <Button disabled={!this.state.title || this.state.recipients.length === 0 || this.state.selectedOffers.length === 0 || !this.state.text || this.state.recipients.find(r => !r.email || (!isEmail(r.email) || !r.name || !r.position || !r.type))} loading={this.state.loading} primary thick title={i18next.t("page.private.measure.createMeasureApprovalModal.exitButton")} icon="envelope" onClick={async () => {
                this.setState({ loading: true })

                const result = await fetch(`${scanmetrix.nestURL}/v2/measure-approval`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        offers: this.state.selectedOffers.map(s => parseInt(s, 10)),
                        measure_id: this.props.measure.id,
                        title: this.state.title,
                        text: this.state.text,
                        files: this.state.selectedFiles.map(s => parseInt(s, 10)),
                        standard_files: this.state.selectedStandardFiles.map(s => parseInt(s, 10)),
                        recipients: this.state.recipients,
                        type: this.state.type
                    }),
                    credentials: "include"
                })

                if(result.status === 201) {
                    this.props.refresh().then(() => instance.close())
                } else {
                    if(result.status === 403) {
                        this.setState({ loading: false, error: "SENDER_NOT_VERIFIED" })
                    } else this.setState({ loading: false, error: true })
                }
            }} />} />
        </Modal>
    }
}
